import { useNavigate } from "react-router";
import {ReactComponent as NewSupserLogoIcon} from "../../../../Assets/new_sync_sync_logo.svg";
import {ReactComponent as SelectedOption} from "../../../../Assets/icons/selected_login_icon.svg";
import {ReactComponent as UnSelectedOption} from "../../../../Assets/icons/unselected_login_icon.svg";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Field, FillButton, Text, TextAndField } from "../../../Widgets/FormUtils/FormUtils";
import { useEffect, useState } from "react";
import SkillSet from "../Widgets/SkillSet/SkillSet";
import { Service } from "../../../../Network/ServiceClass/Auth";
import { useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { getAllSkillSetThunk } from "../../../../Store/utils";
import { toast } from "react-toastify";

export function AddSignUpInfo({ handleSaveDate, setCurrFlow }){
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [selectedOption, selectOption] = useState(true)
    // const [skills, setSkills] = useState([]);
    const [openSkillSet, setOpenSkillSet] = useState(false)
    const [countries, setCountries] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [chosenSkills, setChosenSkills] = useState([]);
    const skills = useSelector(state => state.utils)?.skillSets || []

    const auth = new Service();

    const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      userName: '',
      password: '',
      confirmPassword: '',
    },

    validationSchema: Yup.object({
      firstName: Yup.string().min(2, "Invalid name").required("Please enter first name"),
      lastName: Yup.string().min(2, "Invalid name").required("Please enter last name"),
      userName: Yup.string().min(2, "Invalid name").required("Please enter user name"),
      password: Yup.string().min(6, "Password is too short").required("Please enter your password"),
      confirmPassword: Yup.string().oneOf([Yup.ref('password'),null],'Password must match').required('Password must match')
    }),
    onSubmit: (value) => {
      const data = {
        ...value,
        skillSetIds: chosenSkills.map(val => val.id),
      }
      handleSaveDate(data);
      setCurrFlow(3)
    },
  });

    useEffect(()=>{

      dispatch(getAllSkillSetThunk())
        if(searchParams.get('hash')!==null)
        console.log(searchParams.get('hash'));
    
        auth.getCountries().then((value)=>{
          setCountries(value);
        }).catch((err)=>{
          console.log(err);
        }); 
    
      },[])


    return (
        <div className="mt-12">
            <div className="flex items-center gap-2 mb-10">
            <FaArrowLeftLong className="cursor-pointer" onClick={()=>setCurrFlow(1)}  />
            <p>Back</p>
            </div>
        <NewSupserLogoIcon />
        <p className="text-[32px] mt-10 font-semibold">Create your SuprSync Account</p>
        <p className='text-sm font-medium text-A6A mb-6'>Fill in the forms below with the correct details.</p>
        <div className="flex flex-col mt-5 gap-10 dark:text-white rounded-input">
        <form className='flex flex-col gap-[20px]'>
            <div className="flex items-center gap-5">
            <TextAndField>
            <Text text={"First Name"} />
            <Field text={'Kindly enter first name'}
                value={formik.values.firstName}
                handleChange={formik.handleChange}
                name={'firstName'}
                formik={formik} 
                size="large"/>
            </TextAndField>
            <TextAndField>
            <Text text={"Last Name"} />
            <Field text={'Kindly enter last name'}
                value={formik.values.lastName}
                handleChange={formik.handleChange}
                name={'lastName'}
                formik={formik} size="large"/>
            </TextAndField>
            </div>

            <TextAndField>
            <Text text={"User Name"} />
            <Field text={'Kindly enter user name'}
                value={formik.values.userName}
                handleChange={formik.handleChange}
                name={'userName'}
                formik={formik} size="large"/>
            </TextAndField>

            <div className="flex items-center gap-5">
            <TextAndField>
                    <Text text={"Password"} />
                    <Field text={'Enter password'}
                      value={formik.values.password}
                      handleChange={formik.handleChange}
                      name={'password'}
                      formik={formik} size="large" type={'password'}/>
                  </TextAndField>

                  <TextAndField>
                    <Text text={"Confirm password"} />
                    <Field text={'Enter confirm password'}
                      value={formik.values.confirmPassword}
                      handleChange={formik.handleChange}
                      name={'confirmPassword'}
                      formik={formik} size="large" type={'password'}/>
                  </TextAndField>
            </div>

            
            <TextAndField>
                    <Text text={'Skills'}/>
                    <div className='min-w-[356px] h-[50px] cursor-pointer border-1 dark:border-333 flex flex-col justify-center pl-2 border-[#d3d4d8] text-[13px] rounded-[100px]'
                    onClick={()=>setOpenSkillSet(true)}>Select your skills</div>
                  </TextAndField>
                  {openSkillSet === true && <div className= {!openSkillSet ? 'hidden': 'block'}>
                    <SkillSet data={skills} setOpenSkillSet={setOpenSkillSet} chosenSkills={chosenSkills} setChosenSkills={setChosenSkills}/>
                  </div> }

        </form>

          <div>
            <FillButton
                type="submit"
                text={'Next'}
                width={'100%'}
                height={'50px'}
                callBack={() => {
                  formik.handleSubmit()
                  console.log(formik.errors)
                }}
                sx={{ borderRadius: 100}}
              />
          </div>
        </div>
        </div>
    )
}