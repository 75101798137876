import { ReactComponent as ForgotIcon } from "../../../Assets/forgot_password_logo.svg";
import { ReactComponent as NewSupserLogoIcon } from "../../../Assets/new_sync_sync_logo.svg";
import { FaArrowLeftLong } from "react-icons/fa6";
import {
  Field,
  FillButton,
  Text,
  TextAndField,
} from "../../Widgets/FormUtils/FormUtils";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useState } from "react";
import { Service } from "../../../Network/ServiceClass/Auth";
import { CircularProgress, Stack, Box, Typography } from "@mui/material";
import { MdOutlineCancel } from "react-icons/md";
import { font } from "../../Widgets/Styles/font";
import "./ForgottenPassword.css";
import { NewResetPasswordModal } from "./Widget/NewResetPasswordModal";

import { useSearchParams } from "react-router-dom";

const Auth = new Service();

export function NewResetPassword() {
  const navigate = useNavigate();
  const [modalStatus, setModalStatus] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams] = useSearchParams();
  const [success, setSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },

    validationSchema: Yup.object({
      password: Yup.string().min(6).required("Please enter password"),
      confirmPassword: Yup.string()
        .min(6)
        .oneOf([Yup.ref("password"), null], "password must match")
        .required("Please enter confirm password"),
    }),
    onSubmit: (value) => {
      ResetPassword(value.password);
    },
  });

  const ResetPassword = async (password) => {
    setIsLoading(true);
    Auth.ResetPassword({ password: password, hash: searchParams.get("hash") })
      .then((value) => {
        setIsLoading(false);
        handleOpenModal();
        setSuccess(true);
        
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err.response.data.message);
        setIsError(true);
        setIsLoading(false);
      });
  };

  const handleOpenModal = () => {
    setModalStatus(true);
  };

  const handleCloseModal = () => {
    setModalStatus(false);
  };

  return (
    <section className="w-screen h-full flex items-center">
      <div className="w-1/2 h-full bg-forgot-bg bg-no-repeat bg-cover">
        <ForgotIcon className="ml-10 mt-4" />
      </div>
      <div className="w-1/2 h-full flex justify-center items-center bg-base-color">
        <div className="rounded-[30px] shadow-small p-16 w-[600px] h-[725px] flex items-center justify-start ml-[-60%] z-50 bg-white rounded-input">
          <div className="w-full">
            <NewSupserLogoIcon />

            <p className="text-[32px] mt-4 font-semibold font-jakarta">
              Reset Password
            </p>
            <p className="text-sm font-medium text-A6A">
              Type in your new password
            </p>

            <div className="my-8 w-full">
              <TextAndField>
                <Text text={"Enter new password"} />
                <Field
                  text={"Enter new password"}
                  value={formik.values.password}
                  handleChange={formik.handleChange}
                  name={"password"}
                  formik={formik}
                  size="large"
                  type={"password"}
                />
              </TextAndField>

              <div className="my-10" />

              <TextAndField>
                <Text text={"Confirm password"} />
                <Field
                  text={"Confirm password"}
                  value={formik.values.confirmPassword}
                  handleChange={formik.handleChange}
                  name={"confirmPassword"}
                  formik={formik}
                  size="large"
                  type={"password"}
                />
              </TextAndField>
            </div>

            {isError && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "0.2rem",
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  padding: "0.5rem",
                }}
              >
                <Typography
                  sx={{
                    ...font,
                    fontSize: "12px",
                    color: "red",
                    textAlign: "center",
                  }}
                >
                  {errorMessage}
                </Typography>
                <MdOutlineCancel
                  style={{ color: "red", fontSize: "23px", cursor: "pointer" }}
                  onClick={() => setIsError(false)}
                />
              </Box>
            )}

            <div>
              {!isLoading && (
                <FillButton
                  text={"Recover Password"}
                  width={"100%"}
                  height={"50px"}
                  callBack={formik.handleSubmit}
                  sx={{ borderRadius: 100 }}
                />
              )}
              {isLoading && (
                <Stack direction="row" justifyContent="center">
                  <CircularProgress />
                </Stack>
              )}
            </div>
          </div>
        </div>
      </div>
      {modalStatus && (
        <NewResetPasswordModal
          navigate={navigate}
          open={modalStatus}
          handleClose={handleCloseModal}
        />
      )}
    </section>
  );
}
