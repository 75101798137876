import React, { useState, useEffect, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'    
import { useDispatch, useSelector } from 'react-redux'
import { SideBar } from '../../Widgets/SideBar'
import { BranchesHeader } from '../BranchScreen/BranchesHeader/BranchesHeader'
import ButtonWithIcon from '../../Widgets/ButtonWithIcon'
import GlobalButton from '../../Widgets/GlobalButton'
import SearchInput from '../../Widgets/SearchInput'
import CustomButton from '../TransferItems/TransferButton'
import { IoClose } from 'react-icons/io5';
import { IoIosArrowBack } from 'react-icons/io';
import { FaFolderOpen, FaFolder } from 'react-icons/fa6';
import { toast } from 'react-toastify';

import CustomDropdownMultipleSelect from '../../Widgets/CustomDropdownMultipleSelect'
import GetItems from './GetItems/GetItems';
import ConfirmRequest from './ConfirmRequest/ConfirmRequest';
import {BsPlus} from 'react-icons/bs'
import AppLayout from '../../Widgets/AppLayout';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { orderRequestTutorial } from '../mock';
import { getAllItemsThunk, getInventoryGroupsThunk } from '../../../Store/Inventory';
import OrderTable from './OrderTable'
import OrderRequestModal from './RquestItemModal';
import { AiOutlineFolder } from 'react-icons/ai';

const InventoryOrderRequest = () => {
    const dispatch = useDispatch()
    const [searchText, searchinventory] = useState("")
    const [selectedBranch, setBranch] = useState([]);
    const [pageIndex, setPageIndex] = useState(0)
    const [selectedItems, setSelectedItems] = useState([]);
    const [branchList, setBranchList] = useState([])
    const [selectedGroup, setSelectedGroup] = useState('All');
    const [groups, setGroups] = useState(['All']);
    const [items, setItems] = useState([]);
    const [activeFilter, setActiveFilter] = useState('All');
    const [searchQuery, setSearchQuery] = useState('');

    // Get data from Redux store
    const rawInventoryItems = useSelector(state => state?.inventory?.items);
    const rawInventoryGroups = useSelector(state => state?.inventory?.groups);

    // Memoize the inventory data
    const inventoryItems = useMemo(() => rawInventoryItems || [], [rawInventoryItems]);
    const inventoryGroups = useMemo(() => rawInventoryGroups || [], [rawInventoryGroups]);

    const [group, selectGroup] = useState({
        label: 'All groups',
        value: '',
      })
    
      useEffect(()=>{
        dispatch(getAllItemsThunk())
        dispatch(getInventoryGroupsThunk())
      },[])

    const handleSelectBranch = (e) => {
        setBranch(e);
    };

    const reset = () =>{
        setBranch([])
        setSelectedItems([])
    }

    const navigate = useNavigate();

    const handleGroupFilter = (groupName) => {
        setSelectedGroup(groupName);
        
        if (groupName === 'All') {
            setItems([...items]);
        } else {
            const filtered = items.filter(item => item.group === groupName);
            setItems([...filtered]);
        }
    };

    const handleFilterClick = (filter) => {
        setActiveFilter(filter);
    };

    const filteredInventoryItems = useMemo(() => {
        if (!Array.isArray(inventoryItems)) return [];
        
        return inventoryItems.filter(item => {
            if (activeFilter === 'All') return true;
            return item.group === activeFilter;
        });
    }, [inventoryItems, activeFilter]);

    const getGroupName = (groupId) => {
        const group = inventoryGroups?.find(g => g.id === groupId)
        return group?.name || '-'
    }
    const handleSelectGroup = (val) => {
        selectGroup(val)
      }

    // The groups state can be derived from inventoryGroups
    const Groups = useMemo(() => ['All', ...inventoryGroups.map(group => group.name)], [inventoryGroups]);

    // Add state for modal
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Update handleRequestItems to open modal
    const handleRequestItems = () => {
        if (selectedItems.length === 0) {
            toast.error('Please select items to request');
            return;
        }
        setIsModalOpen(true);
    };

  return (
    <section>
        <AppLayout
            showAppBar={false}
            hideLiveUsers={true}
            custom="inventory"
            subCustom="create-order-request"
            title=""
            customTitle="Inventory"
            subtitle=""
        >
            <div className=" absolute top-[15px] left-[15px] w-[calc(100%-15px)] h-[95vh] overflow-y-auto border border-[#EAEAEA] rounded-[12px] flex flex-col bg-white">
                <div className=" flex flex-col w-full h-[30px] pl-5 pt-2 gap-[10px]">
                    <div className="flex items-center gap-2">
                        <AiOutlineFolder className="text-gray-600 text-xl" />
                        <p className="font-[Plus Jakarta Sans] font-medium text-[12px] text-[#7a7a7a] leading-[15px]">
                            Order Request
                        </p>
                    </div>

                    <div className=" flex flex-row h-screen w-[80vw] gap-[20px] relative mt-5 justify-between items-center">
                        <div className=" flex flex-col w-full h-full">
                                <div className=" flex flex-row items-center gap-2">
                                    <IoIosArrowBack className="text-[#00A862]" />
                                    <Link to="/stock-order-request" className="text-[#00A862]">Order Request</Link>
                                </div>
                                <h1 className="font-[Plus Jakarta Sans] font-semibold text-[30px]">
                                    Create Order Request
                                </h1>
                        </div>

                        {/* <div className='pr-5'>
                            <CustomButton
                                title="+ Create new order request"
                                onClick={() => {}}
                                className="rounded-md font-semibold text-[16px] !text-[#00AD57] !bg-[#E8E8E8] !w-[240px] h-[48px]"
                            />
                        </div> */}
                    </div>

                    <div className="py-3 items-center">
                        <SearchInput
                             placeholder="I'm Searching for" 
                             className="w-[77vw] h-[40px] !rounded-[24px] border border-[#E8E8E8] placeholder-[#C3C3C3]-400 placeholder:font-[Plus Jakarta Sans] placeholder:text-sm"
                             value={searchQuery}
                             onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>

                    <div className="flex flex-row w-[35vw] h-[30px] gap-[10px]">
                        <CustomButton
                            title="All"
                            onClick={() => handleFilterClick('All')}
                            className={`min-w-[48px] w-[48px] max-w-[80px] rounded-[21px] border border-[#F0F0F0] 
                                      font-['Plus_Jakarta_Sans'] font-medium text-[12px] 
                                      leading-[15.12px] tracking-[-0.01em] 
                                      ${activeFilter === 'All' ? 'bg-[#9c9c9c] text-white' : ''}
                                      hover:bg-[#9c9c9c] hover:text-white transition-colors`}
                        />

                        {inventoryGroups.map((group) => (
                            <CustomButton
                                key={group.id}
                                title={group.name}
                                onClick={() => handleFilterClick(group.name)}
                                className={`min-w-[70px] rounded-[21px] border border-[#F0F0F0] 
                                          font-['Plus_Jakarta_Sans'] font-medium text-[12px] 
                                          leading-[15.12px] tracking-[-0.01em] 
                                          ${activeFilter === group.name ? 'bg-[#9c9c9c] text-white' : ''}
                                          hover:bg-[#9c9c9c] hover:text-white transition-colors`}
                            />
                        ))}
                    </div>

                    <div className="w-[78vw] flex-1 min-h-[65vh] border border-[#EAEAEA] overflow-y-auto gap-[10px]">
                        <OrderTable 
                            activeFilter={activeFilter}
                            searchQuery={searchQuery}
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                        />
                    </div>

                    <div className="flex flex-row justify-end gap-[10px] w-[78vw] mt-1 mb-6 pr-4">
                        <CustomButton
                            title="Cancel"
                            onClick={() => navigate(-1)}
                            className="min-w-[100px] h-[36px] rounded-[4px] border border-[#E8E8E8] 
                                      font-['Plus_Jakarta_Sans'] font-medium text-[14px] text-[#848484] 
                                      !bg-white hover:!bg-gray-50 transition-colors"
                        />
                        <CustomButton
                            title="Request Items"
                            onClick={handleRequestItems}
                            className="min-w-[120px] h-[36px] rounded-[4px] 
                                      font-['Plus_Jakarta_Sans'] font-medium text-[14px] text-white 
                                      !bg-[#00A862] hover:!bg-[#008F53] transition-colors"
                        />
                    </div>

                </div>
            </div>
         
        </AppLayout>
       
        <OrderRequestModal
            open={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
        />
    </section>
  )
}

export default InventoryOrderRequest


