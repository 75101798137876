/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { BsDot } from 'react-icons/bs';
import { Storage } from '../../../../../../Network/StorageClass/StorageClass';
import UserDropdown from '../../../../../Widgets/UserDropdown';
import { Column } from '@ant-design/plots';
import { useSelector, useDispatch } from 'react-redux';
import InitialsGen from '../../../../../Widgets/InitialsGen';
import SkeletonLoading from '../../../../../Widgets/SkeletonLoading';
import moment from 'moment';
import { Loader } from '../../../../../Widgets/Loader';
import roundimage from '../../../../../../Assets/roundimg.png';
import {
  getAllTeamMembersAsync,
  fetchTeamMemberUpcomingShiftAsync,
  fetchTeamMemberSwapFrequencyAsync,
  fetchTeamMemberShiftDateBookedAsync,
  fetchTeamMemberShiftLateClockInAsync,
  fetchTeamMemberShiftHourWorkedAsync,
  updateReportToAsync,
  getATeammemberAsync,
  fetchLicenseAsync,
  ApproveDisapproveLicenseAsync,
  getSecondaryJobsAsync,
  fetchJobRolesAsync,
  updateUserHourlyPayThunk,
} from '../../../../../../Store/Branches';

import { IoIosArrowBack } from 'react-icons/io';
import PlaceHolderOne from '../../../../../Widgets/placeholders/PlaceHolderOne';
import licenImage from '../../../../../../Assets/placholders/placeholder1.png';
import { LicenseModal } from '../../../../ProfilePageScreen/Modal/License';
import {BsThreeDotsVertical} from 'react-icons/bs'
import { toast } from 'react-toastify';
import { CustomPopper } from '../../../../../Widgets/CustomPopper';
import ButtonWithIcon from '../../../../../Widgets/ButtonWithIcon';
import { BsSearch } from 'react-icons/bs';
import { createSecondaryJobsAsync } from '../../../../../../Store/Branches';
import { convertToDollar } from '../../../../../../utils/util';
import ReportIcon from '../../../../../../Assets/report_icon.svg'
import { useLocation, useNavigate } from 'react-router';
import AppLayout from '../../../../../Widgets/AppLayout';
import { CiLocationOn } from 'react-icons/ci';

const dateSelection = [
  { label: 'This week', value: 0 },
  { label: 'Last 7 days', value: 6 },
  { label: 'Last 30 days', value: 29 },
];

const defaultDate = {
  from: moment().startOf('isoWeek').format('yyyy-MM-DD'),
  to: moment().endOf('isoWeek').format('yyyy-MM-DD'),
};
export const TeamMemberDetails = ({ showTeamText=true }) => {
  const dispatch = useDispatch();
  const location = useLocation()
  
  const branchName = location?.state?.branchName
  const selectedUser = location?.state?.data
  const [openLicenseModal, setOpenLincenseModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [licenseImg, setLicenseImg] = useState('')
  const [reportTo, setReportTo] = useState({});
  const [swapFreqDate, setSwapFreq] = useState(defaultDate);
  const [dateBookedFilter, setDateBooked] = useState(defaultDate);
  const [lateArrivalDate, setLateArrival] = useState(defaultDate);
  const [swapFreqDropdown, setSwapFreqDropdown] = useState({
    label: 'This week',
    value: 0,
  });
  const [dateBookedFilterDropdown, setDateBookedDropdown] = useState({
    label: 'This week',
    value: 0,
  });
  const [lateArrivalDateDropdown, setLateArrivalDropdown] = useState({
    label: 'This week',
    value: 0,
  });
  const [shiftHourWorked] = useState(defaultDate);
  const navigate = useNavigate()

  const today = new Date().toDateString().split(' ')[0];
  const [isEdit, setEditMode] = useState(false);
  const [hourlyPay, setHourlyPay] = useState(selectedUser?.hourlyPay);
  const storage = new Storage();
  const priviledge = storage.getActiveCompany().memberships[0].privilege;

  const branches = useSelector((state) => state.branches);
  const licenses = useSelector((state) => state.branches)?.licenses;
  const licenseApproveDisapproveLoading = useSelector((state) => state.branches)?.approveDisapproveLicenseToLoading;

  const [showLisenceAction, setShowLisenceAction] = useState(false)
  const [lisenceActionIndex, setLisenceActionIndex] = useState(null)
  const [openSearchSkills, setOpenSearchSkill] = useState(null)
  const [inputJobroleSearch, setInputJobroleSearch] = useState('')
  const [loadingAddToSkill, setLoadingAddToSkill] = useState(false)

  const jobRoles = useSelector(
    (state) => state.branches?.jobRoles
  );

  const memberSecondaryJobroles = useSelector(
    (state) => state.branches?.teamMembers
  ).find((x) => x.user?.id === selectedUser.user?.id);

  const handleApproveDisapproveLisence = (id, state) => {
    setShowLisenceAction(false)
    dispatch(ApproveDisapproveLicenseAsync({id, state}))
    .then(val => {
      if(val.payload?.response?.status === 400){
        toast.error(val.payload?.response?.data?.message)
      }else if(val.payload?.status === 200){
          toast.success( state === true ? 'Licence Approved' : 'Licence Disapproved')
          dispatch(fetchLicenseAsync(selectedUser.user?.id));
      }else {
          toast.error('Try again')

      }
    })
  }

  const data = branches?.shiftDateBook.map((val) => {
    return {
      date: moment().day(val?.dayOfWeek).format('ddd'),
      hours: val.count,
    };
  });
  const sumHours = data.reduce((a,b) => {return a + b.hours},0)

  const handleFetchAllTeamMembers = useCallback(() => {
    dispatch(getAllTeamMembersAsync(selectedUser?.assignedBranchId));
    dispatch(getATeammemberAsync(selectedUser?.user?.id));
  }, [selectedUser?.assignedBranchId]);

  const handleFetchUpcomingShft = useCallback(() => {
    dispatch(fetchTeamMemberUpcomingShiftAsync(selectedUser?.user?.id));
  }, [selectedUser?.id]);

  const handleFetchSwapFrequency = useCallback(() => {
    dispatch(
      fetchTeamMemberSwapFrequencyAsync({
        ...swapFreqDate,
        userId: selectedUser?.user?.id,
      })
    );
  }, [selectedUser.id, swapFreqDate]);

  const handleFetchDateBook = useCallback(() => {
    dispatch(
      fetchTeamMemberShiftDateBookedAsync({
        ...dateBookedFilter,
        userId: selectedUser?.user?.id,
      })
    );
  }, [dateBookedFilter, selectedUser.id]);

  const handleFetchLateClockIn = useCallback(() => {
    dispatch(
      fetchTeamMemberShiftLateClockInAsync({
        ...lateArrivalDate,
        userId: selectedUser.user?.id,
      })
    );
  }, [lateArrivalDate, selectedUser.id]);

  const handleFetchHourWorked = useCallback(() => {
    dispatch(
      fetchTeamMemberShiftHourWorkedAsync({
        ...shiftHourWorked,
        userId: selectedUser.user?.id,
      })
    );
  }, [selectedUser?.user?.id, shiftHourWorked]);

  useEffect(() => {
    handleFetchAllTeamMembers();
    handleFetchHourWorked();
    handleFetchUpcomingShft();
    dispatch(fetchJobRolesAsync())
    dispatch(getSecondaryJobsAsync())
    dispatch(fetchLicenseAsync(selectedUser.user?.id));
  }, []);

  useEffect(() => {
    handleFetchDateBook();
    handleFetchLateClockIn();
    handleFetchSwapFrequency();
  }, [
    lateArrivalDate,
    dateBookedFilter,
    swapFreqDate,
    handleFetchDateBook,
    handleFetchLateClockIn,
    handleFetchSwapFrequency,
  ]);

  const config = {
    data,
    xField: 'date',
    yField: 'hours',
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      date: {
        alias: 'kfkf',
      },
      hours: {
        alias: 'Employees available',
      },
    },
    minColumnWidth: 25,
    maxColumnWidth: 25,
    columnStyle: {
      radius: [4, 4, 4, 4],
    },
    colorField: 'date', // or seriesField in some cases
    color: ({ date }) => {
      switch (today) {
        case date:
          return '#2F5C01';

        default:
          return '#E3EFD7';
      }
    },
  };

  const RenderDayOfWeek = ({ week, value }) => {
    return (
      <div className="p-3 rounded-lg border-1 border-9FD dark:border-333 dark:bg-828">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <BsDot size={30} className="dark:text-white" />
            <p className="text-558 text-sm ml-1 dark:text-white">{week}</p>
          </div>
          <p className="text-xs font-semibold dark:text-white">{value}</p>
        </div>
      </div>
    );
  };

  const handleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  const handleSubmitReport = () => {
    dispatch(
      updateReportToAsync({
        userId: selectedUser.user?.id,
        data: {
          managerId: reportTo,
        },
      })
    ).then(() => {
      setEditMode(false);
      handleFetchAllTeamMembers();
    });
  };

  const handleSaveHourlyPay = () => {
    dispatch(updateUserHourlyPayThunk({ 
    hourlyPay: hourlyPay,
    membershipId: selectedUser.id
  })).then((res) => {
    if(res?.meta?.requestStatus === "fulfilled"){
      toast.success("Hourly pay was successfully updated")
    }
  })
  }

  const handleCancelEditMode = () => {
    setEditMode(false);
  }

  const handleTeamMemberTab = () => {
    navigate(-1)
  };

  const handleBranchTab = () => {
    navigate("/branches")
  };

  const handleDuration = (value) => {
    if (value === 0) {
      return {
        from: moment().startOf('isoWeek').format('yyyy-MM-DD'),
        to: moment().endOf('isoWeek').format('yyyy-MM-DD'),
      };
    }

    return {
      from: moment().subtract(value, 'days').format('yyyy-MM-DD'),
      to: moment().format('yyyy-MM-DD'),
    };
  };

  const handleOpenLincenseModal = (value) => {
    setOpenLincenseModal(true);
    setLicenseImg(value?.document?.url);
    setSelectedId(value.id);
  };

  const handleSearchJobroles = (searchText) => {
    let filterJobRoles = jobRoles
    if (searchText.length >= 1) {
      const pattern = new RegExp(`\D*${searchText}\D*`,'i')
      filterJobRoles = jobRoles?.filter((x)=> x.title.match(pattern) || 
      x.symbol.match(pattern))

    }
    return filterJobRoles
  }

  const handleCreateSecJobRole = (val) => {
    setLoadingAddToSkill(true)
    // const user = JSON.parse(localStorage.getItem("user"));
    // const activeCompany = JSON.parse(localStorage.getItem("activeCompany"))
    // const [member] = activeCompany?.memberships.filter((x) => x.userId === user?.id);
    dispatch(createSecondaryJobsAsync({
      teamMembershipId: selectedUser?.id,
      jobRoles: [val]
    })).then((res) => {
      if(res?.meta?.requestStatus === 'fulfilled'){
        dispatch(getSecondaryJobsAsync()).then((res) => {
          toast.success("Secondary job role  was successfully created")
          dispatch(getAllTeamMembersAsync(selectedUser?.assignedBranchId));
          dispatch(getATeammemberAsync(selectedUser?.user?.id));
        })
      }
        setLoadingAddToSkill(false)
    })
  }

  const handleOnChange = (e) => {
    setHourlyPay(Number(e.target.value) * 100)
  }

  
  const handleDirectSendBulkAccountCreation = () => {
    navigate("/branches/bulk-account")
}

  return (
    <div>
      <AppLayout
      custom="company"
      subCustom="branch"
      title="Team members"
      customTitle=" "
      subtitle="Details about team members"
      RightComponent={<div className='flex items-center gap-4'>
          <button onClick={handleDirectSendBulkAccountCreation} className="p-3 rounded text-black border-2 flex items-center"> <CiLocationOn size={23} /> <span className="w-max">Create a bulk location</span></button>
      </div>}
      >
        
      <div
        className={priviledge === 3 ? 'branch-name-no-margin' : 'branch-name flex items-center'}
      >
        <IoIosArrowBack onClick={handleTeamMemberTab} className='dark:text-white cursor-pointer' size={20} />
        {priviledge !== 3 && (
          <p onClick={handleBranchTab} className="child-branch cursor-pointer text-[14px] font-semibold">
            {branchName.toUpperCase()} /
          </p>
        )}
        {showTeamText && <p
          onClick={handleTeamMemberTab}
          className="child-branch cursor-pointer text-[14px] font-semibold"
        >
          TEAM MEMBERS /
        </p>}
        <p className='text-[14px] dark:text-white'>
          {selectedUser.user.firstName} {selectedUser.user.lastName}
        </p>
      </div>
      <div className="p-[38px] border-9FD border-1 rounded-lg dark:border-333">
        <SkeletonLoading width="50%" height={100} loading={false}>
          <div className="flex items-end mb-[28px]">
            <div className="flex items-center">
              <InitialsGen
                width={40}
                name={`${selectedUser.user.firstName} ${selectedUser.user.lastName}`}
                bgColor="blue"
              />
              <p className="font-bold text-[34px] ml-2 dark:text-white">
                {selectedUser.user.firstName} {selectedUser.user.lastName}
              </p>
            </div>
            <p className="ml-4 text-base font-semibold text-A5A dark:text-white">
              {selectedUser?.jobRole?.title}
            </p>
          </div>
        </SkeletonLoading>
        <div className=" grid grid-cols-2  gap-4">
          <SkeletonLoading
            width="100%"
            height={200}
            loading={branches?.shiftHourWorkedIsLoading}
          >
            <section className="flex py-4 px-3 rounded-lg border-9FD border-1 dark:border-333 dark:bg-828">
              <div className="flex-1 border-r-1">
                <p className="text-[22px] font-semibold dark:text-white">
                  {branches?.shiftHourWorked?.week}
                </p>
                <p className="text-969 text-base">Hours worked this week</p>
              </div>
              <div className="flex-1 pl-2">
                <p className="text-[22px] font-semibold dark:text-white">
                  {branches?.shiftHourWorked?.rotation}
                </p>
                <p className="text-969 text-base">Hours in rotation</p>
              </div>
            </section>
          </SkeletonLoading>
          <SkeletonLoading
            width="100%"
            height={200}
            loading={branches?.loading}
          >
            <section className="rounded-lg border-9FD border-1 px-3 py-4 dark:border-333 dark:bg-828">
              <p className="text-xs font-normal mb-2 dark:text-white">Reporting to</p>
              {!isEdit && (
                <div className="flex items-center pl-3 py-2 w-3/4 rounded border-6E6 border dark:border-333 dark:bg-828">
                  {branches?.manager?.manager !== null ? (
                    <p className="flex-1 dark:bg-828 dark:text-white">
                      {branches?.manager?.manager?.firstName}{' '}
                      {branches?.manager?.manager?.lastName}
                    </p>
                  ) : (
                    <p className="bg-4F4 flex-1 dark:bg-828 dark:text-white">N/A</p>
                  )}
                  <p
                    onClick={handleEditMode}
                    className="flex-0 mr-5 ml-3 text-5F3 text-sm font-semibold underline cursor-pointer"
                  >
                    <img src={ReportIcon} alt="report" />
                  </p>
                </div>
              )}
              {isEdit && (
                <div className="flex items-end justify-between rounded">
                  <UserDropdown
                    data={branches?.teamMembers?.map((team) => ({
                      label: `${team?.user?.firstName} ${team?.user?.lastName}`,
                      value: team?.user?.id,
                    }))}
                    onChangeOption={(e) => {
                      setReportTo(e.value);
                    }}
                    zIndex="dark:bg-444 dark:text-white z-50"
                    showAvatar={false}
                    className="h-[40px] w-[350px] dark:text-white"
                    defaultLabel={{
                      label: 'Select report to',
                      value: 'select',
                    }}
                    headerClass="text-xs p-0"
                  />

                  {branches?.updateReportToLoading ? (
                    <Loader />
                  ) : (
                    <div className="flex mr-5 ml-3">
                    <p
                      onClick={handleCancelEditMode}
                      className="flex-0 mr-4 text-sm font-semibold underline cursor-pointer dark:text-white"
                    >
                      Cancel
                    </p>
                    <p
                      onClick={handleSubmitReport}
                      className="flex-0 text-5F3 text-sm font-semibold underline cursor-pointer"
                    >
                      Done
                    </p>
                    </div>
                  )}
                </div>
              )}
            </section>
          </SkeletonLoading>

          <SkeletonLoading
            width="100%"
            height={200}
            loading={branches?.upcomingShiftLoading}
          >
            <section className="rounded-lg border-9FD border-1 px-3 py-4 dark:border-333 dark:bg-E1E">
              <p className="mb-5 text-base font-semibold dark:text-white">Next Shifts</p>
              { branches?.upcomingShift?.length > 0 && <div className="h-[300px] overflow-y-scroll">
                {branches?.upcomingShift?.map((shift) => (
                  <div className="p-3 rounded-lg bg-0F2 mb-3 dark:bg-828">
                    <div className="flex justify-between">
                      <p className="text-558 text-sm dark:text-white">
                        {moment(shift?.start).format('dddd')}
                      </p>
                      <p className="text-xs font-semibold dark:text-white">
                        {moment(shift?.start).format('h A')} -{' '}
                        {moment(shift?.end).format('h A')}
                      </p>
                    </div>
                    <p className="text-09B">{shift?.branch?.address}</p>
                  </div>
                ))}
                {branches?.upcomingShift?.length === 0 && (
                  <p className='dark:text-white'>No Upcoming shift</p>
                )}
              </div>}
              {branches?.upcomingShift?.length <= 0 && <div><PlaceHolderOne/></div>}
            </section>
          </SkeletonLoading>
          <SkeletonLoading>
          <div className="Licenses w-full !mt-0 p-4 border-1 border-9FD rounded-lg dark:border-333 dark:bg-E1E">
            <div className="flex items-center justify-between">
              <p className='font-semibold text-lg dark:text-white'>Secondary Jobs</p>
              <div>
              <ButtonWithIcon type='text' title='Add New' handleClick={(e)=>{
                          e.stopPropagation()
                          setOpenSearchSkill(openSearchSkills ? null : e.currentTarget)}}/>

                        <CustomPopper
                        openPopper={openSearchSkills}
                        handleClose={()=> setOpenSearchSkill(null)}
                        placement='bottom-start'>
                          <div  style={{boxShadow: '0 20px 25px -5px rgb(0 0 0 / 0.1)', borderRadius: '10px'}}
                          className='w-[250px] bg-[white] max-h-[250px] px-2 py-2  overflow-y-auto'>
                            
                            <div className='py-[4px] bg-white dark:bg-138 text-[14px] border-1 px-2 rounded-md flex gap-2 sticky top-[-8px] items-center'>
                              <BsSearch />
                              <input 
                              className='focus:outline-none dark:text-white'
                              type="text" placeholder='Search' onChange={(e)=> {
                                e.stopPropagation()
                                e.preventDefault()
                                setInputJobroleSearch(e.target.value)
                                }}/>

                            </div>


                            {(handleSearchJobroles(inputJobroleSearch) !== undefined && handleSearchJobroles(inputJobroleSearch).length > 0) ? 
                            <div className='mt-2'>
                              {handleSearchJobroles(inputJobroleSearch).map(val => 
                              <div
                              onClick={()=>{
                                handleCreateSecJobRole(val.id)}}
                              className='hover:bg-gray-100 font-semibold py-2 px-2 cursor-pointer flex justify-between'>
                                <p className='dark:text-white'>{val.title}</p>
                                </div>)}
                            </div> :
                            <p className='text-[14px] text-center pt-3 dark:text-white'>Nothing here</p>
                            }
                          </div>
                        </CustomPopper>
              </div>
            </div>
              <div className='w-full max-h-[400px] mt-4 min-h-[200px] overflow-y-auto dark:bg-E1E'>
                <table >
                  <thead className='sticky top-0 dark:bg-444'>
                    <td className="dark:text-white dark:!bg-444">Name</td>
                    <td className="dark:text-white dark:!bg-444">Symbol</td>
                  </thead>

                  {!loadingAddToSkill ? <tbody>
                    {memberSecondaryJobroles?.secondaryJobroles.length === 0 ?<tr className='text-center h-[300px]'>
                      <td className='dark:text-white' colSpan={3}><PlaceHolderOne /></td>
                    </tr>:
                    (memberSecondaryJobroles?.secondaryJobroles.map(role => 
                      <tr>
                        <td className='dark:text-white dark:bg-828'>{role?.jobrole?.title}</td>
                        <td className='dark:text-white dark:bg-828'>{role?.jobrole?.symbol}</td>
                      </tr>
                      ))
                    }
                    {/* <tr className='sticky top-0'>
                      <td>
                        <ButtonWithIcon type='text' title='Add New' handleClick={(e)=>{
                          e.stopPropagation()
                          setOpenSearchSkill(openSearchSkills ? null : e.currentTarget)}}/>

                        <CustomPopper
                        openPopper={openSearchSkills}
                        handleClose={()=> setOpenSearchSkill(null)}
                        placement='bottom-start'>
                          <div  style={{boxShadow: '0 20px 25px -5px rgb(0 0 0 / 0.1)', borderRadius: '10px'}}
                          className='w-[250px] bg-[white] max-h-[250px] px-2 py-2  overflow-y-auto'>
                            
                            <div className='py-[4px] bg-white dark:bg-138 text-[14px] border-1 px-2 rounded-md flex gap-2 sticky top-[-8px] items-center'>
                              <BsSearch />
                              <input 
                              className='focus:outline-none dark:text-white'
                              type="text" placeholder='Search' onChange={(e)=> {
                                e.stopPropagation()
                                e.preventDefault()
                                setInputJobroleSearch(e.target.value)
                                }}/>

                            </div>


                            {(handleSearchJobroles(inputJobroleSearch) !== undefined && handleSearchJobroles(inputJobroleSearch).length > 0) ? 
                            <div className='mt-2'>
                              {handleSearchJobroles(inputJobroleSearch).map(val => 
                              <div
                              onClick={()=>{
                                handleCreateSecJobRole(val.id)}}
                              className='hover:bg-gray-100 font-semibold py-2 px-2 cursor-pointer flex justify-between'>
                                <p className='dark:text-white'>{val.title}</p>
                                </div>)}
                            </div> :
                            <p className='text-[14px] text-center pt-3 dark:text-white'>Nothing here</p>
                            }
                          </div>
                        </CustomPopper>
                      </td>
                    </tr> */}
                  </tbody> : 
                  <tr className='text-center'>
                  <td className='dark:text-white' colSpan={3}>Loading ...</td>
                  </tr>}
                </table>

              </div>
            </div>
          </SkeletonLoading>

          <SkeletonLoading
            width="100%"
            height={200}
            loading={branches?.shiftSwapFrequencyLoading}
          >
            <section className="rounded-lg border-9FD border-1 px-3 py-4 dark:border-333 dark:bg-E1E">
              <div className="flex items-center justify-between mb-3">
                <p className="text-base font-semibold dark:text-white">Swap frequency</p>
                <UserDropdown
                  data={dateSelection}
                  onChangeOption={(e) => {
                    setSwapFreqDropdown(e);
                    const data = handleDuration(e.value);
                    setSwapFreq({
                      ...data,
                    });
                  }}
                  showAvatar={false}
                  className="h-[40px]"
                  defaultLabel={swapFreqDropdown}
                  headerClass="text-xs p-0"
                  zIndex="dark:bg-444 dark:text-white z-50"
                />
              </div>
              <div className="grid grid-cols-2 gap-3">
                <RenderDayOfWeek
                  week="Monday"
                  value={
                    branches?.shiftSwapFrequency?.filter(
                      (freq) => freq.dayOfWeek === 1
                    )[0]?.count
                  }
                />
                <RenderDayOfWeek
                  week="Tuedays"
                  value={
                    branches?.shiftSwapFrequency?.filter(
                      (freq) => freq.dayOfWeek === 2
                    )[0]?.count
                  }
                />
                <RenderDayOfWeek
                  week="Wednesday"
                  value={
                    branches?.shiftSwapFrequency?.filter(
                      (freq) => freq.dayOfWeek === 3
                    )[0]?.count
                  }
                />
                <RenderDayOfWeek
                  week="Thursday"
                  value={
                    branches?.shiftSwapFrequency?.filter(
                      (freq) => freq.dayOfWeek === 4
                    )[0]?.count
                  }
                />
                <RenderDayOfWeek
                  week="Friday"
                  value={
                    branches?.shiftSwapFrequency?.filter(
                      (freq) => freq.dayOfWeek === 5
                    )[0]?.count
                  }
                />
                <RenderDayOfWeek
                  week="Saturday"
                  value={
                    branches?.shiftSwapFrequency?.filter(
                      (freq) => freq.dayOfWeek === 6
                    )[0]?.count
                  }
                />
                <RenderDayOfWeek
                  week="Sunday"
                  value={
                    branches?.shiftSwapFrequency?.filter(
                      (freq) => freq.dayOfWeek === 0
                    )[0]?.count
                  }
                />
              </div>
            </section>
          </SkeletonLoading>

          <SkeletonLoading
            width="100%"
            height={200}
            loading={branches?.shiftDateBookLoading}
          >
            <section className="rounded-lg border-9FD border-1 px-3 py-4 dark:border-333 dark:bg-E1E">
              <div className="flex items-center justify-between mb-3">
                <p className="text-base font-semibold dark:text-white">Days booked</p>
                <UserDropdown
                  data={dateSelection}
                  onChangeOption={(e) => {
                    setDateBookedDropdown(e);
                    const data = handleDuration(e.value);
                    setDateBooked(data);
                  }}
                  showAvatar={false}
                  className="h-[40px]"
                  defaultLabel={dateBookedFilterDropdown}
                  headerClass="text-xs p-0"
                  zIndex="dark:bg-444 dark:text-white z-50"
                />
              </div>
              <div className="">
                {sumHours > 0 && <Column {...config} />}
                {sumHours === 0 && <div className='mt-10'><PlaceHolderOne/></div> }
              </div>
            </section>
          </SkeletonLoading>

          <SkeletonLoading
            width="100%"
            height={200}
            loading={branches?.shiftLateClockInIsLoading}
          >
            <section className="rounded-lg border-9FD border-1 px-3 py-4 dark:border-333 dark:bg-E1E">
              <div className="flex items-center justify-between mb-3">
                <p className="text-base font-semibold dark:text-white">Number of late arrivals</p>
                <UserDropdown
                  data={dateSelection}
                  onChangeOption={(e) => {
                    setLateArrivalDropdown(e);
                    const data = handleDuration(e.value);
                    setLateArrival({
                      ...data,
                    });
                  }}
                  showAvatar={false}
                  className="h-[40px]"
                  defaultLabel={lateArrivalDateDropdown}
                  headerClass="text-xs p-0"
                  zIndex="dark:bg-444 dark:text-white z-50"
                />
              </div>
              <div className="grid grid-cols-2 gap-3">
                <RenderDayOfWeek
                  week="Monday"
                  value={
                    branches?.shiftLateClockIn?.filter(
                      (freq) => freq.dayOfWeek === 1
                    )[0]?.count
                  }
                />
                <RenderDayOfWeek
                  week="Tuesday"
                  value={
                    branches?.shiftLateClockIn?.filter(
                      (freq) => freq.dayOfWeek === 2
                    )[0]?.count
                  }
                />
                <RenderDayOfWeek
                  week="Wednesday"
                  value={
                    branches?.shiftLateClockIn?.filter(
                      (freq) => freq.dayOfWeek === 3
                    )[0]?.count
                  }
                />
                <RenderDayOfWeek
                  week="Thursday"
                  value={
                    branches?.shiftLateClockIn?.filter(
                      (freq) => freq.dayOfWeek === 4
                    )[0]?.count
                  }
                />
                <RenderDayOfWeek
                  week="Friday"
                  value={
                    branches?.shiftLateClockIn?.filter(
                      (freq) => freq.dayOfWeek === 5
                    )[0]?.count
                  }
                />
                <RenderDayOfWeek
                  week="Saturday"
                  value={
                    branches?.shiftLateClockIn?.filter(
                      (freq) => freq.dayOfWeek === 6
                    )[0]?.count
                  }
                />
                <RenderDayOfWeek
                  week="Sunday"
                  value={
                    branches?.shiftLateClockIn?.filter(
                      (freq) => freq.dayOfWeek === 0
                    )[0]?.count
                  }
                />
              </div>
            </section>
          </SkeletonLoading>

          <SkeletonLoading
            width="100%"
            height={200}
            loading={branches?.loading}
          >
            <section className="rounded-lg border-9FD border-1 px-3 py-4 dark:border-333 dark:bg-E1E">
              <p className="text-xs font-normal mb-2 dark:text-white">Hourly Pay ($)</p>
                <div className="flex items-end rounded">
                    <input onChange={handleOnChange} type="number" name="hourly-pay" className="bg-4F4 flex-1 px-3 py-4 dark:!bg-828 dark:text-white" defaultValue={hourlyPay ? convertToDollar(hourlyPay) : "--"}/>
                  <p
                    onClick={handleSaveHourlyPay}
                    className="flex-0 mr-4 text-sm font-semibold underline cursor-pointer dark:text-white"
                  >
                    Save
                  </p>
                </div>
            </section>
          </SkeletonLoading>

          <SkeletonLoading>
          <div className=" col-span-2 border-1 border-9FD rounded py-5 px-4 dark:border-333 dark:bg-primary">
              <div className="flex flex-row justify-between items-end mb-3">
                <p className='dark:text-white'>Licenses</p>
              </div>

              <table cellSpacing={0}>
                <thead className="bg-0F5 dark:bg-444">
                  <td className="py-5 px-2 dark:text-white">Licence Name</td>
                  <td className="py-5 px-2 dark:text-white">Licence Number</td>
                  <td className="py-5 px-2 dark:text-white">Expiring Date</td>
                  <td className="py-5 px-2 dark:text-white">Licence Image</td>
                  <td className="py-5 px-2 dark:text-white">Status</td>
                </thead>

                {licenses?.map((val, index) => (
                  <tr
                    key={index}
                    className="hover:cursor-pointer hover:shadow-lg h-16"
                    // onClick={() => handleOpenLincenseModal(val)}
                  >
                    <td className='border px-2 dark:text-white dark:bg-828'>{val.name}</td>
                    <td className='border px-2 dark:text-white dark:bg-828'>{val.licenceNumber}</td>
                    <td className='border px-2 dark:bg-828'>
                      {Date(val.expiration.toString())
                        .toString()
                        .substring(0, 15)}
                    </td>
                    <td onClick={() => handleOpenLincenseModal(val)} className='border px-2 dark:bg-828'>
                      <img src={roundimage} alt="img" />
                    </td>
                    <td className='border px-2 h-16 flex items-center justify-between relative dark:bg-828'>

                      {(licenseApproveDisapproveLoading && lisenceActionIndex === index) ? 
                      <p className='text-A5A text-[13px] dark:text-white'>Updating...</p> :
                      <span
                        style={{
                          padding: '5px',
                          color: val.status ? 'green' : '#FFD633',
                          border: val.status ? '1px solid green' : '1px solid #FFD633',
                          borderRadius: '5px',
                        }}
                      >
                       {val.status ? 'Approved' : 'Not approved' } 
                      </span> 
                      
                      }

                      {[1,2].includes(priviledge) && 
                      <BsThreeDotsVertical onClick={()=>{
                        setShowLisenceAction(!showLisenceAction)
                        setLisenceActionIndex(index)
                      }}
                      className='cursor-pointer'/>
                      }
                      {(showLisenceAction && lisenceActionIndex === index) &&
                       <div className="bg-white z-50 top-[80%] right-0 absolute drop-shadow-lg w-max">
                        <p 
                          onClick={()=>handleApproveDisapproveLisence(val.id, false)}
                          className="text-xs mb-3 text-171 dark:text-white hover:bg-slate-300 p-3 cursor-pointer font-bold">
                          disapprove
                        </p>

                        <p 
                          onClick={()=>handleApproveDisapproveLisence(val.id, true)}
                          className="text-xs mb-3 dark:text-white hover:bg-slate-300 p-3 cursor-pointer font-bold">
                          approve
                        </p>
                      </div>}
                     

                    </td>
                  </tr>
                ))}
                {
                  licenses?.length === 0 && <tr className='min-h-[900px]'>
                    <td colSpan={5}>
                      <div className="flex flex-col justify-center items-center">
                        <PlaceHolderOne />
                        {/* <img className="py-14" src={licenImage} width={200} height={200} alt="licen" />
                        <p className='dark:text-white'>No license Available</p> */}

                      </div>
                    </td>
                  </tr>
                }
              </table>
              </div>
          </SkeletonLoading>
          <LicenseModal
        open={openLicenseModal}
        readOnly={true}
        handleClose={() => setOpenLincenseModal(false)}
        id={selectedId}
        url={licenseImg}
        callback={()=>{}}
        openDeleteModal={setDeleteModal}
      />
        </div>
      </div>
      </AppLayout>
    </div>
  );
};
