import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoClose } from 'react-icons/io5';
import CustomModal from "../../Widgets/CustomModal";
import CustomButton from "./TestButton";
import { Branch } from '../../../Network/ServiceClass/Branch';
import { confirmQuickWithdrawalThunk } from '../../../Store/Inventory';
import { toast } from 'react-toastify';
import { getInventoryMeasurementUnit, getInventoryOrderRequest } from '../../../Network/ServiceClass/InventoryService';
import CustomDropdown from "../../Widgets/CustomDropdown";

const WithdrawModal = ({ isOpen, onClose, selectedItems, setSelectedItems, location }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.inventory);
  const [teamMembershipId, setTeamMembershipId] = useState(null);
  const [measurementUnits, setMeasurementUnits] = useState([]);
  const [selectedMeasurementUnits, setSelectedMeasurementUnits] = useState({});
  const [withdrawalAmounts, setWithdrawalAmounts] = useState({});
  const [orderRequests, setOrderRequests] = useState({});
  const companyState = useSelector((state) => state?.company);
  
  const branchService = new Branch();

  useEffect(() => {
    const getTeamMembership = async () => {
      try {
        const response = await branchService.fetchTeamMember({});
        if (response.status === 200) {
          setTeamMembershipId(response.data[0]?.id);
        }
      } catch (error) {
        console.error('Error fetching team membership:', error);
      }
    };

    const fetchMeasurementUnits = async () => {
      try {
        const response = await getInventoryMeasurementUnit();
        if (response.length > 0) {
          setMeasurementUnits(response);
        }
      } catch (error) {
        console.error('Error fetching measurement units:', error);
      }
    };

    const fetchOrderRequests = async () => {
      try {
        const response = await getInventoryOrderRequest();
        if (response.status === 200) {
          // Create a mapping of inventory item IDs to their order request IDs
          const orderRequestMap = {};
          response.data.forEach(request => {
            orderRequestMap[request.inventoryItemId] = request.id;
          });
          setOrderRequests(orderRequestMap);
        }
      } catch (error) {
        console.error('Error fetching order requests:', error);
      }
    };

    getTeamMembership();
    fetchMeasurementUnits();
    fetchOrderRequests();
  }, []);

  const handleWithdraw = () => {
    if (!location?.value) {
      toast.error('Please select a location');
      return;
    }

    if (!teamMembershipId) {
      toast.error('Team membership information not found');
      return;
    }

    try {
      const submitData = {
        teamMembershipId: teamMembershipId,
        withdrawalData: selectedItems.map(item => ({
          inventoryItemsId: item.id,
          quantityToWithdraw: Number(withdrawalAmounts[item.id] || 1),
          measurementUnitId: selectedMeasurementUnits[item.id],
          withdrawLocationId: location.value
        }))
      };

      dispatch(confirmQuickWithdrawalThunk(submitData))
        .unwrap()
        .then((response) => {
          // Check if response is an array and has items (matching your successful response)
          if (Array.isArray(response) && response.length > 0) {
            toast.success('Items withdrawn successfully');
            setSelectedItems([]);
            onClose();
          } else {
            toast.error('Failed to withdraw items');
          }
        })
        .catch((error) => {
          console.error('Error details:', error);
          toast.error(error?.response?.data?.message || 'Failed to withdraw items');
        });
    } catch (error) {
      console.error('Error in withdrawal:', error);
      toast.error('Failed to process withdrawal');
    }
  };

  return (
    <CustomModal
      open={isOpen}
      handleClose={onClose}
      modalClassName="max-w-[500px]"
      overlayClassName="left-[200px] bg-opacity-30"
    >
      <div className="pt-8">
        <h2 className="text-xl font-semibold mb-6">Withdraw Item</h2>
        
        <div className="space-y-6">
          <div>
            <p className="text-sm text-gray-600 mb-2">Location</p>
            <div className="p-3 bg-gray-50 rounded-lg">
              {location?.label || 'No location selected'}
            </div>
          </div>

          {/* Measurement Unit Dropdown */}
          <div>
            <p className="text-sm text-gray-600 mb-2">Selected Items</p>
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left text-sm text-gray-600 pb-2">Item</th>
                  <th className="text-left text-sm text-gray-600 pb-2">Measurement Unit</th>
                  <th className="text-left text-sm text-gray-600 pb-2">Quantity</th>
                  <th className="text-left text-sm text-gray-600 pb-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {selectedItems.map((item) => (
                  <tr key={item.id} className="bg-white">
                    <td className="p-2">
                      <span className="text-sm">{item.referenceNumber}</span>
                    </td>
                    <td className="p-2">
                      <CustomDropdown
                        data={[
                          { label: 'Select Unit', value: null },
                          ...(measurementUnits?.length !== 0 
                            ? measurementUnits?.map((unit) => ({
                                label: unit.name,
                                value: unit.id,
                              })) 
                            : [{label: 'No data', value: null}]
                          )
                        ]}
                        trimtext={true}
                        className={`w-full h-9 ${!selectedMeasurementUnits[item.id] ? 'border-red-500' : 'border-[#c7c5c5]'} rounded py-[14px] px-5`}
                        value={selectedMeasurementUnits[item.id] ? {
                          label: measurementUnits.find(unit => unit.id === selectedMeasurementUnits[item.id])?.name,
                          value: selectedMeasurementUnits[item.id]
                        } : { label: 'Select Unit', value: null }}
                        dropdownTitleClass="text-A5A"
                        optionClass="z-[1000] shadow-md min-h-[50px]"
                        onChangeOption={(val) => {
                          setSelectedMeasurementUnits(prev => ({
                            ...prev,
                            [item.id]: val?.value || null
                          }));
                        }}
                      />
                    </td>
                    <td className="p-2">
                      <input
                        type="number"
                        min="1"
                        value={withdrawalAmounts[item.id] || 1}
                        onChange={(e) => setWithdrawalAmounts(prev => ({
                          ...prev,
                          [item.id]: parseInt(e.target.value) || 1
                        }))}
                        className="w-20 p-1 border rounded"
                      />
                    </td>
                    <td className="p-2">
                      <button 
                        onClick={() => setSelectedItems(items => items.filter(i => i.id !== item.id))}
                        className="text-gray-400 hover:text-gray-600"
                      >
                        <IoClose size={20} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-end gap-3 mt-6">
            <CustomButton
              title="Cancel"
              onClick={onClose}
              className="min-w-[100px] h-[36px] rounded-[4px] border border-[#E8E8E8] 
                        font-['Plus_Jakarta_Sans'] font-medium text-[14px] text-[#848484] 
                        bg-white hover:bg-gray-50 transition-colors"
            />
            <CustomButton
              title="Withdraw Items"
              onClick={handleWithdraw}
              disabled={loading}
              className="min-w-[120px] h-[36px] rounded-[4px] 
                        font-['Plus_Jakarta_Sans'] font-medium text-[14px] text-white 
                        bg-[#00A862] hover:bg-[#008F53] transition-colors"
            />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default WithdrawModal;
