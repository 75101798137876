import { useState,useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import capitalizeFirstLetter from '../../../../../utils/capitalizeFirstLetter';
import dayjs from 'dayjs';
import CustomDropdown from '../../../../Widgets/CustomDropdown';
import { getInventoryItemsFuturePurchaseThunk } from '../../../../../Store/Inventory';

const PurchaseGraph = ({branches, payload, orderItems}) => {

    const dispatch = useDispatch()
    const loading = useSelector(state => state.inventory)?.loadingFuturePurchase
    const items = useSelector(state => state.inventory)?.itemsFuturePurchase
    const [location, setLocation] = useState({ value: null, label: "All Locations" })
    const units = items.map(x => ({location: x?.location[0], unit: x?.measurement_unit}))

    const [measurementUnit, setMeasurementUnit] = useState(null)

    useEffect(()=> {
        dispatch(getInventoryItemsFuturePurchaseThunk(payload))
        .then(val => {
            const data = val?.payload?.data?.data
            if (data && data.length > 0 && data[0]?.location?.length > 0) {
                setMeasurementUnit(
                    {
                        label: `${data[0]?.location[0]?.name} - ${data[0]?.measurement_unit?.name}`,
                        value: {locationId: data[0]?.location[0]?.id, unitId: data[0]?.measurement_unit?.id},
                        hexcode: data[0]?.location[0]?.hexcode  
                    }
                )
            }
        })
    },[])


    const handleChangeLocation = (e) => {
        setLocation(e)
        dispatch(getInventoryItemsFuturePurchaseThunk({...payload, locationId: e?.value}))
        .then(val => {
            const data = val?.payload?.data?.data
            setMeasurementUnit(
                {
                    label: `${data[0]?.location[0]?.name} - ${data[0]?.measurement_unit?.name}`,
                    value: {locationId: data[0]?.location[0]?.id, unitId: data[0]?.measurement_unit?.id},
                    hexcode: data[0]?.location[0]?.hexcode  
                }
            )
        })
    }

    const selectedItem = () => {
       return items.filter(x => (x.location[0]?.id === measurementUnit?.value?.locationId &&
            x.measurement_unit?.id === measurementUnit?.value?.unitId))[0]
    }


  return (
    <div className='py-3 px-1 mb-8 dark:text-white'>
        <p className="text-2xl font-medium mb-6">Future Purchase</p>

        {orderItems?.length !== 0 ?<div className='rounded py-4 pr-2 shadow-shift-shadow-small dark:bg-1F1 flex flex-col gap-5 h-[550px]'>


            <div className='px-6 flex justify-between'>

                <CustomDropdown
                    data={[
                    {
                        label: 'All Locations',
                        value: null,
                    },
                    ...findMatchingObjects(branches, orderItems)?.map((x) => ({ label: x?.name, value: x?.id, hexcode: x?.hexcode })),
                    ]?.map((team) => ({
                    label: team?.label,
                    value: team?.value,
                    hexcode: team?.hexcode
                    }))}
                    value={location}
                    onChangeOption={handleChangeLocation}
                    dropdownTitleClass="text-black"
                    optionClass="z-[500] max-h-[200px] w-44 overflow-y-scroll"
                    className="w-44 rounded h-[42px] dark:bg-color-333 dark:border-333"
                />

               {/* <CustomDropdown
                    data={
                       
                        units?.map((x) => ({
                                label: `${x?.location?.name} - ${x?.unit?.name}`,
                                value: {locationId: x?.location?.id, unitId: x?.unit?.id},
                                hexcode: x?.location?.hexcode  }))
                    ?.map((team) => ({
                        label: team?.label,
                        value: team?.value,
                        hexcode: team?.hexcode
                    }))}
                    optionClass="z-[500] w-44"
                    dropdownTitleClass="text-black"
                    className="w-44 rounded h-[42px] bg-white dark:bg-color-333 dark:border-333"
                    value={measurementUnit}
                    onChangeOption={(e) => { setMeasurementUnit(e) }}
                    /> */}
            </div>

            {!loading ? 
            <div className='flex flex-col gap-4 h-full'>

                <ResponsiveContainer className='' width={'100%'} height={'100%'}>
                    <AreaChart
                        width={500}
                        height={300}
                        data={selectedItem()?.data?.map(x => ({
                        quantity: x?.quantity,
                        date: dayjs(x?.date).format('DD MMM YYYY')
                    }))}
                        
                    >
                        <XAxis dataKey="date" tick={{ fontSize: 12, fontWeight: 700 }} interval={5}/>
                        <YAxis tick={{ fontSize: 12, fontWeight: 700 }}/>
                        <Tooltip content={(props) => CustomTooltip(props)} />
                        <Legend content={(props) => CustomLegend(props)} />

                        <Area type="monotone" dataKey={"quantity"} stroke="#82ca9d" fill="#82ca9d"  activeDot={{ r: 8 }}/>
                    </AreaChart>
                </ResponsiveContainer>

                <div className='px-6 text-[14px] font-bold text-gray-500 dark:text-gray-200 flex flex-col gap-2 '>
                    <p>Stipulated Item Exhaustion Date: {dayjs(selectedItem()?.expected_exhaustion).format('DD MMM YYYY')}</p>
                    <p>Stipulated Item Critical Limit Date: {dayjs(selectedItem()?.critical_limit_date).format('DD MMM YYYY')}</p>
                    <p>Purchase Proposal Date: {dayjs(selectedItem()?.purchase_prop).format('DD MMM YYYY')}</p>

                </div> 

                        
            </div>
            
            :
                <div className='flex justify-center mt-8'>
                <CircularProgress />
            </div> }

           

        </div>:
        <div className='rounded py-4  shadow-shift-shadow-small flex justify-center items-center h-[200px]'>

            <p className='text-gray-500'>Nothing here yet</p>

        </div>
        }

        
    </div>
  )
}

export default PurchaseGraph


const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      // You can access the tooltip values from 'payload'
      return (
        <div className="custom-tooltip bg-white dark:bg-138 rounded px-4 py-2 text-[14px] shadow-shift-shadow-small">
          <p>Date: {dayjs(label).format('DD MMM YYYY')}</p>
          {payload?.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color }}>
              {`${capitalizeFirstLetter(entry.name)}: ${entry.value}`}
            </p>
          ))}
        </div>
      );
    }
  
    return null;
  }
  
  const CustomLegend = ({ payload }) => {
    return (
      <div className='flex justify-center gap-5'>
        {payload?.map((entry, index) => (
          <div className='flex gap-2 items-center'>
            <p className='w-2 h-2 rounded-full'  style={{ background: entry.color }}/>
            <p key={`item-${index}`} style={{ color: entry.color }}>
            {capitalizeFirstLetter(entry.value)}
            </p>
          </div>
        ))}
      </div>
    );
  }
  

  const findMatchingObjects = (branch, orderItems) => {
    // Create an empty array to store the matching objects
    let matchingObjects = [];
  
    // Create a Set of the 'id' values in the second array for faster lookups
    const idSet = new Set(orderItems?.map(obj => obj?.locationId));
  
    // Loop through the first array
    for (let i = 0; i < branch.length; i++) {
      let element = branch[i];
  
      // Check if the 'id' of the element exists in the Set from the second array
      if (idSet.has(element?.id)) {
        matchingObjects.push(element);
      }
    }
  
    return matchingObjects;
  }
  
  