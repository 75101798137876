import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { sortBy } from 'lodash'
import { getInventoryManufacturersThunk, getInventorySuppliersThunk } from '../../../Store/Inventory'

const OrderTable = ({ activeFilter, searchQuery, selectedItems, setSelectedItems }) => {
  const dispatch = useDispatch();
  const rawInventoryItems = useSelector(state => state?.inventory?.items)
  const rawInventoryGroups = useSelector(state => state?.inventory?.groups)
  const manufacturers = useSelector(state => state.inventory.manufacturers);
  const suppliers = useSelector(state => state.inventory.suppliers);

  const inventoryItems = useMemo(() => rawInventoryItems || [], [rawInventoryItems])
  const inventoryGroups = useMemo(() => rawInventoryGroups || [], [rawInventoryGroups])

  useEffect(() => {
    dispatch(getInventoryManufacturersThunk());
    dispatch(getInventorySuppliersThunk());
  }, [dispatch]);

  const getManufacturerName = (currentItem) => {
    const manufacturerFromThunk = manufacturers?.find(m => m.id === currentItem?.inventoryManufacturerId);
    return manufacturerFromThunk?.name || '-';
  };

  const getSupplierName = (supplierId) => {
    const supplier = suppliers?.find(s => s.id === supplierId);
    return supplier?.name || '-';
  };

  const filteredItems = useMemo(() => {
    let items = inventoryItems

    if (searchQuery) {
      const query = searchQuery.toLowerCase()
      items = items.filter(item => 
        item.name?.toLowerCase().includes(query) ||
        item.referenceNumber?.toLowerCase().includes(query) ||
        item.description?.toLowerCase().includes(query)
      )
    }

    if (activeFilter !== 'All') {
      items = items.filter(item => {
        const group = inventoryGroups.find(g => g.id === item.groupId)
        return group?.name === activeFilter
      })
    }

    return sortBy(items, [item => {
      const group = inventoryGroups.find(g => g.id === item.groupId)
      return group?.name || ''
    }])
  }, [inventoryItems, inventoryGroups, activeFilter, searchQuery])

  const handleSelectRow = (item) => {
    if (selectedItems.some(i => i.id === item.id)) {
      setSelectedItems(prev => prev.filter(i => i.id !== item.id))
    } else {
      setSelectedItems(prev => [...prev, item])
    }
  }

  const getGroupName = (groupId) => {
    const group = inventoryGroups.find(group => group.id === groupId)
    return group?.name || '-'
  }

  return (
    <div className="relative h-[65vh] border-b border-[#EAEAEA] flex-1 z-10 w-[78vw]">
      <div className="overflow-auto h-full">
        <table className="w-full border-collapse">
          <thead>
            <tr className="border-b border-[#EAEAEA] bg-[#F1FBF2]">
              <th className="p-4 w-[52px] h-[52px] sticky top-0 bg-[#F1FBF2]">
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                Reference No
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                Item Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                Group
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                Item Price
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                Suppliers
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                Manufacturer
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {Array.isArray(filteredItems) && filteredItems.map((item) => (
              <tr 
                key={item.id} 
                className="h-[52px] border-b border-[#EAEAEA] hover:bg-gray-50 transition-colors"
              >
                <td className="p-4">
                  <div className="flex items-center justify-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 
                                accent-[#00A862]
                                focus:ring-[#00A862] focus:ring-offset-0 focus:ring-2
                                transition-colors cursor-pointer"
                      checked={selectedItems.some(i => i.id === item.id)}
                      onChange={() => handleSelectRow(item)}
                    />
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                  {item.referenceNumber || '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                  {item.name || '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                  {getGroupName(item.groupId)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                  ${(item.packageCost || 0).toFixed(2)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                  {getSupplierName(item.manAndSupp?.[0]?.supplierId)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                  {getManufacturerName(item)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default OrderTable
