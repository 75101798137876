import {useState, useEffect, useCallback} from 'react'
import AppLayout from '../../../Widgets/AppLayout'
import SearchInput from '../../../Widgets/SearchInput'
import CustomDropdown from '../../../Widgets/CustomDropdown'
import GlobalButton from '../../../Widgets/GlobalButton'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { getTransferItemsRequestThunk } from '../../../../Store/Inventory'
import { CircularProgress } from '@mui/material'
import dayjs from 'dayjs';
import { Branch } from '../../../../Network/ServiceClass/Branch'
import { statusColor } from '../../../../utils/inventoryStatus'
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter'
import { CustomPopper } from '../../../Widgets/CustomPopper'
import { ClickableItem } from '../../../Widgets/ClickableItem'
import { Storage } from '../../../../Network/StorageClass/StorageClass'
import { inventoryUpdateTransferItemsStatus } from '../../../../Network/ServiceClass/InventoryService'
import { toast } from 'react-toastify'
import { IoQrCode } from 'react-icons/io5';
import { usePDF } from 'react-to-pdf';
import QRCode from 'react-qr-code'
import { TableWrapper, TableHeader, Td, Tr } from '../../../Widgets/Table/TableWrapper'
import TutorialPanel from '../../../Widgets/Tutorial/TutorialPanel'
import { transferItemListTutorial } from '../../mock'
import TransferredItemsTable from './TransferredItemsTable'
import { BiTransfer } from 'react-icons/bi';
import { ReactComponent as TransferIcon } from '../../../../Assets/transfer.svg';




const branchService = new Branch()
const storage = new Storage()
const ListOfTransferedItems = () => {

    const { toPDF, targetRef } = usePDF({filename: 'item-transfer-request.pdf'});

    const dispatch = useDispatch()
    const itemsList = useSelector(state => state.inventory)?.transferRequestItems || []
    const loading = useSelector(state => state.inventory)?.loading
    const activeCompany = useSelector(state => state.company)?.activeCompany
    const [showPDFContent, setShowPDFContent] = useState(false)
    const [printData, setPrintData] = useState(null)
    const [branches, setBranches] = useState([])

    const [toggleMenu, setToggleMenu] = useState(null)
    const [menuIndex, setMenuIndex] = useState(null)
    const [isLoadingAction, setIsLoadingAction] = useState(false)

    const [withdrawalBranch, setWithdrawalBranch] = useState({label: 'From Location', value: null})
    const [transferedBranch, setTransferedBranch] = useState({label: 'To Location', value: null})


    const navigate = useNavigate()
    const [searchValue,setSearchValue] = useState('')

    const isAllow = () => {
        if ([1,2].includes(storage.getActiveCompany()?.memberships[0]?.privilege)) return true
        else return false
    }

    const teamMembershipId = storage.getActiveCompany()?.memberships[0]?.id



    const handleDownloadPdf = () => {
        setShowPDFContent(true)
        setTimeout(() => {
            toPDF();
            setShowPDFContent(false)
         }, 100);
    }

    const getItems = () => {
        dispatch(getTransferItemsRequestThunk({
            withdrawalLocation: withdrawalBranch.value, 
            transferedLocation: transferedBranch.value
        }))
    }

    useEffect(()=> {
        getItems()
    },[withdrawalBranch, transferedBranch])
    
    useEffect(()=> {
        branchService.getBranches()
        .then(val => {
            setBranches(val?.data.map(x => ({label: x.name, value: x.id, hexcode: x?.hexcode})))
        })
        getItems()
    },[])

    const handleSearch = useCallback(() => {
        let data = itemsList 
        if(searchValue.length !== 0){
          const pattern = new RegExp(`\D*${searchValue}\D*`,'i')
                data = itemsList.filter((val) => (
                    val.referenceNumber?.toLowerCase().match(pattern) ||
                    val.reference?.name?.toLowerCase().match(pattern) ||
                    val.batchIdentifier?.toLowerCase().match(pattern) )) 
        }
        return data
    
    },[searchValue, itemsList])


    const handleApprove = (value) => {
        setIsLoadingAction(true)
        const data = {
            referenceNumber: value?.referenceNumber,
            locationId: value?.transferLocation?.id,
            status: "approved",
            trackingStatus: "shipped",
            teamMemberId: teamMembershipId
        }

        inventoryUpdateTransferItemsStatus(data)
        .then(val => {
            if (val?.status === 200){
                toast.success('Approved Successfully')
                setToggleMenu(null)
                getItems()
            }else toast.error(val?.data?.message)
            setIsLoadingAction(false)
        })
    }


    return (
        <div>
            <AppLayout
                showAppBar={false}
                hideLiveUsers={true}
                custom="inventory"
                subCustom="transfered-items-list"
                title=""
                customTitle="Inventory"
                subtitle=""
            >
                <div className='flex flex-col absolute top-[15px] border-[#F1FBF2] rounded-[12px] w-full h-[95vh] bg-white'>
                    <div className='px-5 pt-4 flex flex-row gap-2'>
                        <TransferIcon />
                        <p className="font-[Plus Jakarta Sans] font-normal text-[14px] text-[#404040] mb-4">
                            Transfer Items
                        </p>
                    </div>

                    <div className="withdraw-items-search-container flex flex-col h-screen w-[80vw] gap-[20px] relative mt-5 pl-5">
                        <div className="withdraw-items-heading mt-[15px] h-[66px] w-[350px]">
                            <h1 className="font-[Plus Jakarta Sans] font-semibold text-[30px]">
                                Transferred Items List
                            </h1>
                            <p className="font-[Plus Jakarta Sans] font-normal text-[14px] leading-[22px] text-[#606060]">
                                List of transferred items from one location to another
                            </p>
                        </div>

                        <div className="withdraw-items-search flex flex-row items-center w-full gap-[20px] relative z-20">
                            <SearchInput 
                                placeholder="Search by name" 
                                className="w-[55vw] h-[40px] !rounded-[24px] border border-[#E8E8E8] placeholder-[#C3C3C3]-400 placeholder:font-[Plus Jakarta Sans] placeholder:text-sm"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <CustomDropdown 
                                data={branches}
                                value={withdrawalBranch}
                                onChangeOption={setWithdrawalBranch}
                                className="w-[10vw] h-[40px] border border-[#E8E8E8] !rounded-[24px] flex items-center px-4 text-[#848484] font-[Plus Jakarta Sans] text-sm"
                            />
                            <CustomDropdown 
                                data={branches}
                                value={transferedBranch}
                                onChangeOption={setTransferedBranch}
                                className="w-[10vw] h-[40px] border border-[#E8E8E8] !rounded-[24px] flex items-center px-4 text-[#848484] font-[Plus Jakarta Sans] text-sm"
                            />
                        </div>

                        <TransferredItemsTable 
                            loading={loading}
                            handleSearch={handleSearch}
                            handleDownloadPdf={handleDownloadPdf}
                            setPrintData={setPrintData}
                                isAllow={() => true}
                        />

                        <PdfTemp 
                            showPDFContent={showPDFContent} 
                            targetRef={targetRef} 
                            data={printData} 
                            company={activeCompany?.name || ''}
                        />


                    </div>

                    

                   
                    
                   
                </div>
            </AppLayout>
        </div>
    )
}

export default ListOfTransferedItems


const PdfTemp = ({showPDFContent, targetRef, data, company}) => {
    return (
        <div className={`absolute w-[100%] px-3 py-3 left-[-1500px] top-0 ${!showPDFContent && 'hidden'}`} ref={targetRef} 
                    >
                        <div className='flex flex-col gap-10'>

                            <div className='text-[18px] font-bold flex flex-col gap-2'>
                                <p>Company Name: {company}</p>
                                <p>Transfered From: {data?.fromLocation?.locationName}</p>
                                <p>Transfered To: {data?.toLocation?.locationName}</p>

                            </div>

                            <div >
                                <p className='text-[18px] font-bold mb-3'>Items List:</p>
                                <table>
                                    <tr className='font-bold'>
                                        <td className='pl-4 py-4 border-1 text-center border-[black]'>Ref No.</td>
                                        <td className='border-1 text-center border-[black]'>Name</td>
                                        <td className='border-1 text-center border-[black]'>From Location</td>
                                        <td className='border-1 text-center border-[black]'>To Location</td>
                                        <td className='border-1 text-center border-[black]'>Amount</td>
                                    </tr>
                                    <tbody>
                                        {data?.items.map(val => (
                                            <tr>
                                                <td className='pl-4 py-4 border-1 text-center border-[black]'>{val?.referenceNumber}</td>
                                                <td className='border-1 text-center border-[black]'>{val?.name}</td>
                                                <td className='border-1 text-center border-[black]'>{data?.fromLocation?.locationName}</td>
                                                <td className='border-1 text-center border-[black]'>{data?.toLocation?.locationName}</td>
                                                <td className='border-1 text-center border-[black]'>{val?.quantityWithdrawn}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className='w-full flex justify-center mt-10'>
                                <div className='bg-white'>
                                    <QRCode
                                    size={200}
                                    value={data?.batchIdentifier || ''} />
                                </div>

                            </div>
                        </div>
        </div>
    )
}