import React from 'react';
import FormModal from '../../../../../Widgets/Animation/FormModal';
import { Stack } from '@mui/material';
import {useFormik} from 'formik'
import * as Yup from 'yup'
import { TextAndField, Text, Field, FillButton } from '../../../../../Widgets/FormUtils/FormUtils';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { createInventoryGroupsThunk, getInventoryGroupsThunk } from '../../../../../../Store/Inventory';
import { toast } from 'react-hot-toast';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '524px',
  maxHeight: '100vh',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '11px',
  overflowY: 'auto',
  overflowX: 'hidden',
};

export const CreateGroupModal = ({
  open = false,
  handleClose = () => {},
  loading = false,
}) => {
  const dispatch = useDispatch();
  const { loading: isLoading } = useSelector((state) => state.inventory);

  const formik = useFormik({
    initialValues: {
      name: '',
      description: ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("This is required"),
      description: Yup.string().required("This is required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await dispatch(createInventoryGroupsThunk(values)).unwrap();
        
        if (response.status === 201 || response.status === 200) {
          // Refresh the groups list after successful creation
          await dispatch(getInventoryGroupsThunk());
          
          toast.success('Group created successfully');
          formik.resetForm();
          handleClose();
        } else {
          toast.error('Failed to create group');
        }
      } catch (error) {
        console.error('Error details:', error);
        toast.error(error?.response?.data?.message || 'Failed to create group');
      }
    }
  });

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack className='dark:bg-138 dark:text-white' direction="column" sx={style} gap={3}>
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-xl font-semibold">Create new item</h2>
          <CloseIcon 
            className="cursor-pointer text-gray-500 hover:text-gray-700"
            onClick={() => {
              formik.resetForm();
              handleClose();
            }}
          />
        </div>

        <div className="w-full">
          <input
            type="text"
            placeholder="Enter name"
            className="w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:ring-1 focus:ring-green-500"
            value={formik.values.name}
            onChange={formik.handleChange}
            name="name"
          />
          {formik.errors.name && formik.touched.name && (
            <p className="text-red-500 text-sm mt-1">{formik.errors.name}</p>
          )}
        </div>

        <div className="w-full">
          <textarea
            placeholder="Enter description"
            className="w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:ring-1 focus:ring-green-500 min-h-[100px] resize-none"
            value={formik.values.description}
            onChange={formik.handleChange}
            name="description"
          />
          {formik.errors.description && formik.touched.description && (
            <p className="text-red-500 text-sm mt-1">{formik.errors.description}</p>
          )}
        </div>

        <button
          onClick={formik.handleSubmit}
          disabled={isLoading}
          className="w-full bg-[#00A651] text-white py-2 rounded-md hover:bg-[#008A44] transition-colors mt-4"
        >
          {isLoading ? "Loading..." : "Submit"}
        </button>
      </Stack>
    </FormModal>
  );
};
