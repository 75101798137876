import { useNavigate } from "react-router";
import {ReactComponent as NewSupserLogoIcon} from "../../../../Assets/new_sync_sync_logo.svg";
import { Field, FillButton, Text, TextAndField } from "../../../Widgets/FormUtils/FormUtils";
import "../SignUpScreen.css"
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useEffect } from "react";

export function SignGetStart({ handleKeypress, setCurrFlow, handleSaveDate }){
    const navigate = useNavigate()

     
  const formik = useFormik({
    initialValues: {
      email: ''
    },

    validationSchema: Yup.object({
      email: Yup.string().email('must be a valid email').required("Please enter valid email address")
    }),
    onSubmit: (value) => {
      handleSaveDate(value);
      setCurrFlow(1)
    },
  });

    return (
        <div className="mt-20">
        <NewSupserLogoIcon />
        <p className="text-[32px] mt-10 font-semibold">Get Started with SuprSync</p>
        <p className='text-sm font-medium text-A6A mb-6'>Enter your credentials to access your account.</p>
        <div className="flex flex-col mt-5 gap-10 dark:text-white rounded-input">
        <TextAndField>
            <Text text={'Email Address'} />
            <Field
              text={'Kindly enter an email'}
              value={formik.values.email}
              handleChange={formik.handleChange}
              name={'email'}
              formik={formik}
              size="large"
              onKeyDown={handleKeypress}
            />

          </TextAndField>
          <div>
            <FillButton
                type="submit"
                text={'Get Started'}
                width={'100%'}
                height={'50px'}
                callBack={()=> {
                  formik.handleSubmit()
                  console.log(formik, "lohhgggdd")

                  // if(!!!formik.errors["email"]){
                  //   setCurrFlow(1)
                  // }
                  
                }}
                sx={{ borderRadius: 100}}
              />
              <div className="flex flex-row mt-2 justify-center items-center gap-1 text-[10px]">
              <p className="text-[#696969] text-[16px]">Already have an account? </p>
              <p
                className="ml-[10px] cursor-pointer text-D57 text-[16px]"
                onClick={() => navigate('/login')}
              >
                Log in
              </p>
              {/* <FiArrowUpRight /> */}
            </div>
          </div>
        </div>
        </div>
    )
}