import React from 'react'
import AppBar from "@mui/material/AppBar";
import AppBarTop from './AppBar';
import { Toolbar } from '@mui/material';
import { SideBarLayout } from './SideBarLayout';

const drawerWidth = 264;

export function NewSideBar(props) {

    return (
        <section className='w-screen h-screen flex items-end'>

            <SideBarLayout />
            <div className='w-[calc(100%-264px)] h-full overflow-y-scroll relative'>
                <AppBar
                    elevation={0}
                    position="fixed"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        bgcolor: "white",
                        color: "black",
                    }}
                    className="dark:bg-138"
                >
                    {props.showAppBar !== false && (
                        <AppBarTop
                            title={props?.pageTitle || ""}
                            TitleIcon={props?.titleIcon}
                            layoutProps={props.layoutProps}
                            layoutClass={props.layoutClass}
                            custom={props.customTitle}
                            withoutHeaderTitle={props.withoutHeaderTitle}
                        />
                    )}
                </AppBar>
                <Toolbar />
                <div className='p-5 mt-10 mb-40'>
                    {props.children}
                </div>
            </div>
        </section>
    )
}