import { useState, useEffect } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import { Storage } from '../../../Network/StorageClass/StorageClass';
import ButtonWithIcon from '../../Widgets/ButtonWithIcon';
import { AiOutlineDelete } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import AppLayout from '../../Widgets/AppLayout';
import { getInventoryMeasurementUnitThunk } from '../../../Store/Inventory';
import { NewUnitModal } from './Modal/NewUnitModal';
import { DeleteModal } from './Modal/DeleteModal';
import SearchInput from '../../Widgets/SearchInput';
import { TableWrapper, TableHeader, Td, Tr } from '../../Widgets/Table/TableWrapper';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { measurementTutorial } from '../mock';
import { TbRuler } from 'react-icons/tb';
import { ReactComponent as MeasurementIcon } from '../../../Assets/measurement.svg';

export const InventoryMeasurement = () => {
  const storage = new Storage();
  const dispatch = useDispatch()
  const priviledge = storage.getActiveCompany().memberships[0].privilege;
  const loading = useSelector(state => state.inventory)?.loading

  const units = useSelector(state => state.inventory)?.measurementUnits


  const [openDeleteModal, setToggleDeleteModal] = useState(false);
  const [openAddUnit, setOpenAddUnit] = useState(false);

  const [data, setData] = useState(null);

  const [searched, setSearched] = useState('');

  const handleOpen = () => {
    setOpenAddUnit(true);
  };

 
  const handleClose = () => {
    setOpenAddUnit(false);
  };

  const closeDeleteModal = () => {
    setToggleDeleteModal(false)
  }

  const handleOpenDeleteModal = (value) => {
    setToggleDeleteModal(true)
    setData(value);
  }

  const callback = () => {
    dispatch(getInventoryMeasurementUnitThunk())
  };

  useEffect(() => {
    callback();
  }, []);


  const handleSearch = (val) => {
    const filteredData = units?.filter((x) => x?.name?.toLowerCase().includes(val?.toLowerCase()))
    return filteredData;
  }

  const handleOnSearch = (e) => {
    setSearched(e.target.value);
  }

  return (
    <section className="homepage">
      <DeleteModal
      open={openDeleteModal}
      data={data}
      handleClose={closeDeleteModal}
      loading={loading}
      callback={callback}
      />


      <AppLayout
        hideLiveUsers={true}
        showAppBar={false}
        custom="inventory"
        subCustom="measurement"
        title=""
        customTitle=" "
        subtitle=""
      >
        <div className=" absolute top-[15px] left-[15px] w-[calc(100%-15px)] h-[95vh] overflow-y-auto border border-[#EAEAEA] rounded-[12px] flex flex-col bg-white">
            
              <div className=" flex flex-col w-full h-[30px] pl-5 pt-2 gap-[10px]">
                    <div className="flex items-center gap-2">
                          <MeasurementIcon />
                          <p className="font-[Plus Jakarta Sans] font-medium text-[12px] text-[#7a7a7a] leading-[15px]">
                              Measurement
                          </p>
                    </div>
                    <div className='flex flex-row justify-between items-center'>
                      <div className=" flex flex-col w-full h-full">
                          <h1 className="font-[Plus Jakarta Sans] font-semibold text-[30px]">
                              Inventory Measurement
                          </h1>
                          <p className="font-[Plus Jakarta Sans] font-medium text-[14px] text-[#7a7a7a] leading-[15px]">
                              Managing Measurement 
                          </p>
                        </div>
                        <div className='pr-5'>
                          {[1, 2].includes(priviledge) && (
                            <ButtonWithIcon 
                              title='Add new measurement' 
                              type='text' 
                              handleClick={handleOpen}
                              className="!rounded-[24px] font-semibold text-[16px] !bg-white w-[230px] h-[48px]"
                            />
                          )}
                        </div>
                    </div>
                </div>
            <div className="flex flex-col gap-3 p-7 h-[70vh] mt-[120px]">

              <div className="flex justify-between items-center">

                <SearchInput 
                  inputStyle='placeholder:!text-grey-600'
                  className="w-[77vw] h-[40px] !rounded-[24px] border border-[#E8E8E8] placeholder-[#C3C3C3]-400 placeholder:font-[Plus Jakarta Sans] placeholder:text-sm"
                  placeholder='Search units'
                  onChange={handleOnSearch}
                />
              </div>

              {!loading && (
                  <TableWrapper className='mb-12 w-full'>
                    <table className="w-full">
                        <thead>
                            <tr className="bg-[#F1FBF2]">
                                <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider w-[35%]">
                                    Name
                                </th>
                                <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider w-[35%]">
                                    Description
                                </th>
                                <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider w-[20%]">
                                    Symbol
                                </th>
                                <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider w-[5%]">
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {handleSearch(searched)?.map((value, index) => (
                                <tr key={index} className="border-b border-[#EAEAEA] hover:bg-gray-50">
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-[#404040]">
                                        {value.name}
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                                        {value?.description}
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                                        {value.symbol}
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A]" onClick={() => handleOpenDeleteModal(value)}>
                                        <AiOutlineDelete />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                  </TableWrapper>
              )}

              {loading && (
                <Stack mt={5} direction="row" justifyContent="center">
                  <CircularProgress />
                </Stack>
              )}
            </div>
        </div>
      </AppLayout>

      <NewUnitModal
        open={openAddUnit}
        handleClose={handleClose}
        callback={callback}
      />
    </section>
  );
};
