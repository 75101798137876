import React,{useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CustomPopper } from '../../Widgets/CustomPopper'
import { ClickableItem } from '../../Widgets/ClickableItem'
import { CircularProgress } from '@mui/material'
import { DivShadow } from './DataTable'
import CustomDropdown from '../../Widgets/CustomDropdown'
import { getInventoryPendingTransferApprovalThunk } from '../../../Store/Inventory'
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter'
import { Storage } from '../../../Network/StorageClass/StorageClass'
import { inventoryUpdateTransferItemsStatus } from '../../../Network/ServiceClass/InventoryService'
import { toast } from 'react-toastify'
import ExportTemplate from './Widget/ExportTemplate'

const storage = new Storage()
const PendingTransfer = ({statusColor, branches}) => {
    const dispatch = useDispatch()

    const isAllow = () => {
        if ([1,2].includes(storage.getActiveCompany()?.memberships[0]?.privilege)) return true
        else return false
    }

    const teamMembershipId = storage.getActiveCompany()?.memberships[0]?.id

    const [toggleMenu, setToggleMenu] = useState(null)
    const [isLoadingAction, setIsLoadingAction] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [withdrawalBranch, setWithdrawalBranch] = useState({label: 'All location', value: null})
    const [menuIndex, setMenuIndex] = useState(null)


    const transferApprovalRow = useSelector(state => state.inventory)?.pendingTransferApproval
   

    const getPendingTransfer = (id=null) => {
        dispatch(getInventoryPendingTransferApprovalThunk(id))
        .then(_ => setIsLoading(false))
    }
    useEffect(()=> {
        setIsLoading(true)
        getPendingTransfer(withdrawalBranch.value)
    },[withdrawalBranch])
    

    const handleApprove = (value) => {
        setIsLoadingAction(true)

        const data = {
            referenceNumber: value?.referenceNumber,
            locationId: value?.transferLocation?.id,
            status: "approved",
            trackingStatus: "shipped",
            teamMemberId: teamMembershipId
        }

        inventoryUpdateTransferItemsStatus(data)
        .then(val => {
            if (val?.status === 200){
                toast.success('Approved Successfully')
                setToggleMenu(null)
                getPendingTransfer(withdrawalBranch.value)
            }else toast.error(val?.data?.message)
            setIsLoadingAction(false)
        })
    }
  return (
    <div>

        <DivShadow className='max-h-[70vh]'>


            <ExportTemplate 
                title='Pending Transfer Request'
                dropDownData={branches}
                dropDownValue={withdrawalBranch}
                onChangeDropDown={(val)=>setWithdrawalBranch(val)}

                exportData={transferApprovalRow?.map(x => ({
                    'Reference Number': x?.referenceNumber,
                    'Batch ID': x?.batchIdentifier,
                    'Name': x?.reference?.name,
                    'Manufacturer': x?.reference?.inventoryManufacturer?.name,
                    'Transfered From': x?.withdrawLocation?.name,
                    'Transfered To': x?.transferLocation?.name,
                    'Unit': x?.measurementUnit?.name,
                    'Amount': x?.quantityWithdrawn,
                    'Status': capitalizeFirstLetter(x?.status)



                }))}
                fileName={'transfer-request'}
            />


            <div className='rounded-md border-1 dark:border-333 mt-6 min-h-[40vh] max-h-[40vh] mb-3 overflow-y-auto text-[14px]'>
            <table className="w-full border-collapse">
                <thead>
                    <tr className="border-b border-[#EAEAEA] bg-[#F1FBF2]">
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Ref No.
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            BatchID
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Name
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Manufacturer
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Transfered From
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Transfered To
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Unit
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Amount
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Status
                        </th>
                    </tr>
                </thead>

                {!isLoading ? <tbody className="bg-white">
                    {transferApprovalRow?.length !== 0 ?
                        transferApprovalRow.map(val => (
                            <tr className="h-[52px] border-b border-[#EAEAEA] hover:bg-gray-50 transition-colors">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    {val?.referenceNumber}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    {val?.batchIdentifier}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    {val?.reference?.name}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    {val?.reference?.inventoryManufacturer?.name}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    <div className='flex items-center gap-2'>
                                        <div className='w-2 h-2 rounded-full' style={{background: val?.withdrawLocation?.hexcode}}/>
                                        <span>{val?.withdrawLocation?.name}</span>
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    <div className='flex items-center gap-2'>
                                        <div className='w-2 h-2 rounded-full' style={{background: val?.transferLocation?.hexcode}}/>
                                        <span>{val?.transferLocation?.name}</span>
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    {val?.measurementUnit?.name}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    {val?.quantityWithdrawn}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm">
                                    {isAllow() ? 
                                        <div 
                                            onClick={(e)=> {
                                                e.stopPropagation()
                                                !isLoadingAction && setToggleMenu(toggleMenu ? null : e.currentTarget)
                                                !isLoadingAction && setMenuIndex(val?.id)
                                            }}
                                            className={`cursor-pointer ${statusColor[val?.status]?.color}`}>
                                            {capitalizeFirstLetter(val?.status)}
                                        </div> :
                                        <div className={`${statusColor[val?.status]?.color}`}>
                                            {capitalizeFirstLetter(val?.status)}
                                        </div>
                                    }

                                    {menuIndex === val?.id &&
                                        <CustomPopper 
                                            openPopper={toggleMenu} 
                                            handleClose={()=> {
                                                !isLoadingAction && setToggleMenu(null)
                                            }} 
                                            className='z-[1500]' 
                                            placement='bottom'
                                        >
                                            <div className='min-h-[50px] min-w-[150px] mt-1 py-3 font-bold bg-white shadow-shift-shadow-small'>
                                                {!isLoadingAction ? 
                                                    <div className='flex flex-col gap-2 text-[#738583] text-[12px]'>
                                                        <ClickableItem 
                                                            className={'py-1 text-[black]'} 
                                                            text={'Approve Request'} 
                                                            handleClick={()=> handleApprove(val)}
                                                        />
                                                    </div>
                                                    :
                                                    <div className="flex h-full items-center justify-center">
                                                        <CircularProgress/>
                                                    </div>
                                                }
                                            </div>          
                                        </CustomPopper>
                                    }
                                </td>
                            </tr>
                        ))
                        : <tr>
                            <td colSpan={9} className="text-center py-4 text-[#7A7A7A]">Nothing to see here</td>
                        </tr>
                    }
                </tbody> : 
                <tbody>
                    <tr>
                        <td colSpan={9} className="text-center py-4">
                            <CircularProgress />
                        </td>
                    </tr>
                </tbody>}
            </table>

            </div>
        </DivShadow>
    </div>
  )
}

export default PendingTransfer