import React from "react";
import { ReactComponent as GettingIcon } from "../../Assets/menu/get_started_menu_icon.svg";
import { ReactComponent as SettingIcon } from "../../Assets/menu/setting_menu_icon.svg";
import { ReactComponent as LocationIcon } from "../../Assets/menu/location_menu_icon.svg";
import { ReactComponent as TaskIcon } from "../../Assets/menu/task_menu_icon.svg";
import { ReactComponent as JobIcon } from "../../Assets/menu/job_role_menu_icon.svg";
import { ReactComponent as JobGroupIcon } from "../../Assets/menu/job_group_menu_icon.svg";
import { ReactComponent as AnnouncementIcon } from "../../Assets/menu/unavailanle_menu_icon.svg";
import { ReactComponent as ShiftTypes } from "../../Assets/menu/shift_types_menu_icon.svg";
import { ReactComponent as Holiday } from "../../Assets/menu/holidays_nenu_icon.svg";
import { ReactComponent as SkillSetIcon } from "../../Assets/menu/holidays_nenu_icon.svg";
import { ReactComponent as DataIcon } from "../../Assets/menu/holidays_nenu_icon.svg";
import { ReactComponent as ManagementIcon } from "../../Assets/menu/rotation_menu_icon.svg";
import { ReactComponent as CompanyDetails } from "../../Assets/menu/company_details_menu_icon.svg";
import { ReactComponent as ReportIcon } from "../../Assets/menu/report_menu_icon.svg";
import { ReactComponent as MessageIcon } from "../../Assets/menu/sms_menu_icon.svg";
import { ReactComponent as TutorialIcon } from "../../Assets/menu/supersync_ai_menu_icon.svg";
import { ReactComponent as SearchIcon } from "../../Assets/menu/search-normal.svg";
import { ReactComponent as IoIosNotificationsOutline } from "../../Assets/notification_side_menu.svg";
import { AiOutlineHome } from 'react-icons/ai';
import { BsListCheck } from 'react-icons/bs';
import { BiTransfer, BiData } from 'react-icons/bi';
import { AiOutlineFolder } from 'react-icons/ai';
import { HiOutlineDocumentMagnifyingGlass } from 'react-icons/hi2';
import { MdDataSaverOff } from 'react-icons/md';
import { MdSwapVert } from 'react-icons/md';
import { VscHistory } from 'react-icons/vsc';
import { TbClipboardList } from 'react-icons/tb';
import { FaCut } from 'react-icons/fa';
import { FaRuler } from 'react-icons/fa';
import { ReactComponent as InventoryIcon } from '../../Assets/inventory.svg';
import { ReactComponent as TransferIcon } from '../../Assets/transfer.svg';
import { ReactComponent as WithdrawIcon } from '../../Assets/withdraw.svg';
import { ReactComponent as LogsIcon } from '../../Assets/logs.svg';
import { ReactComponent as MeasurementIcon } from '../../Assets/measurement.svg';
import { ReactComponent as ManSuppIcon } from '../../Assets/mansupp.svg';
import { ReactComponent as BigDataIcon } from '../../Assets/Bigdata.svg';
// import { IoIosNotificationsOutline } from 'react-icons/io'

export const sideMenu = [
  {
    label: "MAIN MENU",
    permission: [1, 2, 3],
    route: "/home",
    icon: null,
    domain: null,
    children: [
      {
        label: "Getting Started",
        permission: [1, 2, 3],
        route: "/getting-started",
        domain: "/getting-started",
        icon: AiOutlineHome,
      },
      {
        label: "Home",
        permission: [1, 2, 3],
        route: "/home",
        domain: "/home",
        icon: AiOutlineHome,
      },
      {
        label: "Search",
        permission: [1, 2, 3],
        route: "/global-search",
        domain: "/global-search",
        icon: SearchIcon,
      },
      {
        label: "Notification",
        permission: [1, 2, 3],
        route: "/notifications",
        domain: "/notifications",
        icon: IoIosNotificationsOutline,
      },
    ],
  },
  {
    label: "COMPANY",
    permission: [1, 2, 3],
    icon: null,
    domain: null,
    route: "/branches",
    children: [
      {
        label: "Location",
        permission: [1, 2],
        route: "/branches",
        domain: "/branches",
        icon: LocationIcon,
      },
      {
        label: "Write ups",
        permission: [1, 2, 3],
        route: "/write-ups",
        domain: "/write-ups",
        icon: TaskIcon,
      },
      {
        label: "Ticket Management",
        permission: [1, 2, 3],
        route: "/ticket-management",
        domain: "/ticket-management",
        icon: TaskIcon,
      },
      {
        label: "Job roles",
        permission: [1, 2, 3],
        route: "/jobs",
        domain: "/jobs",
        icon: JobIcon,
      },
      {
        label: "Job Groups",
        permission: [1, 2, 3],
        route: "/job-groups",
        domain: "/job-groups",
        icon: JobGroupIcon,
      },
      {
        label: "Announcements",
        permission: [1, 2, 3],
        route: "/announcement",
        domain: "/announcement",
        icon: AnnouncementIcon,
      },
      {
        label: "Unavailable Days",
        permission: [1, 2, 3],
        route: "/unavailable-days",
        domain: "/unavailable-days",
        icon: AnnouncementIcon,
      },
      {
        label: "Shift types",
        permission: [1, 2, 3],
        route: "/templates",
        domain: "/templates",
        icon: ShiftTypes,
      },
      {
        label: "Holidays",
        permission: [1, 2, 3],
        route: "/holidays",
        domain: "/holidays",
        icon: Holiday,
      },
      {
        label: "Skill set",
        permission: [1, 2, 3],
        route: "/skillset",
        domain: "/skillset",
        icon: SkillSetIcon,
      },
      {
        label: "Data",
        permission: [1, 2, 3],
        route: "/company-data",
        domain: "/company-data",
        icon: DataIcon,
      },
      {
        label: "Rotation Management",
        permission: [1, 2],
        route: "/rotation-management",
        domain: "/rotation-management",
        icon: ManagementIcon,
      },
      {
        label: "Company details",
        permission: [1, 2],
        route: "/company-details",
        domain: "/company-details",
        icon: CompanyDetails,
      },
    ],
  },
  {
    label: "SHIFTS",
    permission: [1, 2, 3],
    icon: null,
    domain: null,
    route: "/schedules",
    children: [
      {
        label: "Schedules",
        permission: [1, 2, 3],
        route: "/schedules",
        domain: "/schedules",
        icon: null,
      },
      {
        label: "Location Schedules",
        permission: [1, 2, 3],
        route: "/branch-schedule",
        domain: "/branch-schedule",
        icon: null,
      },
      {
        label: "Combined Schedules",
        permission: [1, 2, 3],
        route: "/combined-schedule",
        domain: "/combined-schedule",
        icon: null,
      },
      {
        label: "Limbo Schedule",
        permission: [1, 2],
        route: "/limbo-schedule",
        domain: "/limbo-schedule",
        icon: null,
      },
      {
        label: "Swap offers",
        permission: [1, 2, 3],
        route: "/swap-offers",
        domain: "/swap-offers",
        icon: null,
      },
      {
        label: "Time off",
        permission: [1, 2, 3],
        route: "/time-off",
        domain: "/time-off",
        icon: null,
      },
      {
        label: "Clock in Clock Out",
        permission: [1, 2],
        route: "/clock-in-clock-out",
        domain: "/clock-in-clock-out",
        icon: null,
      },
    ],
  },

  {
    label: "INVENTORY (BETA)",
    permission: [1, 2],
    icon: null,
    domain: null,
    route: "/inventory-items",
    children: [
      {
        label: "Inventory Items",
        permission: [1, 2],
        route: "/inventory-items",
        domain: "/inventory-items",
        icon: InventoryIcon,
      },
      {
        label: "Transfer Items",
        permission: [1, 2],
        route: "/transfer-items",
        domain: "/transfer-items",
        icon: TransferIcon,
      },
      {
        label: "Transfered Requests",
        permission: [1, 2],
        route: "/transfered-items-list",
        domain: "/transfered-items-list",
        icon: TransferIcon,
      },
      {
        label: "Create order request",
        permission: [1, 2],
        route: "/create-order-request",
        domain: "/create-order-request",
        icon: AiOutlineFolder,
      },
      {
        label: "Order Request",
        permission: [1, 2],
        route: "/stock-order-request",
        domain: "/stock-order-request",
        icon: AiOutlineFolder,
      },
      {
        label: "Back Order Request",
        permission: [1, 2],
        route: "/back-order-request",
        domain: "/back-order-request",
        icon: HiOutlineDocumentMagnifyingGlass,
      },
      {
        label: "Quick withdraw",
        permission: [1, 2],
        route: "/quick-withdrawal",
        domain: "/quick-withdrawal",
        icon: WithdrawIcon,
      },
      {
        label: "Logs",
        permission: [1, 2],
        route: "/inventory-logs",
        domain: "/inventory-logs",
        icon: LogsIcon,
      },
      {
        label: "Inventory data",
        permission: [1, 2],
        route: "/inventory-data",
        domain: "/inventory-data",
        icon: BigDataIcon,
      },
      {
        label: "Suppliers/Manufacturers",
        permission: [1, 2],
        route: "/suppliers",
        domain: "/suppliers",
        icon: ManSuppIcon,
      },
      {
        label: "Measurement",
        permission: [1, 2],
        route: "/inventory-measurement",
        domain: "/inventory-measurement",
        icon: MeasurementIcon,
      },
    ],
  },
  {
    label: "SETTING",
    permission: [1, 2, 3],
    icon: null,
    domain: null,
    route: "/subscriptions",
    children: [
      {
        label: "Subscriptions",
        permission: [1, 2, 3],
        route: "/subscriptions",
        domain: "/subscriptions",
        icon: SettingIcon,
      },
    ],
  },
  {
    label: "HELP",
    permission: [1, 2, 3],
    icon: null,
    domain: null,
    route: "/tutorials",
    children: [
      {
        label: "Tutorials",
        permission: [1, 2, 3],
        route: "/tutorials",
        domain: "/tutorials",
        icon: TutorialIcon,
      },
    ],
  },

  {
    label: "Reports",
    permission: [1, 2],
    domain: "/reports",
    route: "/reports",
    children: null,
    icon: ReportIcon,
  },
  {
    label: "Tasks",
    permission: [1, 2, 3],
    domain: "/tasks",
    route: "/tasks",
    children: null,
    icon: TaskIcon,
  },
  {
    label: "Messages",
    permission: [1, 2, 3],
    domain: "/messages",
    route: "/messages",
    children: null,
    icon: MessageIcon,
  },
];
