import React from 'react';
import { IoQrCode } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { CircularProgress } from '@mui/material';

const TransferredItemsTable = ({ 
    loading, 
    handleSearch, 
    handleDownloadPdf, 
    setPrintData,
    isAllow 
}) => {
    const navigate = useNavigate();

    return (
        <div className="relative w-[77vw]">
            <table className="w-full text-left">
                <thead>
                    <tr className="bg-[#F1FBF2]">
                        <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
                            BatchID
                        </th>
                        <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
                            Transferred From
                        </th>
                        <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
                            Transferred To
                        </th>
                        <th className="px-3 py-3 text-center text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
                            Amount
                        </th>
                        <th className="px-3 py-3 text-center text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
                            No. of items
                        </th>
                        <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
                            Date
                        </th>
                        <th className="px-3 py-3 w-[5%]"></th>
                    </tr>
                </thead>
                <tbody>
                    {!loading ? (
                        handleSearch()?.length !== 0 ? (
                            handleSearch().map(val => (
                                <tr
                                    key={val?.batchIdentifier}
                                    onClick={() => {
                                        isAllow() && navigate(`/transfered-items-list/${val?.batchIdentifier}`)
                                    }}
                                    className={`border-b border-[#EAEAEA] hover:bg-gray-50 ${
                                        isAllow() && 'cursor-pointer'
                                    }`}
                                >
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-[#404040]">
                                        {val?.batchIdentifier}
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                                        <div className='flex items-center gap-2'>
                                            <div 
                                                className='w-2 h-2 rounded-full' 
                                                style={{background: val?.fromLocation?.hexcode}}
                                            />
                                            <span>{val?.fromLocation?.locationName}</span>
                                        </div>
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                                        <div className='flex items-center gap-2'>
                                            <div 
                                                className='w-2 h-2 rounded-full' 
                                                style={{background: val?.toLocation?.hexcode}}
                                            />
                                            <span>{val?.toLocation?.locationName}</span>
                                        </div>
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A] text-center">
                                        {val?.items.length || 0}
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A] text-center">
                                        {val?.items.length === 0 
                                            ? 0 
                                            : val?.items.reduce((acc, curr) => acc + curr.quantityWithdrawn, 0)
                                        }
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                                        {dayjs(val?.items[0]?.createdOn).format('YYYY-MM-DD')}
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap">
                                        <div className='flex justify-center'>
                                            <IoQrCode 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDownloadPdf();
                                                    setPrintData(val);
                                                }}
                                                className='cursor-pointer text-[#404040] hover:text-[#666666] text-lg'
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={8} className='text-center py-8 text-[#A5A5A5] text-sm'>
                                    Nothing to see here
                                </td>
                            </tr>
                        )
                    ) : (
                        <tr>
                            <td colSpan={8} className='text-center py-8'>
                                <CircularProgress />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default TransferredItemsTable;