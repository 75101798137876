import React,{useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { DivShadow } from './DataTable'
import { getInventoryExpiredItemsThunk } from '../../../Store/Inventory'
import dayjs from 'dayjs'
import CurrencyFormat from 'react-currency-format'
import ExportTemplate from './Widget/ExportTemplate'

const Expiring = ({branches}) => {
    const dispatch = useDispatch()

    // const [loading, setIsLoading] = useState(false)

    const [withdrawalBranch, setWithdrawalBranch] = useState({label: 'All location', value: null})


    const expiredItems = useSelector(state => state.inventory)?.expiredItems
    const loading = useSelector(state => state.inventory)?.loadingExpiredItems

    const getUsage = (id=null) => {
        dispatch(getInventoryExpiredItemsThunk(id))
        // .then(_ => setIsLoading(false))
    }
    useEffect(()=> {
        // setIsLoading(true)
        getUsage(withdrawalBranch.value)
    },[withdrawalBranch])
    

   
  return (
    <div>

        <DivShadow className='max-h-[70vh]'>

            <ExportTemplate 
                title='Items Expiring Soon'
                dropDownData={branches}
                dropDownValue={withdrawalBranch}
                onChangeDropDown={(val)=>setWithdrawalBranch(val)}

                exportData={expiredItems?.map(x => ({
                    'Name': x?.orders[0]?.reference?.name,
                    'Reference Number': x?.orders[0]?.referenceNumber,
                    'Location': x?.orders[0]?.branch?.name,
                    'Expiry Date': dayjs(x?.orders[0]?.expirationDate).format('MM/DD/YYYY'),
                    'Lot Number': x?.orders[0]?.lotNumber,
                    

                }))}
                fileName={'expired-items'}
            />


            <div className='rounded-md border-1 dark:border-333 mt-6 min-h-[50vh] max-h-full mb-3 overflow-y-auto text-[14px]'>
            <table className="w-full border-collapse">
                <thead>
                    <tr className="border-b border-[#EAEAEA] bg-[#F1FBF2]">
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Name
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Ref No.
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Location
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Expiry Date
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Lot No.
                        </th>
                    </tr>
                </thead>

                {!loading ? <tbody className="bg-white">
                    {expiredItems?.length !== 0 ?
                        expiredItems.map(val => (
                            <tr className="h-[52px] border-b border-[#EAEAEA] hover:bg-gray-50 transition-colors">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    {val?.orders[0]?.reference?.name}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    {val?.orders[0]?.referenceNumber}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    <div className='flex items-center gap-2'>
                                        <div className='w-2 h-2 rounded-full' style={{background: val?.orders[0]?.branch?.hexcode}}/>
                                        <span>{val?.orders[0]?.branch?.name}</span>
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                                    {dayjs(val?.orders[0]?.expirationDate).format('MM/DD/YYYY')}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                                    {val?.orders[0]?.lotNumber}
                                </td>
                            </tr>
                        ))
                        : <tr>
                            <td colSpan={5} className="text-center py-4 text-[#7A7A7A]">Nothing to see here</td>
                        </tr>
                    }
                </tbody> : 
                <tbody>
                    <tr>
                        <td colSpan={5} className="text-center py-4">
                            <CircularProgress />
                        </td>
                    </tr>
                </tbody>}
            </table>

            </div>
        </DivShadow>
    </div>
  )
}

export default Expiring