import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Stack, Typography, CircularProgress } from '@mui/material';
import { font } from '../../../Widgets/Styles/font';
import FormModal from '../../../Widgets/Animation/FormModal';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { addInventoryMeasurementUnitThunk, getInventoryMeasurementUnitThunk } from '../../../../Store/Inventory';
import { IoClose } from 'react-icons/io5';

import {
  Text,
  Field,
  TextAndField,
  FillButton,
  OutlinedButton,
} from '../../../Widgets/FormUtils/FormUtils';


export const NewUnitModal = ({ open, handleClose, callback }) => {
 
  const dispatch = useDispatch()

  const loading = useSelector(state => state.inventory)?. loadingAddUnit

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      symbol: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please input job title'),
      symbol: Yup.string().required('Please input job symbol'),
      description: Yup.string()
        .min(5, 'Job description is too short')
        .required('Enter Job description'),
    }),
   
    onSubmit: (value) => {
     dispatch(addInventoryMeasurementUnitThunk(value))
     .then(val => {
      console.log(val.payload)
      if([201,200].includes(val.payload?.status)){
        toast.success("Success")
        dispatch(getInventoryMeasurementUnitThunk())
        handleClose()
      }
      else {
        toast.error(val?.payload?.data?.message)
      }
     })
    },
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
    overflowY:'auto',
    overflowX:'hidden'
  };

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack 
        className='dark:bg-138' 
        direction="column" 
        alignItems="center" 
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <div className="bg-white rounded-lg p-6 w-[500px]">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-lg font-medium">Add new measurement</h2>
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
              <IoClose size={24} />
            </button>
          </div>

          <div className="space-y-4">
            <div>
              <label className="block text-sm mb-2">Name</label>
              <input
                type="text"
                placeholder="Enter name"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#00A862] focus:border-[#00A862]"
                value={formik.values.name}
                onChange={formik.handleChange}
                name="name"
              />
            </div>

            <div>
              <label className="block text-sm mb-2">Symbol</label>
              <input
                type="text"
                placeholder="Enter symbol"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#00A862] focus:border-[#00A862]"
                value={formik.values.symbol}
                onChange={formik.handleChange}
                name="symbol"
              />
            </div>

            <div>
              <label className="block text-sm mb-2">Description</label>
              <textarea
                placeholder="Enter description"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#00A862] focus:border-[#00A862] resize-none"
                value={formik.values.description}
                onChange={formik.handleChange}
                name="description"
                rows={3}
              />
            </div>
          </div>

          <div className="mt-8">
            {!loading ? (
              <button
                onClick={formik.handleSubmit}
                className="w-full px-4 py-2 bg-[#00A862] text-white rounded-md hover:bg-[#008F53] transition-colors"
              >
                Add
              </button>
            ) : (
              <CircularProgress size={24} />
            )}
          </div>
        </div>
      </Stack>
    </FormModal>
  );
};
