import React,{useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { DivShadow } from './DataTable'
import {getInventoryLogsThunk} from '../../../Store/Inventory'
import ExportTemplate from './Widget/ExportTemplate'
import dayjs from 'dayjs'

const InventoryLog = ({branches}) => {
    const dispatch = useDispatch()


    const [isLoading, setIsLoading] = useState(false)

    const [withdrawalBranch, setWithdrawalBranch] = useState({label: 'All location', value: null})



    const inventoryData = useSelector(state => state.inventory)?.logs

   

    const getInventoryLog = (id=null) => {
        dispatch(getInventoryLogsThunk(id))
        .then(_ => setIsLoading(false))
    }
    useEffect(()=> {
        setIsLoading(true)
        getInventoryLog(withdrawalBranch.value)
    },[withdrawalBranch])
    

  
  return (
    <div>

        <DivShadow className='max-h-[70vh]'>

            <ExportTemplate 
                title='Inventory Logs'
                dropDownData={branches}
                dropDownValue={withdrawalBranch}
                onChangeDropDown={(val)=>setWithdrawalBranch(val)}

                exportData={inventoryData.map(x => ({
                    'Date': dayjs(x?.timestamp).format('MM/DD/YYYY'),
                    'Time': dayjs(x?.timestamp).format('h:mmA'),
                    'User': `${x?.user?.firstName} ${x?.user?.lastName}`,
                    'Details': x?.details,
                    'Location': x?.branch?.name,
                    'Action': x?.action,

                }))}
                fileName={'logs'}
            />

            <div className='rounded-md border-1 dark:border-333 dark:text-white mt-6 min-h-[50vh] max-h-full mb-3 overflow-y-auto text-[14px]'>
            <table className="w-full border-collapse">
                <thead>
                    <tr className="border-b border-[#EAEAEA] bg-[#F1FBF2]">
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2] w-[9%]">
                            Date
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2] w-[5%]">
                            Time
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2] w-[17%]">
                            User
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2] w-[34%]">
                            Details
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2] w-[17%]">
                            Location
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2] w-[16%]">
                            Action
                        </th>
                    </tr>
                </thead>

                {!isLoading ? <tbody className="bg-white">
                    {inventoryData?.length !== 0 ?
                        inventoryData.map(x => (
                            <tr className="h-[52px] border-b border-[#EAEAEA] hover:bg-gray-50 transition-colors">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    {dayjs(x?.timestamp).format('MM/DD/YYYY')}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    {dayjs(x?.timestamp).format('h:mmA')}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    {x?.user?.firstName} {x?.user?.lastName}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    {x?.details}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    <div className='flex items-center gap-2'>
                                        <div className='w-2 h-2 rounded-full' style={{background: x?.branch?.hexcode}}/>
                                        <span>{x?.branch?.name}</span>
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                                    {x?.action}
                                </td>
                            </tr>
                        ))
                        : <tr>
                            <td colSpan={6} className="text-center py-4 text-[#7A7A7A]">Nothing to see here</td>
                        </tr>
                    }
                </tbody> : 
                <tbody>
                    <tr>
                        <td colSpan={6} className="text-center py-4">
                            <CircularProgress />
                        </td>
                    </tr>
                </tbody>}
            </table>

            </div>
        </DivShadow>
    </div>
  )
}

export default InventoryLog