import {useState, useEffect, useCallback} from 'react'

import SearchInput from '../../../Widgets/SearchInput'
import { Link, useNavigate, useLocation} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import AppLayout from '../../../Widgets/AppLayout';
import { CircularProgress } from '@mui/material';

import GlobalButton from '../../../Widgets/GlobalButton';
import { useSelector, useDispatch } from 'react-redux';
import { getInventoryMeasurementUnitThunk } from '../../../../Store/Inventory';
import { CustomPopper } from '../../../Widgets/CustomPopper';
import { ClickableItem } from '../../../Widgets/ClickableItem';
import { stockUpOrderRequest } from '../../../../Network/ServiceClass/InventoryService';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { statusColor } from '../../../../utils/inventoryStatus';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';
import { inventoryPlaceBackOrder, approveOrderRequest } from '../../../../Network/ServiceClass/InventoryService';
import MainTrackingModal from './Tracking/MainTrackingModal';
import { getInventorySingleOrderRequestThunk } from '../../../../Store/Inventory';
import { TableWrapper, TableHeader, Td, Tr } from '../../../Widgets/Table/TableWrapper';
import FormModal, { OtherExport } from '../../../Widgets/Animation/FormModal';
import { IoClose } from 'react-icons/io5';

const OrderRequestItem = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const { itemId } = useParams();
    const dispatch = useDispatch()
 
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isStocking, setIsStocking] = useState(false)

    const [searchValue, setSearchValue] = useState('')
    const measurementUnitsList = useSelector(state => state.inventory)?.measurementUnits

    const [toggleMenu, setToggleMenu] = useState(null)
    const [menuIndex, setMenuIndex] = useState(null)
    const [isLoadingAction, setIsLoadingAction] = useState(false)
    const [isLoadingApprove, setIsLoadingApprove] = useState(false)

    const [openTrackingModal, setOpenTrackingModal] = useState(false)
    const [trackingData, setTrackingData] = useState(null)

    const [showBackOrderModal, setShowBackOrderModal] = useState(false)
    const [backOrderData, setBackOrderData] = useState(null)
    const [backOrderNote, setBackOrderNote] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                dispatch(getInventoryMeasurementUnitThunk());
                const response = await dispatch(getInventorySingleOrderRequestThunk(itemId));
                console.log('API Response:', response.payload);
                if (response.payload.status === 200) {
                    const mappedData = response.payload.data.map(x => ({
                        ...x,
                        received: 0,
                        expirationDate: dayjs(x?.expirationDate).format('YYYY-MM-DD')
                    }));
                    console.log('Mapped Data:', mappedData);
                    setData(mappedData);
                }
            } catch (error) {
                console.error('Error fetching order details:', error);
                toast.error('Failed to fetch order details');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [itemId, dispatch]);

    const reLoad = () => {
        dispatch(getInventorySingleOrderRequestThunk(itemId))
        .then(val => {
            setData(val.payload?.data.map(x => ({...x, received:0, expirationDate: dayjs().format('YYYY-MM-DD')})))
        })
       
    }

    const placeInBackOrder = (data, status) => {
        if (status === 'backorder') {
            setBackOrderData({
                ...data,
                backOrderAmount: data.packagesToOrder - (data.received || 0)
            });
            setShowBackOrderModal(true);
        } else {
            // For removing from backorder
            setIsLoadingAction(true);
            inventoryPlaceBackOrder({
                orderId: data?.id,
                referenceNumber: data?.referenceNumber,
                amount: data.packagesToOrder - (data.received || 0),
                note: backOrderNote
            })
            .then(val => {
                if(val?.status === 200) {
                    toast.success('Success');
                    setToggleMenu(null);
                    reLoad();
                }
                else toast.error(val?.data?.message);
                setIsLoadingAction(false);
            }).catch(err => setIsLoadingAction(false));
        }
    };

    const handleBackOrderSubmit = (data, status, note = '') => {
        setIsLoadingAction(true);
        inventoryPlaceBackOrder({
            orderId: data?.id,
            referenceNumber: data?.referenceNumber,
            status,
            backOrderAmount: data.backOrderAmount,
            backOrderNote: note
        })
        .then(val => {
            if(val?.status === 200) {
                toast.success('Success');
                setToggleMenu(null);
                setShowBackOrderModal(false);
                setBackOrderNote('');
                reLoad();
            }
            else toast.error(val?.data?.message);
            setIsLoadingAction(false);
        }).catch(err => setIsLoadingAction(false));
    };

    const handleSearch = useCallback(() => {
        let datas = data
        if(searchValue.length !== 0){
          const pattern = new RegExp(`\D*${searchValue}\D*`,'i')
                datas = data.filter((val) => (
                    val.status?.toLowerCase().match(pattern) ||
                    val.packageCost?.toString().match(pattern) ))
        }
        return datas
    
    },[searchValue, data])

    const handleStockItems = () => {
        setIsStocking(true)
        const datas = {
            branchId: data[0]?.branch?.id || null,
            orderIdentifier: itemId,
            itemsReceived: data?.map(item => {
                // Create base item data
                const itemData = {
                    referenceNumber: item?.referenceNumber,
                    numberReceived: item?.received || 0,
                    measurementUnitId: item?.measurementUnitId || null,
                    lotNumber: item?.lotNumber,
                };
                
                // Only include expirationDate if the item expires
                if (item?.reference?.expires && item?.expirationDate) {
                    itemData.expirationDate = item.expirationDate;
                }
                
                return itemData;
            })
        }

        stockUpOrderRequest(datas)
        .then(val => {
            if([200,201].includes(val?.status)) {
                toast.success('Items Stocked')
                navigate('/stock-order-request')
            }else {
                toast.error(val?.data?.message)
            }

            setIsStocking(false)
        })
    }

    const handleApprove = () => {
        setIsLoadingApprove(true)
        approveOrderRequest({id: itemId, status:'ordered'})
        .then(val => {
          if(val?.status === 200){
            toast.success('Successfull')
            reLoad()

          } 
          else toast.error(val?.data?.message)
        setIsLoadingApprove(false)
        })
    
        
    
    }
    


  return (
    <section>
        <AppLayout
            showAppBar={false}
            hideLiveUsers={true}
           custom="inventory"
           subCustom="order-request"
           title={location?.state?.title ?? 'Order Request'}
           customTitle="Inventory"
           subtitle=""
        >
            
            <div className="flex h-[30px] items-center gap-3">
                {/* <IoIosArrowBack onClick={()=>navigate(-1)} size={25} className="cursor-pointer" /> */}
                <p className="font-medium text-base text-5F3 my-6">
                    <span className='cursor-pointer'
                    onClick={()=> navigate(-1)}
                    >
                        {location?.state?.title?.toUpperCase()} /
                    </span>
                    <span className='font-bold'> {itemId}</span>
                </p>
            </div>

            <div className='flex flex-col gap-2 mt-4'>
                <SearchInput 
                    className={'w-[300px] border-gray-200 h-10'}
                    inputStyle={'placeholder:text-gray-400'}
                    iconStyle='text-gray-400'
                    placeholder='Search for items...' 
                    onChange={(e)=>{setSearchValue(e.target.value)}}
                />

                <div className='flex justify-between'>
                    {!isLoading ? <p className='text-[18px] dark:text-white'>Location - {data[0]?.branch?.name}</p>: <div></div> }

                    <div className='flex gap-3'>
                        {!isLoadingApprove ? <GlobalButton 
                            disabled={isLoadingApprove}
                            onClick = {handleApprove}
                            title="Approve Order" 
                            className="rounded-md text-white font-semibold text-[16px] "/>

                            :
                            <div className='mr-5 text-center w-[130px]'><CircularProgress/></div>
                        
                        }

                        {!isStocking ? <GlobalButton 
                            disabled={isLoading}
                            onClick = {handleStockItems}
                            title="Stock Items" 
                            className="rounded-md text-white font-semibold text-[16px] "/>

                            :
                            <div className='mr-5 text-center w-[130px]'><CircularProgress/></div>
                        
                        }

                    </div>
                </div>



            </div>

            <TableWrapper className='mt-6 !max-h-[70vh] !mb-12 w-full'>
                <table className='w-full'>
                    <TableHeader className='z-[1000] w-full'>
                        <Td className='py-4 w-[11%]'>Reference Number</Td>
                        <Td className='w-[14%]'>Name</Td>
                        <Td className='w-[14%] '>Manufacturer</Td>
                        <Td className='w-[14%]'>Expiration Date</Td>
                        <Td className='w-[11%]'>Lot Number</Td>
                        <Td className='w-[8%]'>#Expected</Td>
                        <Td className='w-[11%]'>#Received</Td>
                        <Td className='w-[8%]'>Unit</Td>
                        <Td className='w-[8%] !border-none'>Status</Td>

                    </TableHeader>

                    {!isLoading ? <tbody>
                        {handleSearch()?.length !== 0 ?
                            handleSearch()?.map((val, index) => (
                                <Tr key={index} className='hover:dark:bg-292 dark:text-white font-bold'>
                                        <Td className='py-4'>
                                        {val?.referenceNumber}
                                        </Td>

                                        <Td >{val?.reference?.name}</Td>
                                        <Td >{val?.reference?.manAndSupp[0]?.manufacturer?.name}</Td>

                                        <Td >
                                            {'l' === '' ?<div>d</div>
                                            :
                                            <input
                                                onChange={(e) => {
                                                    const v =  data.map(x => {
                                                        if(x?.id === val?.id){
                                                            x.expirationDate = e.target.value
                                                        }
                                                        return x
                                                    })

                                                    setData(v)
                                                }}
                                                className={`focus:outline-none border-1 rounded-md px-1 py-2 hover:border-[black] hover:dark:border-969 dark:border-333 dark:bg-138 dark:text-white cursor-pointer ${!val?.reference?.expires ? 'opacity-50 cursor-not-allowed bg-gray-100 dark:bg-gray-700' : ''}`}
                                                type="date" 
                                                value={val?.expirationDate}
                                                disabled={!val?.reference?.expires}
                                                title={!val?.reference?.expires ? "This item does not expire" : ""}
                                            />}
                                        </Td>
                                        <Td >
                                            <input 
                                                onChange={(e)=>{
                                                    const v =  data.map(x => {
                                                        if(x?.id === val?.id){
                                                            x.lotNumber = e.target.value
                                                        }
                                                        return x
                                                    })

                                                    setData(v)
                                                }}
                                                className='border-1 py-2 px-1 rounded-md focus:outline-0 hover:border-[black] hover:dark:border-969 dark:border-333 dark:bg-138 dark:text-white w-[150px]'
                                                type="text"  
                                                value={val?.lotNumber}
                                            />
                                        </Td>

                                        <Td >{val?.packagesToOrder}</Td>


                                        <Td >
                                            <div className='flex text-[18px]'>
                                                <p onClick={()=> {
                                                    const v =  data.map(x => {
                                                            if(x?.id === val?.id){
                                                                if ( x.received !== 0) x.received --
                                                            }
                                                            return x
                                                        })

                                                        setData(v)
                                                    }
                                                }

                                                    className='py-[5px] px-3 cursor-pointer rounded-l-md bg-5F3 text-white'>-</p>
                                                <p className='w-[70px] py-[4px] text-center bg-5F5 dark:bg-138 dark:text-white'>{val?.received}</p>
                                                <p 
                                                    onClick={()=> {
                                                    const v =  data.map(x => {
                                                            if(x?.id === val?.id){
                                                                x.received ++
                                                            }
                                                            return x
                                                        })

                                                        setData(v)
                                                    }}
                                                    className='py-[5px] px-3 cursor-pointer rounded-r-md bg-5F3 text-white'>+</p>
                                            </div>
                                        </Td>
                                        
                                        <Td>
                                          
                                            {val?.measurementUnitId 
                                                ? measurementUnitsList.filter(x => x.id === val?.measurementUnitId)[0]?.name 
                                                : 'choose'
                                            }
                                        </Td>

                                        <Td className={'!border-none'}>
                                            <p 
                                                onClick={(e)=> {
                                                    e.stopPropagation()
                                                    !isLoadingAction && setToggleMenu(toggleMenu ? null : e.currentTarget)
                                                    !isLoadingAction && setMenuIndex(val?.id)
                                                }}
                                                className={`rounded-full cursor-pointer px-2 text-center ${statusColor[val?.status]?.bg} ${statusColor[val?.status]?.color}`}>
                                                    {capitalizeFirstLetter(val?.status)}
                                            </p>
                                            {menuIndex === val?.id &&
                                                <CustomPopper 
                                                    openPopper={toggleMenu} 
                                                    handleClose={()=>{
                                                        !isLoadingAction && setToggleMenu(null)
                                                    }} className='z-[1500]' placement='bottom'>
                                                    <div className='h-[100px] min-w-[150px] mt-1 py-3 font-bold bg-white shadow-shift-shadow-small'>

                                                        {!isLoadingAction ? <div className='flex flex-col gap-2 text-[#738583] text-[12px]'>
                                                            
                                                            {val?.status !== 'backorder' ? <ClickableItem 
                                                                handleClick={()=>placeInBackOrder(val, 'backorder')}
                                                                className={'py-1 text-[black]'} 
                                                                text={'Place In Backorder'} 
                                                            /> : 

                                                            <ClickableItem 
                                                                handleClick={()=>placeInBackOrder(val, 'ordered')}
                                                                className={'py-1 text-[black]'} 
                                                                text={'Remove In Backorder'} 
                                                            />
                                                            }

                                                            <ClickableItem 
                                                                className={'py-1 text-[black]'} 
                                                                text={'Track Order'} 
                                                                handleClick={()=> {
                                                                    setOpenTrackingModal(true)
                                                                    setToggleMenu(null)
                                                                    setTrackingData(val)
                                                                }}
                                                            />
                                                        </div>
                                                        :
                                                        <div className="flex h-full items-center justify-center"><CircularProgress/></div>
                                                        }
                                                    
                                                    </div>          
                                                </CustomPopper>
                                            }
                                        </Td>
                                </Tr>

                            ))

                        :<tr>
                            <td colSpan={9} className='text-center pt-5 text-A5A'>Nothing to see here</td>
                        </tr>
                        }
                    </tbody>
                    : <tbody>
                    <tr>
                        <td colSpan={9} className='text-center pt-10 border-1 dark:border-333 border-white'><CircularProgress /></td>
                    </tr>
                </tbody>}
                </table>

            </TableWrapper>
          
        </AppLayout>

        {openTrackingModal && <MainTrackingModal
         openModal={openTrackingModal}
         data={trackingData}
         callBack={reLoad}
         handleCloseModal={()=> {
            setOpenTrackingModal(false)
            setTrackingData(null)
        }}
        />}
       
        {showBackOrderModal && (
            <FormModal open={showBackOrderModal} handleClose={() => setShowBackOrderModal(false)}>
                <div className="bg-white rounded-lg shadow-lg w-[500px] mx-auto mt-[20vh]">
                    <div className="p-4 border-b">
                        <h2 className="text-lg font-medium">Place in Back Order</h2>
                    </div>
                    
                    <div className="p-6 space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Back Order Amount
                            </label>
                            <input
                                type="number"
                                value={backOrderData?.packagesToOrder - backOrderData?.received || 0}
                                readOnly
                                className="w-full p-2 border rounded-md bg-gray-50"
                            />
                        </div>
                        
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Back Order Notes
                            </label>
                            <textarea
                                value={backOrderNote}
                                onChange={(e) => setBackOrderNote(e.target.value)}
                                placeholder="Enter notes..."
                                className="w-full p-2 border rounded-md h-24 resize-none"
                            />
                        </div>
                    </div>
                    
                    <div className="p-4 border-t flex justify-end gap-3">
                        <button
                            onClick={() => setShowBackOrderModal(false)}
                            className="px-4 py-2 border rounded-md hover:bg-gray-50"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => {
                                setIsLoadingAction(true);
                                inventoryPlaceBackOrder({
                                    orderId: backOrderData?.id,
                                    referenceNumber: backOrderData?.referenceNumber,
                                    amount: backOrderData?.packagesToOrder - backOrderData?.received,
                                    note: backOrderNote
                                })
                                .then(val => {
                                    if(val?.status === 200) {
                                        toast.success('Success');
                                        setToggleMenu(null);
                                        setShowBackOrderModal(false);
                                        setBackOrderNote('');
                                        reLoad();
                                    }
                                    else toast.error(val?.data?.message);
                                    setIsLoadingAction(false);
                                }).catch(err => setIsLoadingAction(false));
                            }}
                            disabled={isLoadingAction}
                            className="px-4 py-2 bg-[#00A862] text-white rounded-md hover:bg-[#008F53] disabled:bg-gray-400"
                        >
                            {isLoadingAction ? 'Processing...' : 'Place in Back Order'}
                        </button>
                    </div>
                </div>
            </FormModal>
        )}
    </section>
  )
}

export default OrderRequestItem