import { BsPlus } from "react-icons/bs"
import CustomDropdown from "../../Widgets/CustomDropdown"
import GlobalButton from "../../Widgets/GlobalButton"
import { useNavigate } from "react-router"
import AppLayout from "../../Widgets/AppLayout"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { getBranchesAsync } from "../../../Store/Branches"
import { IoClose } from 'react-icons/io5';
import { toast } from 'react-toastify'
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel'
import { transferItemListTutorial, transferItemTutorial } from '../mock';
import TransferTable from "./TransferTable";
import TransferButton from "./TransferButton";
import SearchInput from "../../Widgets/SearchInput";
import TransferModal from './TransferModal';
import { BiTransfer } from 'react-icons/bi';
import { ReactComponent as TransferIcon } from '../../../Assets/transfer.svg';


export const TransferItems = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [transfromBranch, selectFromBranch] = useState({ label: "All Branches", value : ""})
  const [selectItems, selectInventoryItem] = useState([])
  const [toBranch, selectToBranch] = useState({ label: "All Branches", value : ""})
  const [searchQuery, setSearchQuery] = useState("")
  const [selectedItems, setSelectedItems] = useState([])
  const [activeFilter, setActiveFilter] = useState('All')
  const branches = useSelector(state => state.branches.branches?.data || [])
  const inventoryGroups = useSelector(state => state?.inventory?.groups || [])
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Format branches for dropdown
  const branchOptions = branches.map(branch => ({
    label: branch.name,
    value: branch.id
  }));

  useEffect(() => {
    dispatch(getBranchesAsync())
  }, [dispatch])

  useEffect(() => {
    const transferItem = localStorage.getItem("transferItem")
    if(transferItem !== undefined){
      const data = JSON.parse(transferItem);
      const withdrawalBranch = branches.find((x) => x.id === data?.withdrawLocationId)
      selectFromBranch({ label: withdrawalBranch?.name, value: withdrawalBranch?.id})
      selectInventoryItem(data?.transferData)
    } else {
      selectFromBranch({ label: "All Branches", value : ""})
    }
  }, [branches])

  const handleFilterClick = (filter) => {
    setActiveFilter(filter)
  }

  const handleWithdrawClick = () => {
    if (selectedItems.length === 0) {
      toast.error('Please select items to withdraw')
      return
    }
    setIsModalOpen(true);
  }

  const handleSaveLocationInfo = () => {
    const data = JSON.parse(localStorage.getItem("transferItem"))
    const user = JSON.parse(localStorage.getItem("user"));
    const activeCompany = JSON.parse(localStorage.getItem("activeCompany"))
    const [member] = activeCompany?.memberships.filter((x) => x.userId === user?.id);
    const transferData = {
      ...data,
      transferLocationId: toBranch.value,
      teamMembershipId: member.id,
      transferData: selectItems
    }
    localStorage.setItem("transferItem", JSON.stringify(transferData))
    navigate("/transfer-items-confirm")
  }

  return (
      <AppLayout
        showAppBar={false}
        hideLiveUsers={true}
        custom="inventory"
        subCustom="transfer-items"
        title=""
        customTitle="Inventory"
        subtitle=""
      >
        <div className=" absolute top-[15px] left-[15px] w-[calc(100%-15px)] h-[95vh] overflow-y-auto border border-[#EAEAEA] rounded-[12px] flex flex-col bg-white">
          <div className=" flex flex-col w-full h-[30px] pl-5 pt-1">
            <div className="flex flex-row items-center gap-2 w-[117px] h-[18px] top-15 left-25">
              <TransferIcon />
              <p className="font-[Plus Jakarta Sans] font-medium text-[12px] text-[#7a7a7a] leading-[15px]">
                Transfer Items
              </p>
            </div>
            <div className=" flex flex-col h-screen w-[80vw] gap-[20px] relative mt-5">
              <div className=" mt-[15px] h-[66px] w-[350px]">
                <h1 className="font-[Plus Jakarta Sans] font-semibold text-[30px]">
                  Transfer Items
                </h1>
                <p className="font-[Plus Jakarta Sans] font-normal text-[14px] leading-[22px] text-[#606060]">
                  Move items seamlessly from one location to another
                </p>
              </div>
              <div className=" flex flex-row items-center w-full gap-[20px] relative z-20">
                <SearchInput 
                  placeholder="I'm Searching for" 
                  className="w-[77vw] h-[40px] !rounded-[24px] border border-[#E8E8E8] placeholder-[#C3C3C3]-400 placeholder:font-[Plus Jakarta Sans] placeholder:text-sm"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
               {/*} <CustomDropdown 
                  data={allBranchOptions}
                  value={fromBranch}
                  onChangeOption={(option) => selectFromBranch(option)}
                  className="w-[10vw] h-[40px] border border-[#E8E8E8] !rounded-[24px] flex items-center px-4 text-[#848484] font-[Plus Jakarta Sans] text-sm"
                />*/}
              </div>
              <div className=" flex flex-row w-[35vw] h-[30px] gap-[10px]">
                    <TransferButton
                        title="All"
                        onClick={() => handleFilterClick('All')}
                        className={`min-w-[48px] w-[48px] max-w-[80px] rounded-[21px] border border-[#F0F0F0] 
                                  font-['Plus_Jakarta_Sans'] font-medium text-[12px] 
                                  leading-[15.12px] tracking-[-0.01em] 
                                  ${activeFilter === 'All' ? 'bg-[#9c9c9c] text-white' : ''}
                                  hover:bg-[#9c9c9c] hover:text-white transition-colors`}
                      />

                      {inventoryGroups.map((group) => (
                        <TransferButton
                          key={group.id}
                          title={group.name}
                          onClick={() => handleFilterClick(group.name)}
                          className={`min-w-[70px] rounded-[21px] border border-[#F0F0F0] 
                                    font-['Plus_Jakarta_Sans'] font-medium text-[12px] 
                                    leading-[15.12px] tracking-[-0.01em] 
                                    ${activeFilter === group.name ? 'bg-[#9c9c9c] text-white' : ''}
                                    hover:bg-[#9c9c9c] hover:text-white transition-colors`}
                        />
                      ))}
               </div>
               <div className=" w-[78vw] flex-1 min-h-[65vh] border border-[#EAEAEA] overflow-y-auto">
                 <TransferTable 
                   activeFilter={activeFilter} 
                   searchQuery={searchQuery}
                   selectedItems={selectedItems}
                   setSelectedItems={setSelectedItems}
                 />
               </div>
               <div className=" flex flex-row justify-end gap-[10px] w-[78vw] mt-4 mb-6 pr-4">
                <TransferButton
                  title="Cancel"
                  onClick={() => navigate(-1)}
                  className="min-w-[100px] h-[36px] rounded-[4px] border border-[#E8E8E8] 
                            font-['Plus_Jakarta_Sans'] font-medium text-[14px] text-[#848484] 
                            bg-white hover:bg-gray-50 transition-colors"
                />
                <TransferButton
                  title="Transfer Items"
                  onClick={handleWithdrawClick}
                  className="min-w-[120px] h-[36px] rounded-[4px] 
                            font-['Plus_Jakarta_Sans'] font-medium text-[14px] text-white 
                            bg-[#00A862] hover:bg-[#008F53] transition-colors"
                />
               </div>
            </div>
          </div>
        </div>

        <TransferModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          selectedItems={selectedItems}
          branches={branchOptions}
        />

      </AppLayout>
      
  )
}