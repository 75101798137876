import React,{useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { DivShadow } from './DataTable'
import { getInventoryDataWithdrawalsThunk } from '../../../Store/Inventory'
import { Storage } from '../../../Network/StorageClass/StorageClass'
import dayjs from 'dayjs'
import CurrencyFormat from 'react-currency-format'
import ExportTemplate from './Widget/ExportTemplate'

const storage = new Storage()
const Withdrawals = ({statusColor, branches}) => {
    const dispatch = useDispatch()

    const isAllow = () => {
        if ([1,2].includes(storage.getActiveCompany()?.memberships[0]?.privilege)) return true
        else return false
    }

    const teamMembershipId = storage.getActiveCompany()?.memberships[0]?.id

    const [toggleMenu, setToggleMenu] = useState(null)
    const [isLoadingAction, setIsLoadingAction] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [withdrawalBranch, setWithdrawalBranch] = useState({label: 'All location', value: null})
    const [menuIndex, setMenuIndex] = useState(null)


    const dataWithdrawals = useSelector(state => state.inventory)?.dataWithdrawals


    const getWithdrawals = (id=null) => {
        dispatch(getInventoryDataWithdrawalsThunk(id))
        .then(_ => setIsLoading(false))
    }
    useEffect(()=> {
        setIsLoading(true)
        getWithdrawals(withdrawalBranch.value)
    },[withdrawalBranch])
    

    // const handleApprove = (value) => {
    //     setIsLoadingAction(true)

    //     const data = {
    //         referenceNumber: value?.referenceNumber,
    //         locationId: value?.transferLocation?.id,
    //         status: "approved",
    //         trackingStatus: "shipped",
    //         teamMemberId: teamMembershipId
    //     }

    //     inventoryUpdateTransferItemsStatus(data)
    //     .then(val => {
    //         if (val?.status === 200){
    //             toast.success('Approved Successfully')
    //             setToggleMenu(null)
    //             getPendingTransfer(withdrawalBranch.value)
    //         }else toast.error(val?.data?.message)
    //         setIsLoadingAction(false)
    //     })
    // }
  return (
    <div>

        <DivShadow className='max-h-[70vh]'>

            <ExportTemplate 
                title='Withdrawn Items'
                dropDownData={branches}
                dropDownValue={withdrawalBranch}
                onChangeDropDown={(val)=>setWithdrawalBranch(val)}

                exportData={dataWithdrawals?.map(x => ({
                    'Date': dayjs(x?.createdOn).format('MM/DD/YYYY'),
                    'Time': dayjs(x?.createdOn).format('hh:mmA'),
                    'Reference Number': x?.inventoryItems?.referenceNumber,
                    'Name': x?.inventoryItems?.name,
                    'Location': x?.withdrawLocation?.name,
                    'Patient ID': x?.patientId,
                    'Price': `$${x?.totalPrice/100}`,

                }))}
                fileName={'withdrawn-items'}
            />


            <div className='rounded-md border-1 dark:border-333 mt-6 min-h-[40vh] max-h-[40vh] mb-3 overflow-y-auto text-[14px]'>
                <table className="w-full border-collapse">
                    <thead>
                        <tr className="border-b border-[#EAEAEA] bg-[#F1FBF2]">
                            <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                                Date
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                                Time
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                                Ref No.
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                                Name
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                                Location
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                                PatientID
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                                Price
                            </th>
                        </tr>
                    </thead>

                    {!isLoading ? <tbody className="bg-white">
                        {dataWithdrawals?.length !== 0 ?
                            dataWithdrawals.map(val => (
                                <tr className="h-[52px] border-b border-[#EAEAEA] hover:bg-gray-50 transition-colors">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                        {dayjs(val?.createdOn).format('MM/DD/YYYY')}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                        {dayjs(val?.createdOn).format('hh:mmA')}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                        {val?.inventoryItems?.referenceNumber}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                        {val?.inventoryItems?.name}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                        <div className='flex items-center gap-2'>
                                            <div className='w-2 h-2 rounded-full' style={{background: val?.withdrawLocation?.hexcode}}/>
                                            <span>{val?.withdrawLocation?.name}</span>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                        {val?.patientId}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                        <CurrencyFormat
                                            className='text-[#404040]'
                                            value={val?.totalPrice/100}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            decimalScale={2}
                                        />
                                    </td>
                                </tr>
                            ))
                            : <tr>
                                <td colSpan={7} className="text-center py-4 text-[#7A7A7A]">Nothing to see here</td>
                            </tr>
                        }
                    </tbody> : 
                    <tbody>
                        <tr>
                            <td colSpan={7} className="text-center py-4">
                                <CircularProgress />
                            </td>
                        </tr>
                    </tbody>}
                </table>
            </div>
        </DivShadow>
    </div>
  )
}

export default Withdrawals