/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useNavigate } from 'react-router-dom';
import { MoreHoriz } from '@material-ui/icons';
import { CreateGroupModal } from './componennts/Modals/CreateGroup';
import { useEffect, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { createInventoryGroupsThunk, getInventoryGroupsThunk } from '../../../../Store/Inventory';
import moment from 'moment'
import { toast } from 'react-toastify';
import AppLayout from '../../../Widgets/AppLayout';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import _ from 'lodash'
import { TableWrapper, Tr, Td, TableHeader } from '../../../Widgets/Table/TableWrapper';
import SearchInput from '../../../Widgets/SearchInput';
import GroupTable from './componennts/Modals/GroupTable';

export const InventoryItemGroup = () => {
  const [order, setOrder] = useState('asc')
  const [searchText, searchInventoryGroup] = useState("")
  const dispatch = useDispatch()
  const [open, setModal] = useState(false);
  const navigate = useNavigate()
  const inventoryGroups = useSelector((state) => state.inventory)?.groups
  const isLoading = useSelector((state) => state.inventory)?.loading

  useEffect(()=>{
    dispatch(getInventoryGroupsThunk())
  },[])

  const handleOpenModal = () => {
    setModal(true)
  }

  const handleCloseModal = () => {
    setModal(false)
  }

  const handleCreateGroup = (data) => {
    dispatch(createInventoryGroupsThunk({...data, slug: data?.name?.toLowerCase()?.split(" ").join("-")})).then((res) => {
      if(res.meta?.requestStatus === "fulfilled"){
        toast.success("Inventory group was successfully created")
        handleCloseModal()
        return dispatch(getInventoryGroupsThunk())
      } else {
        toast.error(res?.payload?.message);
      }
    })
  }

  const handleToggleOrder = () => {
    if(order === "asc"){
      setOrder("desc")
    } else {
      setOrder("asc")
    }
  }

  const handleDataRendering = () => {
    
    let data =  []
    if(order === "asc"){
      data = _.orderBy(inventoryGroups, (x) => x?.name?.toLowerCase(), 'asc')
    } else {
      data = _.sortBy(inventoryGroups, (x) => x?.name?.toLowerCase(),'desc').reverse()
    }
    
    return data
  }

  return (
    <section className="bg-[#F5F5F5] min-h-screen">
      {open && (<CreateGroupModal loading={isLoading} onSubmit={handleCreateGroup} handleClose={handleCloseModal} open={open} />)}
      <AppLayout
        showAppBar={false}
        hideLiveUsers={true}
        custom="inventory"
        subCustom="inventory-items"
        title=""
        customTitle=" "
        subtitle=""
      >
          <div className='flex flex-col absolute top-[15px] border-[#F1FBF2] rounded-[12px] w-full h-[95vh] bg-white'>
              <div className='first-heading items-start px-5 pt-4'>
                    <p className="font-[Plus Jakarta Sans] font-normal text-[14px] text-[#404040] mb-4">
                        Inventory Items
                    </p>
                    <h1 className="font-[Plus Jakarta Sans] font-semibold text-[30px] text-[#404040]">
                    Inventory Items
                  </h1>
              </div>
              <div className='flex flex-row justify-between items-center px-5 pt-4'>
                <div className="flex items-center gap-0">
                  <IoIosArrowBack onClick={()=>navigate(-1)} size={25} className="cursor-pointer dark:text-white" />
                  <p className="font-medium text-base text-black my-6">
                    <Link to="/inventory-items">Inventory Items</Link> 
                  </p>
                </div>

                <div className="flex items-center !pr-[90px]">
                  <button onClick={handleOpenModal} className="bg-[#EAF5EB] text-black px-5 py-[10px] rounded font-semibold text-base">
                    + Create group
                  </button>
                </div>
              </div>

              <div className='px-5 pt-2'>
                  <SearchInput 
                  placeholder='search inventory item'
                  onChange={(e) => {
                    searchInventoryGroup(e.target.value);
                  }}
                  className="w-[74vw] h-[40px] border !rounded-[24px] border-[#E8E8E8]"
                />
              </div>

              <div className="ml-5 mt-8">
                <GroupTable 
                  searchQuery={searchText}
                />
              </div>


          </div>

        
      </AppLayout>
    </section>
  );
};
