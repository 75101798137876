import React,{useState, useEffect} from 'react'
import './Styles/HomePageNavbar.css';
import {IoMdNotificationsOutline} from 'react-icons/io';
import {FiChevronDown} from 'react-icons/fi';
import { Storage } from '../../Network/StorageClass/StorageClass';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import logout from '../../Assets/Vector.png';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import { Badge, ClickAwayListener } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import LogoutIcon from '@mui/icons-material/Logout';
import Notification from './Notification';
import { AnimatePresence, motion } from 'framer-motion';
import {getNotifications} from '../../Network/ServiceClass/notification'
import { getGlobalSearchAsync, getNotificationAsync, updatePushNotificationStatus } from '../../Store/dashboard';
import { setLoggedIn } from '../../Store/socketSlice';
import { io } from 'socket.io-client';
import { BsSearch } from 'react-icons/bs';
import LocationIcon from '../../Assets/location_icon.png'
import SpeakerIcon from '../../Assets/speaker.png'
import moment from 'moment'
import { Loader } from './Loader';
import { companyRotationDataThunk } from '../../Store/Company';
import { getATeammemberAsync } from '../../Store/Branches';
import { toast } from 'react-toastify';
import { useJwt } from 'react-jwt'

const selectContainer = (data, conv,nav) => {
  switch(data[0]) {
    case 'location':
      return (<div className="mb-[36px] dark:bg-primary">
         <p className='p-2 text-D6D font-bold text-xs dark:text-white'>{conv[data[0]]}</p>
         {
          data[1]?.map((x) => (<div onClick={()=>nav("/branches")} className="flex cursor-pointer ml-14 mb-[27px] items-center gap-3">
            <img src={LocationIcon} alt="loc" />
            <div>
              <p className="font-bold text-base dark:text-white">{x?.name}</p>
              <p className=" font-semibold text-10 text-727 dark:text-white">{x?.address}</p>
            </div>
          </div>))
         }
      </div>)
    case 'jobRoles':
      return (<div className="mb-[36px] dark:bg-primary">
         <p className='p-2 text-D6D font-bold text-xs dark:text-white'>{conv[data[0]]}</p>
         {
          data[1]?.map((x) => (<div onClick={()=>nav("/jobs")} className="flex cursor-pointer ml-14 mb-[27px] items-center gap-3">
            <div className="w-6 h-6 rounded-full" style={{ backgroundColor: x?.hexcode || "#000000"}} />
            <div>
              <p className="font-bold text-base dark:text-white">{x?.title}</p>
              <p className=" font-semibold text-10 text-727 dark:text-white">{x?.desc}</p>
            </div>
          </div>))
         }
      </div>)
    case 'teamMembers':
      return (<div className="mb-[36px] dark:bg-primary">
         <p className='p-2 text-D6D font-bold text-xs dark:text-white'>{conv[data[0]]}</p>
         {
          data[1]?.map((x) => (<div onClick={()=>nav("/branches")} className="flex cursor-pointer ml-14 mb-[27px] items-center gap-3">
            <div className="w-6 h-6 rounded-full" style={{ backgroundColor: x?.jobRole?.hexcode || "#000000"}} />
            <div>
              <p className="font-bold text-base dark:text-white">{x?.user?.firstName} {x?.user?.lastName}</p>
              <p className=" font-semibold text-10 text-727 dark:text-white">{x?.jobRole?.title}</p>
            </div>
          </div>))
         }
      </div>)
    case 'announcements':
      return (<div className="mb-[36px] dark:bg-primary">
         <p className='p-2 text-D6D font-bold text-xs dark:text-white'>{conv[data[0]]}</p>
         {
          data[1]?.map((x) => (<div onClick={()=>nav("/announcement")} className="flex cursor-pointer ml-14 mb-[27px] items-center gap-3">
            <img src={SpeakerIcon} alt="icon" />
            <div>
              <p className="font-bold text-base dark:text-white">{x?.title}</p>
              <p className=" font-semibold text-10 text-878 dark:text-white">{moment(x.createdOn).format("MMM do yyyy")}-{x?.createdBy?.firstName} {x?.createdBy?.lastName}</p>
            </div>
          </div>))
         }
      </div>)
    default:
      <div></div>
  }
}


const AppBarTop = ({custom="", title ="", TitleIcon, withoutHeaderTitle=false, layoutClass = "", layoutProps={}}) => {
    const location = useLocation();
     const auth = localStorage.getItem("authtoken");
      const { decodedToken, isExpired } = useJwt(auth)
    const navigation = useNavigate();
    const dispatch = useDispatch()
    const [searchedWord, searchWord] = useState('')
    const [isSearchDropdownOpen, closeSearchDropdown] = useState(true)
    const notifications = useSelector(state => state.dashboard)?.notifications?.data || []
    const notNumber = useSelector(state => state.dashboard)?.notifications?.total || 0
    const activeCompany = useSelector(state => state.company.activeCompany)
    const searchResult = useSelector(state => state.dashboard.globalSearch) || []
    const loading = useSelector(state => state.dashboard.loading)

    const userInfo = useSelector(
      (state) => state.branches
    )?.manager;
    
    const socket = useSelector(state => state?.socket?.socket)

    const storage = new Storage();
    const userData = storage.getUserDetails();

    const dp = useSelector((state)=>state.company.dp);

    const [openPopper, setOpenPopper] = useState(null)
    // const [notifications, setNotification] = useState([])

    const handleOpenPopper = (event) => {
      setOpenPopper(openPopper ? null : event.currentTarget);
  }

    useEffect(()=> {
      dispatch(getATeammemberAsync(userData?.id))
    },[])

    useEffect(()=>{
      if(userData === null || isExpired){
        navigation('/') 
      }
      if(activeCompany !== null){
        dispatch(getNotificationAsync())

      }

    },[activeCompany, isExpired])

    useEffect(()=>{
      if(searchedWord?.length > 1){
        dispatch(getGlobalSearchAsync(searchedWord))
      }
    },[dispatch, searchedWord])

    const handlePushNotification = (data) => {
      dispatch(updatePushNotificationStatus({status: userInfo?.pushNotificationStatus ? false : true})).then((res) => {
        if(res?.meta?.requestStatus === "fulfilled"){
          dispatch(getATeammemberAsync(userData?.id))
          toast.success("Push notification was successfully updated")
        }
      })
    }

    const Logout = ()=>{
      localStorage.removeItem("linked")
      localStorage.removeItem("@schedulefilter")
      localStorage.removeItem("withdrawalItem")
      localStorage.removeItem("new-user")
      localStorage.removeItem("transferItem")
      localStorage.removeItem("color-theme")
      localStorage.removeItem("user")
      localStorage.removeItem("activeCompany")
      localStorage.removeItem("step")
      localStorage.removeItem("authToken")
      localStorage.removeItem("companies")
      localStorage.removeItem("picture")
      dispatch(setLoggedIn(false))
      socket.emit('logout');

    // Disconnect the socket connection
      socket.disconnect();
      navigation('/login');
    }

    const mainAnim ={
      exit: {
        opacity: 0,
        transition: {ease: 'easeOut', duration: 1}
      }
    }

    const handleGlobalSearch = (e) => {
      searchWord(e.target.value)
    }

    const convLabel = {
      "location": "Locations",
      "teamMembers": "Team members",
      "jobRoles": "Job Roles",
      "announcements": "Announcements",
      "manufacturers": "Manufacturers",
      "suppliers": "Suppliers"
    }

  return (
    <div>
      <AnimatePresence>

      {openPopper &&
      <motion.div variants={mainAnim} exit={'exit'}>
          <Notification openPopper={openPopper} setOpenPopper={setOpenPopper} user={userInfo} handlePushNotification={handlePushNotification} data={notifications} /> 
      </motion.div>}
      </AnimatePresence>
      <div className={`flex flex-col justify-center h-[69px] border-bottom dark:bg-primary dark:border-b-[0.4px] py-12 dark:border-b-white ${layoutClass}`}>
          <div className='flex flex-row justify-between items-center ml-[30px]'>
            <div className="flex items-center gap-2">
              {TitleIcon && <TitleIcon />}
             <p>{title}</p>
            </div>
              {/* <ClickAwayListener
              onClickAway={()=> {
                searchWord("")
              }}
              >
              <div className="flex relative items-center border-b-[0.5px] border rounded-lg gap-3 py-3 pl-5 max-w-[44%]">
                <BsSearch className=' dark:text-white'/>
                <input onChange={handleGlobalSearch} value={searchedWord} placeholder="Search" className="outline-none w-[500px] dark:bg-primary dark:text-white" />
                {searchedWord?.length > 1 &&  (<div className="absolute max-h-[400px] z-50 py-[40px] bg-white overflow-y-scroll left-0 top-full w-full shadow-shift-shadow dark:bg-primary">
                  {
                    !loading && Object.entries(searchResult)?.map((elem) => elem[1]?.length > 0 && (
                      selectContainer(elem, convLabel, navigation)
                    ))
                  }
                  {loading && (<Loader />)}
                  {
                    !loading && !Object.values(searchResult)?.some((x) => x.length > 0) && <p className="p-4 h-[200px] text-center dark:text-white">No Search Data</p>
                  }
                </div>)}
              </div>
              </ClickAwayListener> */}
          
              <div className='flex flex-row items-center gap-5 pr-6'>
                {userInfo?.firstName && userInfo?.email && (
                  <>
                    <div className={`flex flex-row items-center bg-[white] gap-2 py-1 px-2 rounded-md justify-center dark:bg-primary ${layoutClass}`}>
                      <div className='label'>
                        <div className={`text-[14px] ${layoutProps?.headerClass} dark:text-white`}>{userInfo.firstName+" "+userInfo.lastName}</div>
                        <div className={`text-[10px] text-[#A5A5A5] dark:text-white ${layoutProps?.subHeaderClass}`}>{userInfo.email}</div>
                      </div>
                      {activeCompany && <div className='border-1 w-[50px] h-[50px] rounded-full cursor-pointer' onClick={()=>navigation('/profile-page')}>
                        {dp === undefined || dp===null ? <></>:<img alt="logo" className='w-[100%] h-[100%] rounded-full' src={dp.url}  />}
                      </div>}
                      <div className=''>
                        <FiChevronDown />
                      </div>
                    </div>

                    <div className='h-[50px] border-l-1'>
                    </div>
                  </>
                )}
                
                <div className='cursor-pointer' onClick={Logout}>
                  <LogoutIcon sx={{
                      color: "grey"
                    }}  />
                </div>
              </div>
          </div>
      </div>
    </div>
  )
}

export default AppBarTop