import React, {useState, useEffect, useCallback} from 'react'
import { FaClipboardList } from 'react-icons/fa';

import SearchInput from '../../Widgets/SearchInput'
import CustomDropdown from '../../Widgets/CustomDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getDatesArray } from '../../../utils/dateArray';
import moment from 'moment';
import { months } from '../ShiftScreen/Tabs/BranchSchedule/mock';
import TablePagination from '@mui/material/TablePagination';
import { Link, useNavigate } from 'react-router-dom';
import AppLayout from '../../Widgets/AppLayout';
import { getInventoryWithdrawalLogs } from '../../../Network/ServiceClass/InventoryService';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import { month } from 'react-big-calendar-scheduler/lib/utils/dates';
import Inventory, { getInventoryWithdrawalLogsThunk } from '../../../Store/Inventory';
import { TableWrapper, TableHeader, Td, Tr } from '../../Widgets/Table/TableWrapper';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { logsTutorial } from '../mock';
import { ReactComponent as LogsIcon } from '../../../Assets/logs.svg';

const getMonth = {
    None: '0',
    January: '01',
    February: '02',
    March: '03',
    April: '04',
    May: '05',
    June: '06',
    July: '07',
    August: '08',
    September: '09',
    October: '10',
    November: '11',
    December: '12',
  };

const InventoryLogs = () => {

    // const navigate = useNavigate()
    const dispatch = useDispatch()
    // const [logs, setLogs] = useState([])
    // const [loading, setIsLoading] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const dateCreated = useSelector(
        (state) => state?.company?.activeCompany?.createdOn
      );

    const withdrawalLogsList = useSelector(state => state.inventory)?.withdrawalLogs
    const loading = useSelector(state => state.inventory.loading)

    const [yearSelected, setYearSelected] = useState({
        label: moment().format("yyyy"),
        value: moment().format("yyyy"),
    });

    const [monthSelected, setMonth] = useState({
        label: moment().format('MMMM'),
        value: getMonth[moment().format('MMMM')],
    });

    useEffect(()=>{
        dispatch(getInventoryWithdrawalLogsThunk({
            page:page + 1, 
            pageSize:rowsPerPage, 
            month:Number(monthSelected?.value),
            year: Number(yearSelected?.value)
    }))
    
    },[monthSelected, yearSelected, page, rowsPerPage, dispatch])
    

    const handleOnDateChange = (data) => {
        setYearSelected(data);
    };

    const handleOnMonthChange = (data) => {
        setMonth(data);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

  return (
    <section>
        <AppLayout
            showAppBar={false}
            hideLiveUsers={true}
            custom="inventory"
            subCustom="inventory-logs"
            title=""
            customTitle="Inventory"
            subtitle=""
        >
             <div className=" absolute top-[15px] left-[15px] w-[calc(100%-15px)] h-[95vh] overflow-y-auto border border-[#EAEAEA] rounded-[12px] flex flex-col bg-white">
                        <div className=" flex flex-col w-full h-[30px] pl-5 pt-2 gap-[10px]">
                                <div className="flex items-center gap-2">
                                    <LogsIcon />
                                    <p className="font-[Plus Jakarta Sans] font-medium text-[12px] text-[#7a7a7a] leading-[15px]">
                                        Logs
                                    </p>
                                </div>
                                <div className=" flex flex-col w-full h-full">
                                    <h1 className="font-[Plus Jakarta Sans] font-semibold text-[30px]">
                                        Logs
                                    </h1>
                                    <p className="font-[Plus Jakarta Sans] font-medium text-[14px] text-[#7a7a7a] leading-[15px]">
                                        See all inventory logs
                                    </p>
                                </div>
                        </div>
                <div className='flex gap-2 items-center mt-[120px] px-5'>
                    <SearchInput 
                        className="w-[60vw] h-[40px] !rounded-[24px] border border-[#E8E8E8] placeholder-[#C3C3C3]-400 placeholder:font-[Plus Jakarta Sans] placeholder:text-sm"
                        inputStyle={'placeholder:text-gray-400'}
                        iconStyle='text-gray-400'
                        placeholder='Search for items...' 
                        onChange={(e)=>{setSearchValue(e.target.value)}}
                    />

                    <CustomDropdown
                        data={getDatesArray(dateCreated, new Date().toString())?.map(
                        (dates) => ({ label: dates, value: dates })
                        )}
                        className="w-28 h-[40px] !rounded-[24px] ml-4"
                        optionClass="w-[90%] right-0 mt-3 z-[1000]"
                        value={yearSelected}
                        dropdownTitleClass='dark:text-white'
                        onChangeOption={handleOnDateChange}
                    />

                    <CustomDropdown
                        data={months}
                        className="w-28 h-[40px] !rounded-[24px] ml-4"
                        value={monthSelected}
                        dropdownTitleClass="text-A5A dark:text-white"
                        optionClass="z-[1000]"
                        onChangeOption={handleOnMonthChange}
                    />


                </div>

                <div className="w-fit px-5">
                  <TableWrapper className='mt-6 mb-12'>
                      <table className="w-[77vw] text-left">
                          <thead>
                              <tr className="bg-[#F1FBF2]">
                                  <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider w-[150px]">
                                      Date
                                  </th>
                                  <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider w-[150px]">
                                      Time
                                  </th>
                                  <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider w-[150px]">
                                      User
                                  </th>
                                  <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
                                      Details
                                  </th>
                                  <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider w-[150px]">
                                      Location
                                  </th>
                                  <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider w-[150px]">
                                      Action
                                  </th>
                              </tr>
                          </thead>
                          {!loading ? (
                              <tbody>
                                  {(withdrawalLogsList.length !== 0 && !loading) ? 
                                      withdrawalLogsList
                                      .filter((x) => x?.user?.firstName?.toLowerCase().includes(searchValue) ||
                                      x?.user?.lastName?.toLowerCase().includes(searchValue))
                                      .map(val => (
                                          <tr key={val.id} className="border-b border-[#EAEAEA] hover:bg-gray-50">
                                              <td className="px-3 py-4 whitespace-nowrap text-sm text-[#404040]">
                                                  {dayjs(val?.id?.timestamp).format('DD/MM/YYYY')}
                                              </td>
                                              <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                                                  {dayjs(val?.id?.timestamp).format('h:mmA')}
                                              </td>
                                              <td className="px-3 py-4 whitespace-nowrap text-sm text-[#404040]">
                                                  {val?.user?.firstName} {val?.user?.lastName}
                                              </td>
                                              <td className="px-3 py-4 whitespace-nowrap overflow-hidden text-ellipsis text-sm text-[#7A7A7A]">
                                                  {val?.id?.details}
                                              </td>
                                              <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                                                  {val?.branch?.name}
                                              </td>
                                              <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                                                  {val?.id?.action}
                                              </td>
                                          </tr>
                                      ))
                                  :
                                      <tr>
                                          <td colSpan={6} className="text-center py-5 text-[#7A7A7A] text-sm">
                                              Nothing to see here
                                          </td>
                                      </tr>
                                  }
                              </tbody>
                          ) : (
                              <tbody>
                                  <tr>
                                      <td colSpan={6} className="text-center py-10 border-b border-[#EAEAEA]">
                                          <CircularProgress />
                                      </td>
                                  </tr>
                              </tbody>
                          )}
                      </table>
                  </TableWrapper>
                </div>
                <div className='flex justify-start sticky bottom-0'>
                    <TablePagination
                        className=' dark:text-white'
                        component="div"
                        count={100}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                </div>
            </div>
        </AppLayout>
        
    </section>
  )
}

export default InventoryLogs