import { useEffect, useState } from 'react'
import CustomDropdown from '../../../Widgets/CustomDropdown';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { createInventoryItemThunk, getAllItemsThunk, getInventoryGroupsThunk } from '../../../../Store/Inventory';
import { Loader } from '../../../Widgets/Loader';
import AppLayout from '../../../Widgets/AppLayout';
import { getBranchesAsync } from '../../../../Store/Branches';
import GlobalButton from '../../../Widgets/GlobalButton';
import CreateInventoryModal from './components/CreateInventoryItem';
import { toast } from 'react-toastify';
import sortBy from "lodash/sortBy"
import SearchInput from '../../../Widgets/SearchInput';
import { TableWrapper, Td, Tr } from '../../../Widgets/Table/TableWrapper';
import { InventoryItemGroup } from '../InventoryItemGroup/inventoryItemGroup';
import InventoryTable from './components/InventoryTable';
import InventoryButton from './components/InventoryButton';
import { BsListCheck } from 'react-icons/bs';
import { ReactComponent as InventoryIcon } from '../../../../Assets/inventory.svg';


export const itemStatus = {
  "received": <p className="text-26E text-center font-semibold bg-CF6 rounded-[40px] py-[10px] px-[14px]">Received</p>,
  "pending_approval": <p className="text-1F5 text-center bg-4FE font-semibold rounded-[40px] py-[10px] px-[14px]">Pending approval</p>,
  "ordered": <p className="text-09B text-center bg-0F2 font-semibold rounded-[40px] py-[10px] px-[14px]">Ordered</p>,
  "partial": <p className="text-C00 text-center bg-BEB font-semibold rounded-[40px] py-[10px] px-[14px]">Partial</p>,
}

export const statusTransform = {
  "received": "Received",
  "pending_approval": "Pending approval",
  "ordered": "Ordered",
  "partial": "Partial",
}


export const InventoryItems = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchText, searchinventory] = useState("")
  const [headerToSort, sortHeader] = useState("name")
  const [order, setOrder] = useState("asc")
  const [createModalIsOpen, openCreateModal] = useState(false)
  const inventoryItem = useSelector((state) => state.inventory)?.items || []
  const inventoryGroup = useSelector((state) => state.inventory)?.groups
  const loading = useSelector((state) => state.inventory)?.loading

  const [group, selectGroup] = useState({
    label: 'All groups',
    value: '',
  })

  useEffect(()=>{
    dispatch(getAllItemsThunk())
    dispatch(getInventoryGroupsThunk())
    dispatch(getBranchesAsync())
  },[])

  const handleCloseModal =() => {
    openCreateModal(false)
  }

  const handleSorting = () => {
    let data = sortBy(inventoryItem.map((x)=> ({...x, suppliers: x?.manAndSupp?.length || 0})), [headerToSort])

    if(order === "desc"){
      data = data.reverse()
    }

    return data
  }

  const handleSubmitItem = (data) => {
    const newData = {...data, packageCost: data.packageCost * 100}
    dispatch(createInventoryItemThunk(newData)).then((res) => {
      if(res?.meta?.requestStatus === "fulfilled"){
        dispatch(getAllItemsThunk()).then((res) => {
          if(res?.meta?.requestStatus === "fulfilled"){
            handleCloseModal();
            toast.success("Inventory item was successfully created")
          }
        })
      } else {
        toast.error(res?.payload?.data?.message)
      }
    })
  }

  const handleHeaderClick = (header)=> {
    sortHeader(header);

    if(order === "asc"){
      setOrder("desc")
    } else {
      setOrder("asc")

    }
  }

  const handleSelectGroup = (val) => {
    selectGroup(val)
  }
  
  return (
    <section className="bg-[#F5F5F5] min-h-screen">
      <AppLayout
        showAppBar={false} 
        hideLiveUsers={true} 
        custom="inventory"
        subCustom="inventory-items"
        className="!pt-0"
      >
        <div className="flex flex-col absolute top-[15px] border-[#F1FBF2] rounded-[12px] w-full h-[95vh] bg-white">
          <div className='flex flex-row gap-2 px-5 pt-4'>
                <InventoryIcon />
                <p className="font-[Plus Jakarta Sans] font-normal text-[14px] text-[#404040] mb-4">
                    Inventory Items
                </p>
          </div>
          <div className='flex justify-between items-start px-5 pt-4'>
            <div className='flex flex-col'>
              <h1 className="font-[Plus Jakarta Sans] font-semibold text-[30px] text-[#404040]">
                Inventory Items
              </h1>
              <p className="font-[Plus Jakarta Sans] font-normal text-[14px] leading-[22px] text-[#606060]">
                Manage the List of Inventory
              </p>
            </div>

            <div className="flex relative gap-4 ml-auto mr-[calc(100vw-96vw-20px)] self-end">
              <InventoryButton
                onClick={() => openCreateModal(true)}
                showIcon
                title="+ Create Item"
                className="!bg-[#EAF5EB] !text-black w-[157px] h-[48px] border rounded-[6px]"
              />
              <InventoryButton
                onClick={() => navigate('/inventory-item-group')}
                showIcon={false}
                title="See All Groups"
                className="!bg-black !text-white w-[129px] h-[46px] border rounded-[6px]" 
              />
            </div>
          </div>

          <div className="flex items-center gap-3 px-5 dark:text-white mt-8">
            <SearchInput 
              placeholder='search inventory item'
              onChange={(e) => {
                searchinventory(e.target.value);
              }}
              className="w-[66vw] h-[40px] border !rounded-[24px] border-[#E8E8E8]"
            />
            <div className="flex items-center gap-3 dark:text-black">
              <CustomDropdown
                data={[
                  {
                    label: 'All groups',
                    value: '',
                  },
                  ...inventoryGroup?.map((x) => ({
                    label: x?.name,
                    value: x?.id,
                  })),
                ]?.map((team) => ({
                  label: team?.label,
                  value: team?.value,
                }))}
                optionClass="z-[500]"
                className="min-w-[155px] w-max !rounded-[24px] h-[40px] !border-[#E8E8E8]"
                value={group}
                onChangeOption={handleSelectGroup}
              />
            </div>
          </div>

          <div className="inventory-table border border-[#EAEAEA] overflow-y-auto ml-5 mt-8 w-fit">
            <div >
              <InventoryTable 
                activeFilter={group.label}
                searchQuery={searchText}
                selectedItems={[]}
                setSelectedItems={() => {}}
              />
            </div>
          </div>
          
          
          

          {createModalIsOpen && (
            <CreateInventoryModal
              isLoading={loading}
              handleSubmitItem={handleSubmitItem}
              openInventoryModal={createModalIsOpen}
              handleCloseInventoryModal={handleCloseModal}
            />
          )}
        </div>
      </AppLayout>
    </section>
  );
};
