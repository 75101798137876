import React, {useEffect, useState, useCallback} from "react"
import GlobalButton from "../../../Widgets/GlobalButton"
import AppLayout from "../../../Widgets/AppLayout"
import CustomDropdown from "../../../Widgets/CustomDropdown"
import CustomButton from "../../QuickWithdrawal/TestButton"

import { Branch } from "../../../../Network/ServiceClass/Branch"
import { useDispatch, useSelector } from "react-redux"
import { getInventoryOrderRequestThunk } from "../../../../Store/Inventory"
import { CircularProgress } from "@mui/material"
import dayjs from "dayjs"
import { useNavigate } from "react-router"
import CurrencyFormat from "react-currency-format"
import { toast } from "react-toastify"
import { Storage } from "../../../../Network/StorageClass/StorageClass"
import {ImAttachment} from 'react-icons/im'
import FileUploadUi from "../Widget/FileUploadUi"
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter"
import ViewDocs from "../Widget/ViewDocs"
import avatar from '../../../../Assets/avatar.png'
import TutorialPanel from '../../../Widgets/Tutorial/TutorialPanel'
import { orderRequestTutorial } from '../../mock'
import SearchInput from "../../../Widgets/SearchInput"
import { AiOutlineFolder } from 'react-icons/ai';
import { HiMapPin } from 'react-icons/hi2';

const branchService = new Branch()
const storage = new Storage()



export const StockOrderRequest = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const orderRequests = useSelector(state => state.inventory.orderRequest || [])
  const loading = useSelector(state => state.inventory.loading)

  const [branches, setBranches] = useState([])
  const [selectedBranch, setSelectedBranch] = useState({
    label: (
      <div className="flex items-center gap-2">
        <HiMapPin className="text-gray-600" />
        <span>All locations</span>
      </div>
    ),
    value: ''
  })
  const [selected, setSelected] = useState(null)
  const [toggleMenu, setToggleMenu] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingAction, setIsLoadingAction] = useState(false)
  const [data, setData] = useState([])

  const [openAttachModal, setOpenAttachModal] = useState(false)
  const [attachmentData, setAttachmentData] = useState(null)
  const [openDocsModal, setOpenDocsModal] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  // Add filtered data logic
  const filteredData = searchQuery
    ? orderRequests.filter(order => 
        order?.orders[0]?.orderIdentifier?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : orderRequests;

  const isAllow = () => {
    if ([1,2].includes(storage.getActiveCompany().memberships[0].privilege)) return true
    else return false
  }


  useEffect(()=> {
    branchService.getBranches()
    .then(val => {
      setBranches([{label: 'All locations', value:'1'},...val?.data.map(x => ({label: x.name, value: x.id}))])
    })
  },[])

  const getOrderRequest = () => {
    dispatch(getInventoryOrderRequestThunk(selectedBranch.value === '1' ? undefined : selectedBranch.value))
      .then(val => {
        if (val?.payload?.data) {
          setData(Array.isArray(val.payload.data) ? val.payload.data : []);
        } else {
          setData([]);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getOrderRequest();
  }, [selectedBranch]);

  // const handleSearch = useCallback(()=>{

  // },[])

  const formatStatus = useCallback((status)=>{
    if (status === 'received') return 'Received'
    else if (status === 'partial') return 'Partial'
    else if (status === 'ordered') return 'Ordered'
    else if (status === 'pending_approval') return 'Pending'
    else return capitalizeFirstLetter(status)
  }, []) 

  const reLoad = () => {
    dispatch(getInventoryOrderRequestThunk(selectedBranch.value === '1' ? undefined : selectedBranch.value))
    .then(vall => {
      setData(vall.payload?.data)
      setToggleMenu(null)
      setSelected(null)
      toast.success('Successfull')
      setIsLoadingAction(false)
    })

  }

  // const handleApprove = (status) => {
  //   setIsLoadingAction(true)
  //   approveOrderRequest({id: selected?.orderIdentifier, status})
  //   .then(val => {
  //     if(val?.status === 200) reLoad()
  //     else {
  //       toast.error(val?.data?.message)
  //       setIsLoadingAction(false)
  //     }
  //   })

    

  // }

  const handleCloseAttachModal = ()=> {
    setOpenAttachModal(false)
    setAttachmentData(null)
  }

  const handleCloseDocsModal = ()=> {
    setOpenDocsModal(false)
    setAttachmentData(null)
  }

  return (
    <section>
      {openAttachModal && <FileUploadUi 
        openAttachModal={()=> setOpenAttachModal(true)} 
        handleCloseAttachModal={handleCloseAttachModal}
        data={attachmentData}
        callback = {reLoad}
      />}

      {openDocsModal && <ViewDocs 
        openModal={openDocsModal} 
        handleCloseModal={handleCloseDocsModal}
        data={attachmentData}
      />}

      {/* <CustomPopper openPopper={toggleMenu} 
            handleClose={()=>{
               !isLoadingAction && setToggleMenu(null)
               !isLoadingAction && setSelected(null)
               
            }} 
            className='z-[50]' 
            placement='bottom-start'>
            <div className='h-[150px] min-w-[120px] mt-1 px-4 py-4 font-bold  bg-white rounded-sm shadow-shift-shadow-small'>
              {!isLoadingAction ? <div className="flex flex-col gap-4">
                <GlobalButton 
                  onClick = {()=>handleApprove('partial')}
                  title="Partial" 
                  className="rounded-3xl font-semibold text-[16px] bg-[#C3F6F3] text-[#458970] "/>

                <GlobalButton 
                  onClick = {()=>handleApprove('ordered')}
                  title="Approved" 
                  className="rounded-3xl font-semibold text-[16px] bg-[#CDFFED] text-[#22CA8D] "/>    

              </div>:
              <div className="flex h-full items-center justify-center"><CircularProgress/></div>}

            </div>
        </CustomPopper> */}
        <AppLayout
          showAppBar={false}
          hideLiveUsers={true}
          custom="inventory"
          subCustom=""
          title=""
          customTitle="Inventory"
          subtitle=""
        >
            <div className="absolute top-[15px] left-[15px] w-[calc(100%-15px)] h-[95vh] overflow-y-auto border border-[#EAEAEA] rounded-[12px] flex flex-col bg-white">
              <div className="p-4">
                <div className="flex flex-row items-center gap-2">
                  <AiOutlineFolder />
                  <p className="font-[Plus Jakarta Sans] font-medium text-[12px] text-[#7a7a7a] leading-[15px]">
                    Order Request
                  </p>
                </div>

                <div className="flex justify-between items-center mt-[15px]">
                  <div>
                    <h1 className="font-[Plus Jakarta Sans] font-semibold text-[30px]">
                      Order Request
                    </h1>
                    <p className="font-[Plus Jakarta Sans] font-normal text-[14px] leading-[22px] text-[#606060]">
                      Create and manage order requests
                    </p>
                  </div>

                  <CustomButton
                    onClick={()=> navigate('/create-order-request')}
                    title="+ Create order request" 
                    className="rounded-md font-semibold text-[16px] !text-black !bg-[#EAF5EB] !w-[240px] h-[48px]"
                  /> 
                </div>
              </div>

              <div className="flex flex-col gap-4 mb-7">
              
                <div className="sticky top-[4.3rem] pb-5 bg-white dark:bg-138 px-4 w-[99%]">
                  <div className="w-full flex items-center gap-8">
                    <SearchInput 
                      placeholder="Search By ID" 
                      className="w-[62vw] h-[40px] !rounded-[24px] border border-[#E8E8E8] placeholder-[#C3C3C3]-400 placeholder:font-[Plus Jakarta Sans] placeholder:text-sm"
                      value={searchQuery}
                      onChange={(e)=> setSearchQuery(e.target.value)}
                    />

                    <CustomDropdown
                      mainContainerStyle="w-[15%]"
                      trimtext
                      data={branches.filter(branch => branch.label !== "All locations")}
                      className="h-[40px] w-[200px] border border-[#E8E8E8] !rounded-[24px] flex items-center text-[#848484] font-[Plus Jakarta Sans] text-sm pr-9"
                      value={selectedBranch}
                      dropdownTitleClass="text-A5A"
                      optionClass="z-[1000]"
                      onChangeOption={(e) => {
                        setSelectedBranch(e);
                      }}
                    />

                    
                  </div>
                </div>

                

                {!loading ? (
                  filteredData.length > 0 ? (
                    <div className="flex flex-col mb-28 gap-5 px-4">
                      {filteredData.map((order, index) => (
                        <div
                          onClick={()=> (!isLoadingAction && isAllow()) && navigate(`/stock-order-request/${order?.orders[0]?.orderIdentifier}`,
                          { state: {title:'Order Request'} })}
                          key={index} 
                          className={`border-9FD dark:border-969 ${isAllow() ? 'cursor-pointer': 'cursor-default'}  px-10 flex flex-col gap-5 pb-12 pt-3 border h-full rounded-[20px] w-[99%] `}>
                        
                          <div className="flex gap-2 justify-end ">
                          
                              {order?.attachments.length > 0 &&<div
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setOpenDocsModal(true)
                                    setAttachmentData(order)
                                  }}
                                  className='flex gap-1 h-full text-[13px] cursor-pointer items-center text-D57'>
                                  <ImAttachment size={13}/>
                                  <p>{order?.attachments.length}</p>
                                  <p>Documents</p>
                              </div>}

                              <div 
                                onClick={(e)=> {
                                  e.stopPropagation()
                                  setOpenAttachModal(true)
                                  setAttachmentData(order)
                                }}
                                className='flex gap-1 h-full ml-3 text-[13px] cursor-pointer items-center text-D57'>
                                <ImAttachment size={13}/>
                                <p>Attach</p>
                            </div>
                          </div>

                          <div className="flex justify-between">
                            <Column title={'ID'} text={order?.orders[0]?.orderIdentifier}/>
                            <Column title={'Location'} text={order?.orders[0]?.branch?.name} hexcode={order?.orders[0]?.branch?.hexcode}/>
                            
                            <Column 
                              title={'Created By'} 
                              text={`${order?.orders[0]?.teamMembership?.user?.firstName} ${order?.orders[0]?.teamMembership?.user?.lastName}`}
                              pic={order?.orders[0]?.teamMembership?.user?.picture?.url || 'empty'}  
                            />
                            
                            <Column title={'Date'} text={dayjs(order?.orders[0]?.createdOn).format('D MMM YYYY')}/>
                            <Column title={'No of items'} text={order?.orders?.length}/>

                            <div className="flex flex-col gap-3 items-center dark:text-white">
                              <p className="text-sm text-A5A dark:text-white font-bold">Total value</p>
                              <p className="text-[18px] font-extrabold">
                              <CurrencyFormat
                                value={order?.orders.length === 0 ? 0 : order?.orders?.reduce((accumulator, currentValue) => accumulator + currentValue.reference.packageCost, 0)/100} // The numeric value you want to format
                                displayType={'text'} // You can also use 'input' for input fields
                                thousandSeparator={true} // Use commas as thousands separators
                                prefix={'$'} // Currency symbol or prefix
                                decimalScale={2} // Number of decimal places
                              /></p>

                            </div>

                          
                          </div>  
                        
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center text-A5A mt-2">
                      {searchQuery 
                        ? "No order requests found matching your search"
                        : "No order requests found for this location"
                      }
                    </div>
                  )
                ) : (
                  <div className="text-center mt-2">
                    <CircularProgress />
                  </div>
                )}

              </div>
            </div>
        </AppLayout>
      
    </section>
  )
}

const Column = ({title, text, hexcode, pic}) => {
  return(
    <div className="flex gap-5">

      {pic && <div className="self-end">
        {pic !== 'empty' ? 
          <img className="w-9 h-9 rounded-full border-1 bg-gray-400" src={pic}/>
        :
        <img className="w-9 h-9 rounded-full" src={avatar}/>
        }
      </div>}

      <div className="flex flex-col gap-3 items-center min-w-20">
        <p className="text-sm text-A5A dark:text-white">{title}</p>

        <div className={`flex gap-2 items-center`}>
          {hexcode && <p className="w-2 h-2 rounded-full" style={{backgroundColor: hexcode}}/>}
          <p className="text-A5A dark:text-white font-bold">{text}</p>
        </div>
      </div>
    </div>
  )
}