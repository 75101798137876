import axios from 'axios';
import { conn_url } from '../conn_consts';
import { Storage } from "../StorageClass/StorageClass";

const storage = new Storage();


export const getAllItems = async () => {
    const response = await axios.get(`${conn_url}/api/v1/inventory/get-all-items`, storage.getConfig());
    return {data: response.data, status:  response.status};
  
}

export const getAnItem = async (id) => {
    const response = await axios.get(`${conn_url}/api/v1/inventory/get-item/${id}`, storage.getConfig());
    return {data: response.data, status:  response.status};
  
}

export const getInventoryGroups = async () => {
    const response = await axios.get(`${conn_url}/api/v1/inventory/get-groups`, storage.getConfig());
    return {data: response.data, status:  response.status};
}

export const createInventoryGroup = async (data) => {
    const response = await axios.post(`${conn_url}/api/v1/inventory/create-group`, data, storage.getConfig());
    return {data: response.data, status:  response.status};
}

export const confirmOrder = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/inventory/confirm-order`, data, storage.getConfig());
        return {data: response.data, status: response.status};
    } catch(err) {
        console.error('Error in confirmOrder API call:', err);
        
        // Handle different types of errors
        if (err.response) {
            // The request was made and the server responded with a status code
            console.error('Server error response:', err.response.data);
            return {
                data: err.response.data, 
                status: err.response.status,
                message: err.response.data?.message || 'Server error occurred'
            };
        } else if (err.request) {
            // The request was made but no response was received
            console.error('No response received:', err.request);
            return {
                data: { message: 'No response from server. Please check your network connection.' }, 
                status: 0,
                message: 'No response from server'
            };
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Request setup error:', err.message);
            return {
                data: { message: 'Failed to send request: ' + err.message }, 
                status: 0,
                message: err.message
            };
        }
    }
}

export const confirmQuickWithdrawal = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/inventory/withdraw-items`, data, storage.getConfig());
        return response.data;
    } catch(err) {
        throw Error(err)
    }
}

export const transferItems = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/inventory/transfer-items`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
    } catch(err) {
        throw Error(err)
    }
}

export const getTransferItemsRequest = async ({withdrawalLocation, transferedLocation, batchIdentifier}) => {

    const queryParams = [];

    if (withdrawalLocation !== null && withdrawalLocation !== undefined) {
        queryParams.push(`withdrawLocationId=${withdrawalLocation}`);
    }

    if (transferedLocation !== null && transferedLocation !== undefined) {
        queryParams.push(`transferLocationId=${transferedLocation}`);
    }

    if (batchIdentifier !== null && batchIdentifier !== undefined) {
        queryParams.push(`batchIdentifier=${batchIdentifier}`);
    }

   
    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
    const response = await axios.get(`${conn_url}/api/v1/inventory/get-transfer-request-items${queryString}`, storage.getConfig());
    return {data: response.data, status:  response.status};
}

export const getInventoryGroupItem = async (id) => {
    const {groupId, locationId} = id
    let uri 
    if(locationId === undefined) uri = `${conn_url}/api/v1/inventory/get-group-items?groupId=${groupId}`
    else uri = `${conn_url}/api/v1/inventory/get-group-items?groupId=${groupId}&locationId=${locationId}`
    const response = await axios.get(uri, storage.getConfig());
    return {data: response.data, status:  response.status};
  
}

export const getInventoryWithdrawalLogs = async ({page=1, pageSize=10, month, year}) => {
    const queryParams = [];

    if (page) {
        queryParams.push(`page=${page}`);
    }

    if (pageSize) {
        queryParams.push(`pageSize=${pageSize}`);
    }

    if(month){
        queryParams.push(`month=${month}`);

    }

    if(year){
        queryParams.push(`year=${year}`);

    }
    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

    const response = await axios.get(`${conn_url}/api/v1/inventory/withdrawal-logs${queryString}`, storage.getConfig());
    return {data: response.data, status:  response.status};
}


export const createInventoryItem = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/inventory/create-item`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
    } catch (error) {
        throw Error(error)
    }
}

export const createMetaInventoryItem = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/inventory/create-item-metadata`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
    } catch (error) {
        throw Error(error?.response?.data?.message)
    }
}

export const getInventorySuppliers = async () => {
    const response = await axios.get(`${conn_url}/api/v1/inventory/suppliers`, storage.getConfig());
    return {data: response.data, status:  response.status};
}


export const getInventoryManufacturers = async () => {
    const response = await axios.get(`${conn_url}/api/v1/inventory/manufacturers`, storage.getConfig());
    return response.data;
}

export const getInventoryMeasurementUnit = async () => {
    const response = await axios.get(`${conn_url}/api/v1/inventory/measurement-unit`, storage.getConfig());
    return response.data;
}

export const addInventoryMeasurementUnit = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/inventory/measurement-unit`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
    } catch(err) {
        return {data: err.response.data, status:  err.response.status};
    }
}

export const getInventoryItemsForecast = async ({referenceNumber, itemId}) => {
    const response = await axios.get(`${conn_url}/api/v1/inventory/extrapolate-utility-rate-forecast?referenceNumber=${referenceNumber}&inventoryItemsId=${itemId}`, storage.getConfig());
    return {data: response.data, status:  response.status};
}

export const getInventoryItemsFuturePurchase = async ({referenceNumber, itemId, locationId}) => {
    let uri = `/api/v1/inventory/extrapolate-purchase-prop?referenceNumber=${referenceNumber}&inventoryItemsId=${itemId}`

    if(locationId !== null && locationId !== undefined){
        uri = `/api/v1/inventory/extrapolate-purchase-prop?referenceNumber=${referenceNumber}&inventoryItemsId=${itemId}&locationId=${locationId}`
    }

    const response = await axios.get(`${conn_url}${uri}`, storage.getConfig());
    return {data: response.data, status:  response.status};
}

export const getInventoryItemsUtilityRate = async ({groupId}) => {
    const response = await axios.get(`${conn_url}/api/v1/inventory/extrapolate-utility-rate?groupId=${groupId}`, storage.getConfig());
    return {data: response.data, status:  response.status};
}

export const addInventorySuppliers = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/inventory/supplier`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
    } catch(err) {
        return {data: err.response.data, status:  err.response.status};
    }
}

export const addInventoryManufacturers = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/inventory/manufacturer`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
    } catch(err) {
        return {data: err.response.data, status:  err.response.status};
    }
}

export const stockupInventoryItem = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/inventory/stock-up-item`, data, storage.getConfig());
        return response.data;
    } catch(err) {
        throw Error(err?.response?.data?.details.toString());
    }
}

export const stockupInventoryTransferItem = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/inventory/stock-up-transfer-item`, data, storage.getConfig());
        return {data: response.data, status:  response.status}
    } catch(err) {
        return {data: err.response.data, status:  err.response.status};
    }
}

export const addInventoryManuAndSuppliers = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/inventory/add-man-supp`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
    } catch(err) {
        return err?.response
    }
}

export const editInventorySuppliers = async ({data, id}) => {
    try {
        const response = await axios.patch(`${conn_url}/api/v1/inventory/supplier/${id}`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
    } catch(err) {
        return {data: err.response.data, status:  err.response.status};
    }
}

export const editInventoryManufacturer = async ({data, id}) => {
    try {
        const response = await axios.patch(`${conn_url}/api/v1/inventory/manufacturer/${id}`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
    } catch(err) {
        return {data: err.response.data, status:  err.response.status};
    }
}

export const deletInventorySuppliers = async (id) => {
    try {
        const response = await axios.delete(`${conn_url}/api/v1/inventory/supplier/${id}`, storage.getConfig());
        return {data: response.data, status:  response.status};
    } catch(err) {
        return {data: err.response.data, status:  err.response.status};
    }
}

export const deleteInventoryManufacturer = async (id) => {
    try {
        const response = await axios.delete(`${conn_url}/api/v1/inventory/manufacturer/${id}`, storage.getConfig());
        return {data: response.data, status:  response.status};
    } catch(err) {
        return {data: err.response.data, status:  err.response.status};
    }
}

export const getInventoryOrderRequest = async (id) => {
    let uri
    if (id === undefined) uri = '/api/v1/inventory/get-order-requests'
    else uri = `/api/v1/inventory/get-order-requests/${id}`
    const response = await axios.get(`${conn_url}${uri}`, storage.getConfig());
    return {data: response.data, status:  response.status};
}

export const getInventoryBackOrderRequest = async (id) => {
    let uri
    if (id === undefined) uri = '/api/v1/inventory/get-backorder'
    else uri = `/api/v1/inventory/get-backorder/${id}`
    const response = await axios.get(`${conn_url}${uri}`, storage.getConfig());
    return {data: response.data, status:  response.status};
}

export const getInventorySingleOrderRequest = async (id) => {

    const response = await axios.get(`${conn_url}/api/v1/inventory/get-single-order-requests/${id}`, storage.getConfig());
    return {data: response.data, status:  response.status};
}

export const fetchCurrentItems = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/inventory/fetch-current-item-state`,data, storage.getConfig());
        return response.data;
    } catch (err){
        throw new Error(err?.response?.data?.message)
    }
}

export const approveOrderRequest = async ({id, status}) => {
    try {
        const response = await axios.put(`${conn_url}/api/v1/inventory/approve-order-requests/${id}`, {status}, storage.getConfig());
        return {data: response.data, status:  response.status};
    } catch(err) {
        return {data: err.response.data, status:  err.response.status};
    }
}

export const stockUpOrderRequest = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/inventory/order-request-stock-up`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
    } catch(err) {
        return {data: err.response.data, status:  err.response.status};
    }
}

export const inventoryOrderAttachment = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/inventory/order-attachment`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
    } catch(err) {
        return {data: err.response.data, status:  err.response.status};
    }
}

export const getInventoryPendingOrders = async (id) => {
   let uri
    if (id === null) uri = '/api/v1/inventory/get-pendingOrders'
    else uri = `/api/v1/inventory/get-pendingOrders/${id}`
    const response = await axios.get(`${conn_url}${uri}`, storage.getConfig());
    return {data: response.data, status:  response.status};
}

export const getInventoryPendingTransferApproval = async (id) => {
    let uri
    if (id === null) uri ='/api/v1/inventory/get-pendingTransferItems'
    else uri = `/api/v1/inventory/get-pendingTransferItems/${id}`
    const response = await axios.get(`${conn_url}${uri}`, storage.getConfig());
    return {data: response.data, status:  response.status};
  
}

export const getInventoryDataWithdrawals = async (id) => {
    let uri
    if (id === null) uri ='/api/v1/inventory/get-item-withdrawal-for-company'
    else uri = `/api/v1/inventory/get-item-withdrawal-for-company?withdrawLocationId=${id}`
    const response = await axios.get(`${conn_url}${uri}`, storage.getConfig());
    return {data: response.data, status:  response.status};
  
}

export const getInventoryDataUsage = async (id) => {
    let uri
    if (id === null) uri ='/api/v1/inventory/get-tracked-item-usage'
    else uri = `/api/v1/inventory/get-tracked-item-usage?branchId=${id}`
    const response = await axios.get(`${conn_url}${uri}`, storage.getConfig());
    return {data: response.data, status:  response.status};
  
}

export const getInventoryExpiredItems = async (id) => {
    let uri
    if (id === null) uri ='/api/v1/inventory/get-expiring'
    else uri = `/api/v1/inventory/get-expiring?branchId=${id}`
    const response = await axios.get(`${conn_url}${uri}`, storage.getConfig());
    return {data: response.data, status:  response.status};
  
}

export const getInventoryItemsBelowCriticalLimit = async (id) => {
    let uri
    if (id === null) uri ='/api/v1/inventory/get-itemsBelowCriticalLimits'
    else uri = `/api/v1/inventory/get-itemsBelowCriticalLimits/${id}`
    const response = await axios.get(`${conn_url}${uri}`, storage.getConfig());
    return {data: response.data, status:  response.status};
  
}

export const getInventoryLogs = async (id) => {
    let uri
    if (id === null) uri ='/api/v1/inventory/get-inventory-logs'
    else uri = `/api/v1/inventory/get-inventory-logs/${id}`
    const response = await axios.get(`${conn_url}${uri}`, storage.getConfig());
    return {data: response.data, status:  response.status};
  
}

export const getInventoryGroupDist = async () => {
    const uri ='/api/v1/inventory/get-item-group-dist'
    const response = await axios.get(`${conn_url}${uri}`, storage.getConfig());
    return {data: response.data, status:  response.status};
  
}

export const inventoryPlaceBackOrder = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/inventory/place-in-back-order`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
    } catch(err) {
        return {data: err.response.data, status:  err.response.status};
    }
}

export const inventoryUpdateTransferItemsStatus = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/inventory/update-transfer-items-status`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
    } catch(err) {
        return {data: err.response.data, status:  err.response.status};
    }
}

export const trackItemUsage = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/inventory/track-item-usage`, data, storage.getConfig());
        return response.data;
    } catch(err) {
        return {data: err.response.data, status:  err.response.status};
    }
}

export const inventoryUpdateTrackingDetails = async (data) => {
    try {
        const response = await axios.post(`${conn_url}/api/v1/inventory/update-tracking-details`, data, storage.getConfig());
        return {data: response.data, status:  response.status};
    } catch(err) {
        return {data: err.response.data, status:  err.response.status};
    }
}