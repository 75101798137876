import React from "react";

const InventoryButton = ({ title = "", onClick, className = "" }) => {
  return (
    <button
      onClick={onClick}
      className={`flex items-center justify-center ${className}`}
    >
      {title}
    </button>
  );
};

export default InventoryButton;
