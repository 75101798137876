import React, {useState, useEffect, useCallback} from 'react'
import SearchInput from '../../../Widgets/SearchInput'
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';
import AppLayout from '../../../Widgets/AppLayout';
import ButtonWithIcon from '../../../Widgets/ButtonWithIcon';
import { CreateSupplier } from './Widget/CreateSupplier';
import { EditSupplier } from './Widget/EditSupplier';
import { DeleteSupplier } from './Widget/DeleteSupplier';
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { ClickableItem } from '../../../Widgets/ClickableItem';
import { CustomPopper } from '../../../Widgets/CustomPopper';
import { useDispatch, useSelector } from 'react-redux';
import { getInventorySuppliersThunk } from '../../../../Store/Inventory';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import { TableWrapper, Td, Tr, TableHeader } from '../../../Widgets/Table/TableWrapper';
import { TbScissors } from 'react-icons/tb';
import { ReactComponent as ManSuppIcon } from '../../../../Assets/mansupp.svg'


const SuppliersPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const supplierState = useSelector(state => state.inventory)

    const [openCreateSupplier, setOpenCreateSupplier] = useState(false)
    const [openEditSupplier, setOpenEditSupplier] = useState(false)
    const [openDeleteSupplier, setOpenDeleteSupplier] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [selectedData, setSelectedData] = useState(null)

    const [toggleMenu, setToggleMenu] = useState(null)
    const [menuIndex, setMenuIndex] = useState(null)
    const [isLoading, setIsLoading] = useState(false)


    
    const handleSearch = useCallback(() => {
        let data = supplierState?.suppliers
        if(searchValue.length !== 0){
          const pattern = new RegExp(`\D*${searchValue}\D*`,'i')
                data = supplierState?.suppliers.filter((val) => (
                    val.name?.toLowerCase().match(pattern) ||
                    val.description?.toLowerCase().match(pattern) ))
        }
        return data
    
    },[searchValue, supplierState?.suppliers])

    useEffect(()=>{
        setIsLoading(true)
        dispatch(getInventorySuppliersThunk())
        .then(_ => setIsLoading(false))
        .catch(_ => setIsLoading(false))
    },[])

  return (
    <section>
        <CreateSupplier open={openCreateSupplier} handleClose={()=> setOpenCreateSupplier(false)}/>
        {openEditSupplier && <EditSupplier open={openEditSupplier} handleClose={()=> setOpenEditSupplier(false)} data={selectedData}/>}
        {openDeleteSupplier && <DeleteSupplier open={openDeleteSupplier} handleClose={()=> setOpenDeleteSupplier(false)} data={selectedData}/>}
        
        <CustomPopper openPopper={toggleMenu} 
            handleClose={()=>{
                setToggleMenu(null)
                setSelectedData(null)
            }} 
            className='z-[50]' 
            placement='bottom-start'>
            <div className='h-[70px] w-[120px] dark:bg-292 dark:text-white mt-1 py-3 font-bold flex flex-col justify-between text-[14px] bg-white rounded-sm shadow-select'>
                <ClickableItem text={'Delete'} handleClick={() => {
                    setOpenDeleteSupplier(true)
                    setToggleMenu(null)
                }} />

                <ClickableItem text={'Edit'} handleClick={() => {
                    setOpenEditSupplier(true)
                    setToggleMenu(null)
                }} />
            </div>          
        </CustomPopper>
         <AppLayout
            showAppBar={false}
            hideLiveUsers={true}
            custom="inventory"
            subCustom="suppliers"
            title=""
            customTitle="Inventory"
            subtitle=""
        >
            <div className=" absolute top-[15px] left-[15px] w-[calc(100%-15px)] h-[95vh] overflow-y-auto border border-[#EAEAEA] rounded-[12px] flex flex-col bg-white">
                <div className=" flex flex-col w-full h-[30px] pl-5 pt-2 gap-[10px]">
                    <div className="flex items-center gap-2">
                          <ManSuppIcon />
                          <p className="font-[Plus Jakarta Sans] font-medium text-[12px] text-[#7a7a7a] leading-[15px]">
                              Suppliers
                          </p>
                    </div>
                    <div className='flex flex-row justify-between items-center'>
                      <div className=" flex flex-col w-full h-full">
                          <h1 className="font-[Plus Jakarta Sans] font-semibold text-[30px]">
                              Suppliers & Manufacturers
                          </h1>
                          <p className="font-[Plus Jakarta Sans] font-medium text-[14px] text-[#7a7a7a] leading-[15px]">
                              See all suppliers and manufacturers
                          </p>
                        </div>
                        <div className='pr-5'>
                            <ButtonWithIcon 
                              title='See Manufacturers List'
                              type='text' 
                              handleClick={() => navigate('/manufacturers-page')}
                              className="rounded-md font-semibold text-[16px] !bg-black !text-white w-[230px] h-[48px]"
                            />
                        </div>
                    </div>
                </div>
                    <div className='flex justify-between items-center mt-[120px] px-4 gap-4'>
                        <SearchInput 
                           className="w-[60vw] h-[40px] !rounded-[24px] border border-[#E8E8E8] placeholder-[#C3C3C3]-400 placeholder:font-[Plus Jakarta Sans] placeholder:text-sm"
                            inputStyle={'placeholder:text-gray-400'}
                            iconStyle='text-gray-400'
                            placeholder='Search for items...' 
                            onChange={(e)=>{setSearchValue(e.target.value)}}
                        />
                        <div className='pr-5'>
                            <ButtonWithIcon 
                                title='Add New Supplier' 
                                type='text' 
                                handleClick={()=>setOpenCreateSupplier(true)}
                                className="!rounded-[24px] font-semibold text-[16px] !bg-white !text-[#00AD57] w-[216px] h-[48px] pr-5"
                            />
                        </div>
                    </div>

                    <div className="px-4">
                        <TableWrapper className='mt-6 mb-12 w-fit'>
                            <table className="w-[77vw] text-left">
                                <thead>
                                    <tr className="bg-[#F1FBF2]">
                                        <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
                                            Last Updated
                                        </th>
                                        <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
                                            Name
                                        </th>
                                        <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
                                            Description
                                        </th>
                                        <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
                                            Phone
                                        </th>
                                        <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
                                            Contact
                                        </th>
                                        <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
                                            Website
                                        </th>
                                        <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
                                        </th>
                                    </tr>
                                </thead>

                                {!isLoading ? <tbody>
                                    {handleSearch().length !== 0 ?
                                        handleSearch().map((val, index) => (
                                            <tr key={index} className="border-b border-[#EAEAEA] hover:bg-gray-50">
                                                <td className="px-3 py-4 whitespace-nowrap text-sm text-[#404040]">
                                                    {dayjs(val?.createdOn).format('DD/MM/YYYY')}
                                                </td>
                                                <td className="px-3 py-4 whitespace-nowrap text-sm text-[#404040]">
                                                    {val?.name}
                                                </td>
                                                <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                                                    {val?.description}
                                                </td>
                                                <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                                                    {val?.phone}
                                                </td>
                                                <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                                                    {val?.contactInfo}
                                                </td>
                                                <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                                                    {val?.website}
                                                </td>
                                                <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                                                    <BiDotsVerticalRounded
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            setToggleMenu(toggleMenu ? null : e.currentTarget)
                                                            setMenuIndex(index)
                                                            setSelectedData(val)
                                                        }}
                                                        className='cursor-pointer' />
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td colSpan={7} className="text-center pt-5 text-[#7A7A7A]">Nothing to see here</td>
                                        </tr>
                                    }
                                </tbody>
                                    : <tbody>
                                        <tr>
                                            <td colSpan={7} className="text-center pt-10"><CircularProgress /></td>
                                        </tr>
                                    </tbody>}
                            </table>
                        </TableWrapper>
                    </div>
                </div>
            
        </AppLayout>
       
    </section>
  )
}

export default SuppliersPage