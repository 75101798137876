import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomDropdown from '../../Widgets/CustomDropdown';
import { toast } from 'react-toastify';
import { transferItemThunk, getTransferItemsRequestThunk } from '../../../Store/Inventory';
import { Branch } from '../../../Network/ServiceClass/Branch';
import { getInventoryMeasurementUnit } from '../../../Network/ServiceClass/InventoryService';
import { Link } from 'react-router-dom';

const TransferModal = ({ isOpen, onClose, selectedItems, branches }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.inventory);
  const [teamMembershipId, setTeamMembershipId] = useState(null);
  const [measurementUnits, setMeasurementUnits] = useState([]);
  const [selectedMeasurementUnit, setSelectedMeasurementUnit] = useState(null);
  
  const [withdrawLocation, setWithdrawLocation] = useState({ label: "Select location", value: "" });
  const [transferLocation, setTransferLocation] = useState({ label: "Select location", value: "" });
  const [transferAmounts, setTransferAmounts] = useState({});

  const branchService = new Branch();

  useEffect(() => {
    const getTeamMembership = async () => {
      try {
        const response = await branchService.fetchTeamMember({});
        if (response.status === 200) {
          // Assuming the first member is the current user's membership
          setTeamMembershipId(response.data[0]?.id);
        }
      } catch (error) {
        console.error('Error fetching team membership:', error);
      }
    };

    const fetchMeasurementUnits = async () => {
      try {
        const response = await getInventoryMeasurementUnit();
        if (response.length > 0) {
          setMeasurementUnits(response);
          setSelectedMeasurementUnit(response[0].id); // Set first unit as default
        }
      } catch (error) {
        console.error('Error fetching measurement units:', error);
      }
    };

    getTeamMembership();
    fetchMeasurementUnits();
  }, []);

  if (!isOpen) return null;

  const handleTransferClick = () => {
    if (!withdrawLocation.value || !transferLocation.value) {
      toast.error('Please select both locations');
      return;
    }

    if (!teamMembershipId) {
      toast.error('Team membership information not found');
      return;
    }

    if (!selectedMeasurementUnit) {
      toast.error('Measurement unit not found');
      return;
    }

    try {
      const submitData = {
        teamMembershipId: teamMembershipId,
        withdrawLocationId: withdrawLocation.value,
        transferLocationId: transferLocation.value,
        transferData: selectedItems.map(item => ({
          referenceNum: item.referenceNumber,
          quantityWithdrawn: Number(transferAmounts[item.id] || 1),
          measurementUnitId: selectedMeasurementUnit
        }))
      };

      console.log('Submitting transfer data:', submitData); // Debug log

      dispatch(transferItemThunk(submitData))
        .unwrap()
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            toast.success('Items transferred successfully');
            setTransferAmounts({});
            onClose();
            dispatch(getTransferItemsRequestThunk());
          } else {
            toast.error('Failed to transfer items');
          }
        })
        .catch((error) => {
          console.error('Error details:', error);
          toast.error(error?.response?.data?.message || 'Failed to transfer items');
        });
    } catch (error) {
      console.error('Error in transfer:', error);
      toast.error('Failed to process transfer');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-[500px]">
        {/* Modal Header */}
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-semibold">Transfer Items</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Modal Body */}
        <div className="p-4 space-y-4">
          {/* Withdraw Location Dropdown */}
          <div className="space-y-2 relative z-30">
            <label className="block text-sm font-medium text-gray-700">Withdraw Location</label>
            <CustomDropdown
              data={branches}
              value={withdrawLocation}
              onChangeOption={(option) => setWithdrawLocation(option)}
              placeholder="Select withdraw location"
              className="w-full"
              containerClassName="w-full"
              menuClassName="max-h-[200px] overflow-y-auto bg-white shadow-lg border border-gray-200 rounded-md mt-1"
              controlClassName="w-full h-10 px-3 py-2 text-sm bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#00A862] focus:border-[#00A862]"
              optionClassName="px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
            />
          </div>

          {/* Transfer Location Dropdown */}
          <div className="space-y-2 relative z-20">
            <label className="block text-sm font-medium text-gray-700">Transfer Location</label>
            <CustomDropdown
              data={branches}
              value={transferLocation}
              onChangeOption={(option) => setTransferLocation(option)}
              placeholder="Select transfer location"
              className="w-full"
              containerClassName="w-full"
              menuClassName="max-h-[200px] overflow-y-auto bg-white shadow-lg border border-gray-200 rounded-md mt-1"
              controlClassName="w-full h-10 px-3 py-2 text-sm bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#00A862] focus:border-[#00A862]"
              optionClassName="px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
            />
          </div>

          {/* Measurement Unit Dropdown */}
          <div className="space-y-2 relative z-10">
            <label className="block text-sm font-medium text-gray-700">Measurement Unit</label>
            <CustomDropdown
              data={measurementUnits.map(unit => ({
                label: unit.name,
                value: unit.id
              }))}
              value={{ 
                label: measurementUnits.find(u => u.id === selectedMeasurementUnit)?.name || "Select unit",
                value: selectedMeasurementUnit 
              }}
              onChangeOption={(option) => setSelectedMeasurementUnit(option.value)}
              placeholder="Select measurement unit"
              className="w-full"
              containerClassName="w-full"
              menuClassName="max-h-[200px] overflow-y-auto bg-white shadow-lg border border-gray-200 rounded-md mt-1"
              controlClassName="w-full h-10 px-3 py-2 text-sm bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#00A862] focus:border-[#00A862]"
              optionClassName="px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
            />
            <p className="mt-1 text-xs text-gray-500">
              Can't find a measurement unit? <Link to="/inventory-measurement" className="text-green-600 hover:underline">Click here</Link>
            </p>
          </div>

          {/* Selected Items */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Selected Items</label>
            <div className="border rounded-lg p-2 max-h-[150px] overflow-y-auto">
              {selectedItems.map((item) => (
                <div key={item.id} className="flex items-center justify-between py-1">
                  <span className="text-sm">{item.referenceNumber}</span>
                  <span className="text-sm text-gray-500">{item.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Modal Footer */}
        <div className="p-4 border-t">
          <button
            type="button"
            onClick={handleTransferClick}
            disabled={loading}
            className="w-full bg-[#00A862] text-white py-2 rounded-md hover:bg-[#008F53] transition-colors disabled:bg-gray-400"
          >
            {loading ? "Processing..." : "Transfer Items"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransferModal;