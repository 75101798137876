import React,{useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { DivShadow } from './DataTable'
import {getInventoryItemsBelowCriticalLimitThunk} from '../../../Store/Inventory'
import ExportTemplate from './Widget/ExportTemplate'

const CriticalLimit = ({branches}) => {
    const dispatch = useDispatch()


    const [isLoading, setIsLoading] = useState(false)

    const [withdrawalBranch, setWithdrawalBranch] = useState({label: 'All location', value: null})



    const criticalLimitItems = useSelector(state => state.inventory)?.criticalLimit

   

    const getCriticalLimit = (id=null) => {
        dispatch(getInventoryItemsBelowCriticalLimitThunk(id))
        .then(_ => setIsLoading(false))
    }
    useEffect(()=> {
        setIsLoading(true)
        getCriticalLimit(withdrawalBranch.value)
    },[withdrawalBranch])
    

  
  return (
    <div className='w-full'>

        <DivShadow className='max-h-[70vh]'>

            <ExportTemplate 
                title='Items Running Low'
                dropDownData={branches}
                dropDownValue={withdrawalBranch}
                onChangeDropDown={(val)=>setWithdrawalBranch(val)}

                exportData={criticalLimitItems.map(x => ({
                    'Items': x?.inventoryItems?.name,
                    'Location': x?.location?.name,
                    'Current Amount': x?.currentAmount,
                    'Critical Limit': x?.criticalLimit,
                    

                }))}
                fileName={'critical-limit'}
            />


            <div className='rounded-md border-1 dark:border-333 mt-6 min-h-[50vh] max-h-full mb-3 overflow-y-auto text-[14px]'>
            <table className="w-full border-collapse">
                <thead>
                    <tr className="border-b border-[#EAEAEA] bg-[#F1FBF2]">
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2] w-[30%]">
                            Items
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2] w-[30%]">
                            Location
                        </th>
                        <th className="px-6 py-3 text-center text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2] w-[20%]">
                            Current Amount
                        </th>
                        <th className="px-6 py-3 text-center text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2] w-[20%]">
                            Critical Limit
                        </th>
                    </tr>
                </thead>

                {!isLoading ? <tbody className="bg-white">
                    {criticalLimitItems?.length !== 0 ?
                        criticalLimitItems.map(x => (
                            <tr className="h-[52px] border-b border-[#EAEAEA] hover:bg-gray-50 transition-colors">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    {x?.inventoryItems?.name}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    <div className='flex items-center gap-2'>
                                        <div className='w-2 h-2 rounded-full' style={{background: x?.location?.hexcode}}/>
                                        <span>{x?.location?.name}</span>
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#7A7A7A] text-center">
                                    {x?.currentAmount}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#7A7A7A] text-center">
                                    {x?.criticalLimit}
                                </td>
                            </tr>
                        ))
                        : <tr>
                            <td colSpan={4} className="text-center py-4 text-[#7A7A7A]">Nothing to see here</td>
                        </tr>
                    }
                </tbody> : 
                <tbody>
                    <tr>
                        <td colSpan={4} className="text-center py-4">
                            <CircularProgress />
                        </td>
                    </tr>
                </tbody>}
            </table>

            </div>
        </DivShadow>
    </div>
  )
}

export default CriticalLimit