import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getInventoryGroupsThunk } from '../../../../../../Store/Inventory';

const GroupTable = ({ searchQuery }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const inventoryItems = useSelector((state) => state.inventory.items);
  const inventoryGroups = useSelector((state) => state.inventory.groups);

  const getItemCount = (groupId) => {
    return (inventoryItems || []).filter(item => item.groupId === groupId).length;
  };

  const filteredGroups = useMemo(() => {
    return (inventoryGroups || []).filter(group => {
      const matchesSearch = !searchQuery || 
        group.name?.toLowerCase().includes(searchQuery.toLowerCase());
      
      return matchesSearch;
    })
  }, [inventoryGroups, searchQuery]);

  useEffect(()=>{
    dispatch(getInventoryGroupsThunk())
  },[])

  return (
    <div className="relative w-[74vw] border border-[#EAEAEA]">
      <div className="max-h-[60vh] overflow-y-auto">
        <table className="w-full text-left">
          <thead>
            <tr className="bg-[#F1FBF2]">
              <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
                Name of Group
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
                Number of Items
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
                Date Created
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredGroups.map((group) => (
              <tr key={group.id} className="border-b border-[#EAEAEA] hover:bg-gray-50">
                <td 
                  className="px-6 py-4 whitespace-nowrap text-sm text-[#404040] cursor-pointer"
                  onClick={() => navigate(`/inventory-items-group/${group.id}`)}
                >
                  {group.name || '-'}
                </td>
                <td 
                  className="px-6 py-4 whitespace-nowrap text-sm text-[#7A7A7A] cursor-pointer"
                  onClick={() => navigate(`/inventory-items-group/${group.id}`)}
                >
                  {getItemCount(group.id)}
                </td>
                <td 
                  className="px-6 py-4 whitespace-nowrap text-sm text-[#7A7A7A] cursor-pointer"
                  onClick={() => navigate(`/inventory-items-group/${group.id}`)}
                >
                  {moment(group.createdAt).format('MMM DD, YYYY') || '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                  <button className="text-blue-600 hover:text-blue-800">
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GroupTable;