import React, {useEffect, useState} from 'react'
import { Navbar } from '../../Widgets/Navbar'

import { Loader } from '../../Widgets/Loader';
import './VerifyEmailAddress.css'
import { Service } from '../../../Network/ServiceClass/Auth';
import { useSearchParams, useNavigate } from 'react-router-dom';

import Synclogo from "../../../Assets/sync_light.svg";
import SyncdarkLogo from "../../../Assets/sync_logo.svg";
import { toast } from "react-toastify";


export const VerifyEmailAddress = () => {

  const Auth = new Service(); 
  const navigate = useNavigate();
  const mode = localStorage.getItem('color-theme') ?? 'light'

  const [searchParams, setSearchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const [isVerifying, setIsVerifying] = useState(true);

  useEffect(()=>{
    setIsVerifying(true);
    Auth.confirmMail(searchParams.get('hash')).then((value)=>{
      console.log(value.data);
      toast.success("Email verified successfully! Redirecting to login page...");
      setIsVerifying(false);
      
      // Add a timeout before redirecting
      setTimeout(() => {
        navigate("/");
      }, 3000); // 3 seconds delay
    }).catch((value)=>{
      console.log(value.response.data.message);
      setMessage(value.response.data.message);
      toast.error(value.response.data.message);
      setIsVerifying(false);
    });
      
  }, []);

  return (
    <div>
        <Navbar />
        <section className='verify-email-body'>
          <div className='the-card'>
            <img src={mode === 'light' ? Synclogo : SyncdarkLogo} alt="logo" style={{marginBottom:"20px"}}/>
            <p>Your email is being verified</p>
            {isVerifying ? <Loader /> : (
              message === "" ? 
                <p style={{color:"green", fontSize:"14px"}}>Email verified successfully!</p> : 
                <p style={{color:"red", fontSize:"14px"}}>{message}</p>
            )}
          </div>
        </section>
    </div>
  )
}
