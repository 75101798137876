import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Branch } from '../../../../../../Network/ServiceClass/Branch';
import { Job } from '../../../../../../Network/ServiceClass/Job';
import {Text, Field, TextAndField, FillButton, FieldSelect, OutlinedButton} from '../../../../../Widgets/FormUtils/FormUtils'
import { CircularProgress, Stack, Typography } from '@mui/material';
import {MdOutlineCancel} from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux';
import {  fetchJobRolesAsync } from '../../../../../../Store/Branches';
import {font} from '../../../../../Widgets/Styles/font'
import FormModal from '../../../../../Widgets/Animation/FormModal';
import { toast } from 'react-toastify';

function TeamMemberModal({ open, handleClose, callback, branchId }) {

    const branch = new Branch();
    const job = new Job();
    const dispatch = useDispatch();

    const [jobroles, setJobRoles] = useState([]);
    const [branches, setBranches] = useState([]);
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isloading, setIsLoading] = useState(false)

    // Ensure we load job roles from Redux store to keep the format consistent
    const jobRolesFromStore = useSelector(state => state.branches?.jobRoles || []);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            privilege: 1,
            jobRoleId: "",
            assignedBranchId: branchId || ""
        },
        enableReinitialize: branchId ? true : false,
        validationSchema: Yup.object({
            name: Yup.string().min(2, "Team member name is too short")
                .required('Please enter team member name'),
            email: Yup.string()
                .required('Please email address'),

            privilege: Yup.number()
            .required('Required'),  
            jobRoleId: Yup.string()
                .required('Required'),  
            assignedBranchId: Yup.string()
            .required('Required'),    
        }),
        onSubmit: (value) => {
            
            console.log(value);
            setIsLoading(true);

            // Find the selected job role to get its slug
            const selectedJobRole = jobRolesFromStore.find(role => role.id === value.jobRoleId);
            
            // Replace jobRoleId with slug for the API call
            const dataToSend = {
                ...value,
                jobRoleId: selectedJobRole?.symbol
            };

            branch.inviteTeamMember(dataToSend).then((value) => {
                console.log(value); 
                setIsLoading(false); 
                toast('Your Invite has been sent successfully')
                handleClose(); 
                callback(); 
            }).catch((err) => {
                console.log(err); 
                setIsLoading(false); 
                setErrorMessage(err.response.data.message)
                setIsError(true) 
            });

        }
    })

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '100vh',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
        overflowY:'auto',
        overflowX:'hidden'
      };


    useEffect(() => {
        // Use Redux-managed job roles for consistency with bulk invite
        dispatch(fetchJobRolesAsync()).then((res) => {
            if (res?.payload?.data) {
                // Transform the job roles to match the format expected by FieldSelect
                setJobRoles(res.payload.data.map(val => {
                    return {id: val.id, name: val.title}
                }));
            }
        });

        branch.getBranches().then((value) => {
            setBranches(value.data);
        }).catch((err) => {
            console.log(err);
        });


    }, [dispatch])

    return (
        
        <FormModal open={open} handleClose={handleClose}>
            <Stack  className='dark:bg-138 dark:text-white' direction='column' alignItems='center' sx={style} gap={3}>
                <Typography sx={{...font, fontSize: '18px', fontWeight: '500'}}>Add new team member</Typography>
                
                <TextAndField>
                    <Text text={'Name'}/>
                    <Field text={'Kindly enter member name'} 
                        value={formik.values.name} 
                        formik={formik} 
                        name='name'/>
                </TextAndField>

                <TextAndField>
                    <Text text={'Email'}/>
                    <Field text={'Kindly enter member email'} 
                        value={formik.values.email} 
                        formik={formik} 
                        name='email'/>
                </TextAndField>

                <TextAndField>
                    <Text text={'Level'}/>
                    <FieldSelect  list={[{id: 1, name: 'Super Admin'}, {id: 2, name: 'Admin'}, {id: 3, name: 'Member'}]}
                        value={formik.values.privilege} 
                        formik={formik} name='privilege'
                        />
                </TextAndField>

                <TextAndField>
                    <Text text={'Job role'}/>
                    <FieldSelect  list={jobroles}
                        value={formik.values.jobRoleId} 
                        formik={formik} name='jobRoleId'
                        />
                </TextAndField>

                <TextAndField>
                    <Text text={'Assign location'}/>
                    <FieldSelect  list={branches}
                        value={formik.values.assignedBranchId} 
                        formik={formik} name='assignedBranchId'
                        />
                </TextAndField>

                {isError && 
                <Box sx={{
                    display: 'flex',
                    borderRadius: '0.2rem',
                    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                    padding: '0.5rem'
                }}>
                    <Typography sx={{...font,fontSize:'12px',color: 'red', textAlign:'center' }}>{errorMessage}</Typography>
                    <MdOutlineCancel style={{color: 'red', fontSize:'23px', cursor:'pointer'}} onClick={()=>setIsError(false)}/>
                </Box>}

                <Stack direction='row' width='100%' justifyContent='space-between' mt={6}>
                    <OutlinedButton text='Cancel' callBack={()=>{formik.resetForm(); handleClose()}}/>
                    {!isloading && <FillButton text='Submit' callBack={formik.handleSubmit}/>  }
                    
                    {isloading && <CircularProgress/> }
                </Stack>
            </Stack>
        </FormModal>
    )
}

export default TeamMemberModal