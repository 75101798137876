import React,{useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CustomPopper } from '../../Widgets/CustomPopper'
import { ClickableItem } from '../../Widgets/ClickableItem'
import { CircularProgress } from '@mui/material'
import { DivShadow } from './DataTable'
import {getInventoryPendingOrdersThunk } from '../../../Store/Inventory'
import { Storage } from '../../../Network/StorageClass/StorageClass'
import { toast } from 'react-toastify'
import CurrencyFormat from 'react-currency-format'
import { approveOrderRequest  } from '../../../Network/ServiceClass/InventoryService'
import ExportTemplate from './Widget/ExportTemplate'

const storage = new Storage()
const PendingOrder = ({statusColor, branches, formatStatus}) => {
    const dispatch = useDispatch()

    const isAllow = () => {
        if ([1,2].includes(storage.getActiveCompany()?.memberships[0]?.privilege)) return true
        else return false
    }

    const teamMembershipId = storage.getActiveCompany()?.memberships[0]?.id

    const [toggleMenu, setToggleMenu] = useState(null)
    const [isLoadingAction, setIsLoadingAction] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [withdrawalBranch, setWithdrawalBranch] = useState({label: 'All location', value: null})
    const [menuIndex, setMenuIndex] = useState(null)


    const pendingOrders = useSelector(state => state.inventory)?.pendingOrders
   

    const getPendingOrder = (id=null) => {
        dispatch(getInventoryPendingOrdersThunk(id))
        .then(_ => setIsLoading(false))
    }
    useEffect(()=> {
        setIsLoading(true)
        getPendingOrder(withdrawalBranch.value)
    },[withdrawalBranch])
    

    const handleApprove = (value) => {
        setIsLoadingAction(true)

        approveOrderRequest({id: value?.orderIdentifier, status:'ordered'})
        .then(val => {
            if (val?.status === 200){
                toast.success('Approved Successfully')
                setToggleMenu(null)
                getPendingOrder(withdrawalBranch.value)
            }else toast.error(val?.data?.message)
            setIsLoadingAction(false)
        })
    }
  return (
    <div>

        <DivShadow className='max-h-[70vh]'>

            <ExportTemplate 
                title='Pending Order Request'
                dropDownData={branches}
                dropDownValue={withdrawalBranch}
                onChangeDropDown={(val)=>setWithdrawalBranch(val)}

                exportData={pendingOrders.map(x => ({
                    'Reference Number': x?.referenceNumber,
                    'Total Value': `$${x?.items.length === 0 ? 0 : x?.items.reduce((accumulator, currentValue) => accumulator + currentValue.packageCost, 0)/100}`,
                    'Ordered By': `${x?.teamMember?.firstName} ${x?.teamMember?.lastName}`,
                    'Location': x?.branch?.name,
                    'Number Of Items': x?.items?.length,
                    'Unit': x?.UnitName,
                    'Status': x?.status

                }))}
                fileName={'pending-orders'}
            />


            <div className='rounded-md border-1 dark:border-333 dark:text-white mt-6 min-h-[50vh] max-h-full mb-3 overflow-y-auto text-[14px]'>
            <table className="w-full border-collapse">
                <thead>
                    <tr className="border-b border-[#EAEAEA] bg-[#F1FBF2]">
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Ref No.
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Total Value
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Ordered By
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Location
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            No. of items
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Unit
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider h-[52px] whitespace-nowrap sticky top-0 bg-[#F1FBF2]">
                            Status
                        </th>
                    </tr>
                </thead>

                {!isLoading ? <tbody className="bg-white">
                    {pendingOrders?.length !== 0 ?
                        pendingOrders.map(val => (
                            <tr className="h-[52px] border-b border-[#EAEAEA] hover:bg-gray-50 transition-colors">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    {val?.referenceNumber}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    <CurrencyFormat
                                        className='text-[#404040]'
                                        value={val?.items.length === 0 ? 0 : val?.items.reduce((accumulator, currentValue) => accumulator + currentValue.packageCost, 0)/100}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        decimalScale={2}
                                    />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    {val?.teamMember?.firstName} {val?.teamMember?.lastName}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    <div className='flex items-center gap-2'>
                                        <div className='w-2 h-2 rounded-full' style={{background: val?.branch?.hexcode}}/>
                                        <span>{val?.branch?.name}</span>
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    {val?.items?.length}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-[#404040]">
                                    {val?.UnitName}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm">
                                    {isAllow() ? 
                                        <div 
                                            onClick={(e)=> {
                                                e.stopPropagation()
                                                !isLoadingAction && setToggleMenu(toggleMenu ? null : e.currentTarget)
                                                !isLoadingAction && setMenuIndex(val?.id)
                                            }}
                                            className={`cursor-pointer ${statusColor[val?.status]?.color}`}>
                                            {formatStatus(val?.status)}
                                        </div> :
                                        <div className={`${statusColor[val?.status]?.color}`}>
                                            {formatStatus(val?.status)}
                                        </div>
                                    }

                                    {menuIndex === val?.id &&
                                        <CustomPopper 
                                            openPopper={toggleMenu} 
                                            handleClose={()=> {
                                                !isLoadingAction && setToggleMenu(null)
                                            }} 
                                            className='z-[1500]' 
                                            placement='bottom'
                                        >
                                            <div className='min-h-[50px] min-w-[150px] mt-1 py-3 font-bold bg-white shadow-shift-shadow-small'>
                                                {!isLoadingAction ? 
                                                    <div className='flex flex-col gap-2 text-[#738583] text-[12px]'>
                                                        <ClickableItem 
                                                            className={'py-1 text-[black]'} 
                                                            text={'Approve Request'} 
                                                            handleClick={()=> handleApprove(val)}
                                                        />
                                                    </div>
                                                    :
                                                    <div className="flex h-full items-center justify-center">
                                                        <CircularProgress/>
                                                    </div>
                                                }
                                            </div>          
                                        </CustomPopper>
                                    }
                                </td>
                            </tr>
                        ))
                        : <tr>
                            <td colSpan={7} className="text-center py-4 text-[#7A7A7A]">Nothing to see here</td>
                        </tr>
                    }
                </tbody> : 
                <tbody>
                    <tr>
                        <td colSpan={7} className="text-center py-4">
                            <CircularProgress />
                        </td>
                    </tr>
                </tbody>}
            </table>

            </div>
        </DivShadow>
    </div>
  )
}

export default PendingOrder