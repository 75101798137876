import React, { useEffect } from 'react'
import FormModal from '../../../../Widgets/Animation/FormModal';
import { Stack, CircularProgress, InputAdornment, FormControlLabel, Switch, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import * as Yup from 'yup'
import {
  Field,
  FieldSelect,
  FillButton,
  OutlinedButton,
  Text,
  TextAndField,
} from '../../../../Widgets/FormUtils/FormUtils';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getInventoryGroupsThunk, getInventoryManufacturersThunk, createInventoryItemThunk, getAllItemsThunk } from '../../../../../Store/Inventory';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '485px',
  maxHeight: '100vh',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '5px',
  overflowY: 'auto',
  overflowX: 'hidden',
};

function CreateInventoryModal({
  openInventoryModal,
  handleCloseInventoryModal,
}) {
  const dispatch = useDispatch()
  const { manufacturers, groups, loading } = useSelector((state) => state.inventory)
  const formik = useFormik({
    initialValues: {
      name: "",
      packageCost: 0,
      description: "",
      groupId: "",
      manufacturerId: "",
      referenceNumber: "",
      expires: false,
      expiryNotification: 30
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("This is required"),
      description: Yup.string().required("This is required"),
      groupId: Yup.string().required("This is required"),
      manufacturerId: Yup.string().required("This is required"),
      packageCost: Yup.number().min(1).required("This is required"),
      expires: Yup.boolean(),
      expiryNotification: Yup.number().when('expires', {
        is: true,
        then: () => Yup.number().required("Notification period is required when item expires")
      })
    }),
    onSubmit: async (values) => {
      try {
        const submitData = { ...values };
        if (!submitData.referenceNumber?.length) {
          delete submitData.referenceNumber;
        }

        // Ensure packageCost is a number
        submitData.packageCost = Number(submitData.packageCost);

        // Only include expiryNotification if expires is true
        if (!submitData.expires) {
          delete submitData.expiryNotification;
        }

        const result = await dispatch(createInventoryItemThunk(submitData));
        
        // Check the action type to confirm success
        if (createInventoryItemThunk.fulfilled.match(result)) {
          toast.success('Inventory item created successfully');
          await dispatch(getAllItemsThunk());
          
          setTimeout(() => {
            formik.resetForm();
            handleCloseInventoryModal();
          }, 300);
        }
        
      } catch (error) {
        console.error('Error creating inventory item:', error);
        toast.error(error?.message || 'Failed to create inventory item');
      }
    }
  });

  useEffect(()=>{
    dispatch(getInventoryGroupsThunk())
    dispatch(getInventoryManufacturersThunk())
  },[])

  const handleExpiresChange = (event) => {
    formik.setFieldValue('expires', event.target.checked);
  };

  const handleExpiryNotificationChange = (event) => {
    formik.setFieldValue('expiryNotification', event.target.value);
  };

  return (
    <FormModal
      open={openInventoryModal}
      handleClose={handleCloseInventoryModal}
    >
      <Stack className='dark:bg-138 dark:text-white' direction="column" alignItems="center" sx={style} gap={2}>
        <div className="w-full flex justify-between items-center">
          <p className="text-2xl text-color-424 dark:text-white font-bold">
            Create Item
          </p>
          <CloseIcon 
            className="cursor-pointer text-gray-500 hover:text-gray-700"
            onClick={() => {
              formik.resetForm();
              handleCloseInventoryModal();
            }}
          />
        </div>
        <TextAndField>
          <Text text="Name" />
          <Field
            value={formik.values.name}
            formik={formik}
            name="name"
          />
        </TextAndField>
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          gap={4}
        >
          <TextAndField space={1}>
          <Text text={'Group'} />
          <FieldSelect
            list={groups?.map((x) => ({ name: x?.name, id: x?.id }))}
            value={formik.values.groupId}
            formik={formik}
            name="groupId"
            width="100%"
          />
        </TextAndField>

        <TextAndField>
          <Text text="Package Cost" />
          <Field
            value={formik.values.packageCost || ''}
            formik={formik}
            name="packageCost"
            width="100%"
            placeholder="0.00"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </TextAndField>
        </Stack>

        <TextAndField space={1}>
          <Text text={'Manufacturer'} />
          <FieldSelect
            list={manufacturers?.map((x) => ({ name: x?.name, id: x?.id }))}
            value={formik.values.manufacturerId}
            formik={formik}
            name="manufacturerId"
            width="100%"
          />
           <p className="mt-1 text-xs text-gray-500">
            Can't find a manufacturer? <Link to="/manufacturers-page" className="text-green-600 hover:underline">Click here</Link>
          </p>
        </TextAndField>

        <TextAndField>
          <Text text="Description" />
          <Field
            value={formik.values.description}
            formik={formik}
            name="description"
          />
        </TextAndField>

        <TextAndField>
          <Text text="Reference Number" />
          <Field
            value={formik.values.referenceNumber}
            formik={formik}
            name="referenceNumber"
          />
        </TextAndField>

        <div className="w-full mt-2">
          <FormControlLabel
            control={
              <Switch
                checked={formik.values.expires}
                onChange={handleExpiresChange}
                name="expires"
                color="primary"
              />
            }
            label="Item Expires"
          />
        </div>

        {formik.values.expires && (
          <TextAndField>
            <Text text="Notify Before Expiry" />
            <FormControl fullWidth>
              <Select
                value={formik.values.expiryNotification}
                onChange={handleExpiryNotificationChange}
                displayEmpty
                className="bg-white dark:bg-gray-800 dark:text-white"
              >
                <MenuItem value={30}>30 days</MenuItem>
                <MenuItem value={60}>60 days</MenuItem>
                <MenuItem value={90}>90 days</MenuItem>
              </Select>
            </FormControl>
          </TextAndField>
        )}

        <Stack
          direction="row"
          width="100%"
          justifyContent="center"
          mt={6}
        >
          {!loading && (
            <FillButton
              width="100%"
              text="Submit"
              callBack={formik.handleSubmit}
            />
          )}

          {loading && <CircularProgress />}
        </Stack>

      </Stack>
    </FormModal>
  )
}

export default CreateInventoryModal
