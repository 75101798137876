import {useState, useEffect, useCallback} from 'react'
import AppLayout from '../../Widgets/AppLayout'
import PieChart from '../../Widgets/PieChart'
import {DivShadow } from './DataTable'
import { useDispatch, useSelector } from 'react-redux'
import { 
  getInventoryGroupsThunk,
} from '../../../Store/Inventory'
import { Branch } from '../../../Network/ServiceClass/Branch'
import dayjs from 'dayjs'
import { CircularProgress } from '@mui/material'
import { statusColor } from '../../../utils/inventoryStatus'
import PendingTransfer from './PendingTransfer'
import PendingOrder from './PendingOrder'
import Withdrawals from './Withdrawals'
import Usage from './Usage'
import InventoryLog from './InventoryLog'
import CriticalLimit from './CriticalLimit'
import Expiring from './Expiring'
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel'
import { inventoryDataTutorial } from '../mock'
import { BsDatabase } from 'react-icons/bs'
import { HiDatabase } from 'react-icons/hi'
import { ReactComponent as DataIcon } from '../../../Assets/Bigdata.svg'

dayjs.locale('en');

const config = {
  appendPadding: 10,
  angleField: 'value',
  colorField: 'type',
  legend: {
      position: 'bottom', // Display legend below the chart
    },
  radius: 0.9,
  label: {
    type: 'inner',
    offset: '-30%',
    content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
    style: {
      fontSize: 14,
      textAlign: 'center',
    },
  },
  color: ["#FFB1B7", "#775DA6", "#70B6C1"],
  interactions: [
    {
      type: 'element-active',
    },
  ],
};

const branchService = new Branch()  
const InventoryData = () => {

  const dispatch = useDispatch()

  const formatStatus = useCallback((status)=>{
    if (status === 'received') return 'Received'
    else if (status === 'partial') return 'Partial'
    else if (status === 'ordered') return 'Ordered'
    else if (status === 'pending_approval') return 'Pending'
    else return 'None'
  }, []) 

  const [branches, setBranches] = useState([])



  const groups = useSelector(state => state.inventory)?.groups.map(x => ({type: x?.name, value: x?.numberOfItems
  }))
  const groupsIsLoading = useSelector(state => state.inventory)?.loading

  useEffect(()=>{
    dispatch(getInventoryGroupsThunk())
  },[])



  useEffect(()=> {
    branchService.getBranches()
    .then(val => {
      setBranches([{label: 'All locations', value:null},...val?.data.map(x => ({label: x.name, value: x.id, hexcode: x?.hexcode}))])
    })
  },[])

  return (
    <div>

          <AppLayout
            custom="inventory"
            subCustom="inventory-data"
            title="Inventory Data"
            customTitle=""
            subtitle=""
            showAppBar={false}
            hideLiveUsers={true}
           
        >

          <div className="absolute top-[15px] left-[15px] w-[calc(100%-15px)] h-[95vh] overflow-y-auto border border-[#EAEAEA] rounded-[12px] flex flex-col bg-white">
                <div className="flex flex-rowitems-center gap-2 pt-4 pl-4">
                <DataIcon />
                  <p className="font-[Plus Jakarta Sans] font-medium text-[12px] text-[#7a7a7a] leading-[15px]">
                    Inventory Data
                  </p>
                </div>

                <div className="flex flex-col gap-0 pl-4 pt-4">
                    <h1 className="font-[Plus Jakarta Sans] font-semibold text-[30px]">
                      Inventory Data
                    </h1>
                    <p className="font-[Plus Jakarta Sans] font-normal text-[14px] leading-[22px] text-[#606060]">
                      View all data under inventory
                    </p>
                  </div>

              
            
            <div className='flex flex-col gap-5 mb-12 pt-6'>
                <div className='flex gap-5 max-h-[70vh]'>

                  <div className='w-[50%]'>
                    <CriticalLimit branches={branches}/>

                  </div>


                    <DivShadow  className='!w-[50%]'>
                        <p className='text-[16px] font-semibold dark:text-white'>Group Distribution</p>
                        {!groupsIsLoading ? <PieChart data={groups} config={config}/> 
                          :
                        <div className='flex justify-center mt-10'><CircularProgress/></div>
                        }
                    </DivShadow>
                </div>


                <Expiring branches={branches}/>
                
                <InventoryLog branches={branches}/>

                <PendingOrder statusColor={statusColor} branches={branches} formatStatus={formatStatus}/>

                <PendingTransfer statusColor={statusColor} branches={branches} />

                <Withdrawals statusColor={statusColor} branches={branches}/>

                <Usage statusColor={statusColor} branches={branches}/>
              

               


            </div>
          </div>
        </AppLayout>
    </div>
  )
}

export default InventoryData

