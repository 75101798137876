import { BsPlus, BsSearch } from 'react-icons/bs'
import AppLayout from '../../Widgets/AppLayout'
import ButtonWithIcon from '../../Widgets/ButtonWithIcon'
import { useState } from 'react'
import CustomDropdown from '../../Widgets/CustomDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getATeammemberAsync, getTeammemberAsync } from '../../../Store/Branches'
import CreateTicketModal from './components/CreateTicketModal'
import { createTicketThunk, getAllTicketThunk } from '../../../Store/Ticket'
import dayjs from 'dayjs'
import { Loader } from '../../Widgets/Loader'
import { toast } from 'react-toastify'
import SearchInput from '../../Widgets/SearchInput'
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel'
import { ticketTutorial } from '../mock'
import { CustomTourModal } from '../../Widgets/CustomTourModal/CustomTourModal'
import Tour from 'reactour'
import { ticketCustomizeHelper, ticketTourGuide } from './ticketTour'
import CustomSearchInput from '../../Widgets/CustomSearch'

const statuses = {
  0: "Not Addressed",
  1: "In Progress",
  2: "QA",
  3: "Resolved",
}

export const SelectPriority = (status) => {
  switch (status) {
    case 'urgent':
      return <div className="flex items-center gap-2 justify-start"><div className="w-[11px] rounded-full h-[11px] bg-C0C" /> <p>Urgent</p></div>
    case 'important':
      return <div className="flex items-center gap-2 justify-start"><div className="w-[11px] rounded-full h-[11px] bg-30C" /> <p>Important</p></div>
    case 'todo':
      return <div className="flex items-center gap-2 justify-start"><div className="w-[11px] rounded-full h-[11px] bg-2AF" /> <p>Todo</p></div>
    default:
      return <p>N/A</p>
  }
}

function TroubleTicket() {
  const dispatch = useDispatch()
  const [search, searchTicket] = useState('')
  const [isModalOpen, openModal] = useState(false)
  const [dateFilter, filterByDate] = useState(false)
  const teamMembers = useSelector((state) => state?.branches)?.teamMembers;
  const tickets = useSelector((state) => state?.ticket)?.tickets;
  const loading = useSelector((state) => state?.ticket)?.loading;
  const privilege =
  useSelector((state) => state?.company)?.privilege || 3;  

  const [isTourOpen, setOpenTour] = useState(false)
  const [isTourModalOpen, setModalOpen] = useState(false)

  const [selectedUser, selectUser] = useState({
    label: 'Assigned To',
    value: '',
  });
  const [selectedStatus, selectStatus] = useState({
    label: 'Status',
    value: '',
  });
  const [selectedPriority, selectPriority] = useState({
    label: 'Priority',
    value: '',
  });

  useEffect(() => {
    const user = localStorage.getItem("user")

    dispatch(getTeammemberAsync({}));
    dispatch(getAllTicketThunk({
      priority: selectedPriority.value,
      status: selectedStatus.value,
      assignedToId: selectedUser.value,
    })).then((res) => {
    })
    if (user) {
      const parseUser = JSON.parse(user)
      
    if(parseUser?.new_user_ticket === 0){
      setModalOpen(true);
    }
      dispatch(getATeammemberAsync(parseUser?.id))
    }
  }, [dispatch, selectedPriority, selectedStatus, selectedUser])

  const handleOpenModal = () => {
    openModal(true)
  }

  const handleCloseModal = () => {
    openModal(false)
  }

  const handleDateClick = () => {
    filterByDate((prev) => !prev)
  }

  const handleOnChange = (e) => {
    searchTicket(e.target.value)
  }

  const handleSelectUser = (e) => {
    selectUser(e)
  }

  const handleStatus = (e) => {
    selectStatus(e)
  }

  const handleSelectPriority = (e) => {
    selectPriority(e)
  }

  const handleSubmit = (val, formik) => {

    dispatch(createTicketThunk(val)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        dispatch(getAllTicketThunk({
          priority: selectedPriority.value,
          status: selectedStatus.value,
          assignedToId: selectedUser.value,
        })).then(() => {
          toast.success("A ticket was created")
          formik.resetForm()
          handleCloseModal()
        })
      } else {
        toast.error(res?.error?.message)
      }
    })
  }

  const rowCell = "dark:text-white border text-left pl-3 border-6E6 dark:border-333"

  const handleTour = () => {
    setOpenTour(true)
    setModalOpen(false)
  }
  
  
  const handleCloseTour = () => {
    setOpenTour(false)
    setModalOpen(false)
  }

  return (
    <section>
      <AppLayout
        custom="company"
        subCustom="ticket-management"
        title="Ticket Management"
        customTitle=" "
        subtitle="Create tickets, assign tickets to team members"
        RightComponent={
        <div className="flex items-center gap-3">
          {/* <TutorialPanel items={ticketTutorial} />     */}
          <button data-tour="step1" onClick={handleOpenModal} className="p-3 rounded bg-5EB text-black flex items-center "> <BsPlus size={23} /> <span className="w-max">Create a ticket</span></button>
          {/* <ButtonWithIcon handleClick={handleOpenModal} title="New" type="text" /> */}
        </div>
      }
      >

        {/* Search and Filter section */}
          

        <div  data-tour="step2" className='mt-[69px] flex items-center justify-between'>

        <div className="w-1/2">
            <CustomSearchInput
             
              placeholder='Search in tickets'
              onChange={handleOnChange}
            />
          </div>

          <div className="flex items-center gap-5">
            <p className="dark:text-white">Filter by</p>
            
            {/* Status dropdown */}
            <CustomDropdown
              data={[
                { label: 'All Status', value: '' },
                { label: "Not Addressed", value: 0 },
                { label: "In Progress", value: 1 },
                { label: "QA", value: 2 },
                { label: "Resolved", value: 3 },
              ]}
              showIcon={false}
              className="rounded h-[43px]"
              mainContainerStyle='w-[150px]'
              value={selectedStatus}
              dropdownTitleClass="text-A5A"
              optionClass="z-[1000] h-[200px] overflow-scroll"
              onChangeOption={handleStatus}
            />

            {/* Priority dropdown */}
            <CustomDropdown
              data={[
                { label: 'All Priority', value: '',},
                { label: 'Important', value: 'important', hexcode: '#D20C0C'  },
                { label: 'Todo', value: 'todo', hexcode: '#D2B30C'  },
                { label: 'Urgent', value: 'urgent', hexcode: '#0CD2AF' },
              ]}
              showIcon={false}
              className="w-[120px] rounded h-[43px]"
              value={selectedPriority}
              dropdownTitleClass="text-A5A"
              optionClass="z-[1000] h-[150px] overflow-scroll"
              onChangeOption={handleSelectPriority}
            />

            {/* User dropdown */}
            <CustomDropdown
              data={[
                { label: 'Everyone', value: '' },
                ...teamMembers?.map((team) => ({
                  label: `${team?.user?.firstName} ${team?.user?.lastName}`,
                  value: team?.user?.id,
                })),
              ]}
              showIcon={false}
              className="w-[100%] rounded h-[43px] dark:bg-138"
              mainContainerStyle='w-[150px]'
              value={selectedUser}
              dropdownTitleClass="text-A5A"
              optionClass="z-[1000] h-[200px] overflow-scroll"
              onChangeOption={handleSelectUser}
            />
          </div>
        </div>

        {/* Ticket Management Table */}

        <section className="mt-[46px] mb-48">
          <table className="text-center">
            <thead>
              <tr className="h-[68px] bg-BF2 text-color-424 font-medium text-base dark:bg-1F1">
                <th className={`rounded-tl-[8px] w-[72px] ${rowCell}`}></th>
                <th className={rowCell}>Title</th>
                <th className={rowCell}>Priority</th>
                <th className={rowCell}>Date</th>
                <th className={rowCell}>Status</th>
                <th className={`rounded-tr-[8px] ${rowCell}`}>Assigned To</th>
              </tr>
            </thead>
            <tbody>
              {
                loading && <tr className="h-[300px]"><td colSpan={6}><Loader /></td></tr>
              }
              {
                !loading && tickets?.filter((x) => x?.details?.toLowerCase()?.includes(search?.toLowerCase()))?.filter((y) => {
                  const user = localStorage.getItem("user");
                  const parseUser = JSON.parse(user)
                  if(![1,2].includes(privilege)){
                    return ((y?.createdById === parseUser.id) || (y?.assignedToId === parseUser.id));
                  }
                  
                  return true
                })?.map((ticket, index) => (
                  <tr className="text-sm">
                    <td className={`text-center py-[14px] ${rowCell}`}><p>{index + 1}</p></td>
                    <td className={`${rowCell} text-left pl-5`}><p>{ticket?.details || "N/A"}</p></td>
                    <td className={rowCell}>{SelectPriority(ticket?.priority || 'important')}</td>
                    <td className={rowCell}><p>{dayjs(ticket?.createdOn).format("DD MMMM, YYYY")}</p></td>
                    <td className={rowCell}><p>{statuses[ticket?.status]}</p></td>
                    <td className={rowCell}><p>{teamMembers?.map((x) => ({ ...x, teamId: x?.id, name: `${x?.user?.firstName} ${x?.user?.lastName}` }))?.find((user) => user?.teamId === ticket?.assignedToId)?.name || "N/A"}</p></td>
                  </tr>
                ))
              }
              {
                !loading && tickets?.filter((x) => x?.details?.toLowerCase()?.includes(search?.toLowerCase()))?.filter((y) => {
                  const user = localStorage.getItem("user");
                  const parseUser = JSON.parse(user)
                  if(![1,2].includes(privilege)){
                    return y?.createdById === parseUser.id;
                  }
                  return true
                })?.length === 0 && <tr className="h-[300px]"><td colSpan={6} className="text-center dark:text-white"><p>No ticket available</p></td></tr>
              }
            </tbody>

          </table>
        </section>

        {/* Create Ticket Modal */}
        {
          isModalOpen && <CreateTicketModal isLoading={loading} handleSubmit={handleSubmit} open={isModalOpen} handleClose={handleCloseModal} />
        }

{ isTourModalOpen && (<CustomTourModal
      handleClose={handleCloseTour}
      handleTour={handleTour}
      open={isTourModalOpen}
      title='Welcome to Ticket Management'
      descr='This guide will walk you through creating new tickets, seeing the status of tickets  and ticket management.'
      />)}

      <Tour 
      isOpen={isTourOpen}
      steps={ticketTourGuide}
      onRequestClose={handleCloseTour}
      disableDotsNavigation
      closeWithMask={false}
      showNumber={false}
      startAt={0}
      showButtons={false}
      CustomHelper={({...props}) => <div>{ticketCustomizeHelper[props.current]({...props,handleCloseTour})}  </div>}
      />

      </AppLayout>
    </section>
  )
}

export default TroubleTicket