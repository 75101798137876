import GlobalButton from "../../../Widgets/GlobalButton"
import {PiMicrosoftExcelLogoLight} from 'react-icons/pi'
import CustomDropdown from "../../../Widgets/CustomDropdown"
import { CSVLink } from 'react-csv';
import { HiMapPin } from "react-icons/hi2";



const ExportTemplate = ({
    exportData,
    fileName,
    title='',
    dropDownData,
    dropDownValue,
    onChangeDropDown=()=>{}
}) => {
  return (

    <div className='flex justify-between items-center '>
        <p className='text-[16px] font-bold dark:text-white'>{title}</p>
        <div className="flex gap-3 items-center">

            <CSVLink data={exportData} filename={`${fileName}.csv`}>
                <GlobalButton 
                    className="h-10 rounded-[24px] [&_span]:!text-white"
                    title='Export' 
                    onClick={()=>{}} 
                    Icon={PiMicrosoftExcelLogoLight} 
                    showIcon
                    disabled={exportData.length === 0 && true}
                />
            </CSVLink>
            


            <CustomDropdown
                trimtext
                trimLength={12}
                data={dropDownData}
                className="w-36 h-10 !rounded-[24px] dark:!border-A1A hover:dark:!border-gray-500"
                dropdownTitleClass="text-A5A dark:text-white"
                optionClass="z-[1000] max-h-[200px] overflow-y-scroll"
                value={{
                    label: (
                        <div className="flex items-center gap-2">
                            <HiMapPin className="text-gray-600" />
                            <span>{dropDownValue?.label}</span>
                        </div>
                    ),
                    value: dropDownValue?.value
                }}
                onChangeOption={onChangeDropDown}
            />
        </div>
    </div>
  )
}

export default ExportTemplate