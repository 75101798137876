import {useState, useRef} from 'react'

import CustomModal from '../../../Widgets/CustomModal'
import {BsUpload} from 'react-icons/bs'
import {FiImage} from 'react-icons/fi'
import {MdOutlineDeleteOutline} from 'react-icons/md'
import { uploadAnnouncementFile } from '../../../../Network/ServiceClass/announcementService'
import { CircularProgress } from '@mui/material'
import {BsFilePdf} from 'react-icons/bs'
import { toast } from 'react-toastify'
import trimText from '../../../../utils/trimText'
import { inventoryOrderAttachment } from '../../../../Network/ServiceClass/InventoryService'


const FileUploadUi = ({openAttachModal, handleCloseAttachModal, data, callback}) => {

    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef(null);
    const [uploadFiles, setUpLoadFiles] = useState([])
    const [isUploadingFile,setIsUploadingFile] = useState(false)
    const [isLoading,setIsLoading] = useState(false)

    const makeUpload = (file) => {
        const uploadPromises = [];
      
        for (let i = 0; i < file.length; i++) {
          const formdata = new FormData();
          formdata.append('file', file[i]);
      
          const uploadPromise = uploadAnnouncementFile(formdata)
            .then((val) => {
              if (val.status === 200) return val.data;
              else throw new Error('Upload failed');
            });
      
          uploadPromises.push(uploadPromise);
        }
      
        // Wait for all promises to resolve
        return Promise.all(uploadPromises)
          .then((results) => {
            // results will contain an array of the data from successful uploads
            const successfulUploads = results.filter((result) => result !== undefined);
            setUpLoadFiles([...uploadFiles, ...successfulUploads]);
          })
          .catch((error) => {
            toast.error('Files allowed are image and pdf')
            // Handle errors
          });
      };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
          setDragActive(true);
        } else if (e.type === "dragleave") {
          setDragActive(false);
        }
      };

      const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setIsUploadingFile(true)
            makeUpload(e.dataTransfer.files)
            .then(_ => {
                setIsUploadingFile(false)
            })
        }
      };  

      const onButtonClick = () => {
        inputRef.current.click();
      };

      const handleChange = async (e) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            setIsUploadingFile(true)
            makeUpload(e.target.files)
            .then(_ => {
                setIsUploadingFile(false)
            })
        }
      };

      const handleAttach = () => {
        setIsLoading(true)
        const datas = {
          orderIdentifier: data?.orderIdentifier,
          files: uploadFiles.map(val => ({
            url: val?.url,
            type: 'purchase_orders',
            attachmentName: val?.name
          }))
        }

        if (datas?.files.length === 0 ){
          toast.error("You've to attach a file")
          setIsLoading(false)
          return
        }
        inventoryOrderAttachment(datas)
        .then(val => {
          setIsLoading(false)
          if(val?.status === 201){

            toast.success('File Attached')
            handleCloseAttachModal()
            callback()
          } 
          // else toast.error(val?.data?.message)
          // console.log(val, datas)

        }).catch(err => {
          setIsLoading(false)
          console.log(err)

        })

      }

  return (
    <CustomModal 
        open={openAttachModal} 
        handleClose={()=>{
            setUpLoadFiles([])
            handleCloseAttachModal()
        }}
        modalClassName="w-[500px]"
    >
        <div className="p-6">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold">File Attachment</h2>
            </div>

            <div className="border border-dashed border-gray-300 rounded-lg p-6">
                <div className="text-center mb-4">
                    <h1 className="text-gray-700 font-medium">Upload attachment</h1>
                    <p className="text-gray-500 text-sm">Upload attachment in either PNG, JPG, PDF format</p>
                </div>

                <div className="bg-[#F8F8F8] rounded-lg p-8 flex flex-col items-center justify-center">
                    <div className="mb-4">
                        <BsUpload className="text-4xl text-gray-400" />
                    </div>
                    <div className="text-center mb-4">
                        <p className="text-gray-600">Drag and drop files here</p>
                        <p className="text-gray-400 text-sm">Upload attachment in either PNG, JPG, PDF format</p>
                    </div>
                    <button
                        onClick={() => inputRef.current?.click()}
                        className="bg-white border border-gray-200 text-gray-700 px-6 py-2 rounded-lg hover:bg-gray-50"
                    >
                        Select files
                    </button>
                </div>
            </div>

            {uploadFiles.length > 0 && (
                <div className="mt-6 max-h-[200px] overflow-y-auto">
                    {uploadFiles.map((file, index) => (
                        <div key={index} className="flex items-center justify-between p-3 bg-gray-50 rounded-lg mb-2">
                            <div className="flex items-center gap-2">
                                {file?.type.split('/')[0] === 'image' ? (
                                    <FiImage className="text-gray-600 text-xl" />
                                ) : (
                                    <BsFilePdf className="text-gray-600 text-xl" />
                                )}
                                <span className="text-sm">{trimText(file?.name, 25, 25)}</span>
                            </div>
                            <div className="flex items-center gap-3">
                                <a href={file?.url} target="_blank" className="text-blue-500 text-sm">View</a>
                                <button
                                    onClick={() => setUpLoadFiles(files => files.filter(f => f.name !== file?.name))}
                                    className="text-red-500"
                                >
                                    <MdOutlineDeleteOutline size={20} />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <input
                ref={inputRef}
                type="file"
                multiple
                onChange={handleChange}
                className="hidden"
                accept=".png,.jpg,.jpeg,.pdf"
            />

            <div className="flex justify-end gap-3 mt-6">
                {!isLoading ? (
                    <button
                        onClick={handleAttach}
                        className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                    >
                        Save
                    </button>
                ) : (
                    <CircularProgress size={24} />
                )}
            </div>
        </div>
    </CustomModal>
  )
}

export default FileUploadUi