import { BsPlus } from "react-icons/bs"
import CustomDropdown from "../../Widgets/CustomDropdown"
import GlobalButton from "../../Widgets/GlobalButton"
import { useNavigate } from "react-router"
import AppLayout from "../../Widgets/AppLayout"
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'
import { getBranchesAsync } from '../../../Store/Branches';
import { IoClose } from 'react-icons/io5';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel'
import { quickWithdrawalTutorial } from '../mock'
import SearchInput from "../../Widgets/SearchInput";
import ButtonWithIcon from "../../Widgets/ButtonWithIcon";
import CustomButton from "./TestButton"
import { getInventoryGroupsThunk } from '../../../Store/Inventory'
import Table from './Table'
import WithdrawModal from './WithdrawModal'
import { MdSwapVert } from 'react-icons/md';
import { ReactComponent as WithdrawIcon } from '../../../Assets/withdraw.svg';
import { HiMapPin } from "react-icons/hi2";








export const QuickWithdraw = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [fromBranch, setFromBranch] = useState({
    label: (
      <div className="flex items-center gap-2">
        <HiMapPin className="text-gray-600" />
        <span>All locations</span>
      </div>
    ),
    value: ""
  });
  const [selectItems, selectInventoryItem] = useState([])
  const [activeFilter, setActiveFilter] = useState('All')
  const [searchQuery, setSearchQuery] = useState('')
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  
  const branches = useSelector(state => state?.branches?.branches?.data || [])
  const inventoryGroups = useSelector(state => state?.inventory?.groups) || []

  useEffect(() => {
    dispatch(getBranchesAsync())
  }, [dispatch])

  useEffect(()=>{
    const withdrawalItem = localStorage.getItem("withdrawalItem")
    if(withdrawalItem !== undefined){
      const data = JSON.parse(withdrawalItem);
      const withdrawalBranch = branches.find((x) => x.id === data?.withdrawLocationId)
      if(withdrawalBranch){
        setFromBranch({ label: withdrawalBranch?.name, value: withdrawalBranch?.id})
      }
      if(data?.withdrawalData){
        selectInventoryItem(data?.withdrawalData)
      }
    } else {
      setFromBranch({
        label: (
          <div className="flex items-center gap-2">
            <HiMapPin className="text-gray-600" />
            <span>All locations</span>
          </div>
        ),
        value: ""
      })
    }
  },[])

  useEffect(() => {
    dispatch(getInventoryGroupsThunk())
  }, [dispatch])

  // Transform branches data for dropdown with extra safety checks
  const branchOptions = Array.isArray(branches) 
    ? branches.map(branch => ({
        label: branch.name || '',
        value: branch.id || ''
      }))
    : [];

  // Ensure allBranchOptions is always an array
  const allBranchOptions = [
    { label: "All Locations", value: "all" },
    ...branchOptions
  ].filter(Boolean);

  const handleSaveLocationInfo = (val = undefined) => {
    const data = JSON.parse(localStorage.getItem("withdrawalItem"))
    const user = JSON.parse(localStorage.getItem("user"));
    const activeCompany = JSON.parse(localStorage.getItem("activeCompany"))
    const [member] = activeCompany?.memberships.filter((x) => x.userId === user?.id);
    const withdrawalData = {
      ...data,
      teamMembershipId: member.id,
      withdrawalData: selectItems,
      itemUsage: val
    }
    localStorage.setItem("withdrawalItem", JSON.stringify(withdrawalData))

    navigate("/quick-withdrawal-confirm")
  }

  const handleWithdrawClick = () => {
    if(["", undefined, null].includes(fromBranch?.value)){
      toast.error("Please select a withdrawal location")
      return
    }
    setShowWithdrawModal(true)
  }

  const handleFilterClick = (groupName) => {
    setActiveFilter(groupName)
  }

  return (
    <section className="bg-[#F5F5F5] min-h-screen">
      <AppLayout 
        showAppBar={false} 
        hideLiveUsers={true} 
        className="h-screen flex overflow-hidden"
      >
        <div className="withdrawal-container absolute top-[15px] left-[15px] w-[calc(100%-15px)] h-[95vh] overflow-y-auto border border-[#EAEAEA] rounded-[12px] flex flex-col bg-white">
          <div className="quick-withdraw flex flex-col w-full h-[30px] pl-5 pt-1">
            <div className="flex flex-row items-center gap-2 w-[130px] h-[18px] top-15 left-25">
              <WithdrawIcon />
              <p className="font-[Plus Jakarta Sans] font-medium text-[12px] text-[#7a7a7a] leading-[15px]">
                Quick Withdraw
              </p>
            </div>
            <div className="withdraw-items-search-container flex flex-col h-screen w-[80vw] gap-[20px] relative mt-5">
              <div className="withdraw-items-heading mt-[15px] h-[66px] w-[350px]">
                <h1 className="font-[Plus Jakarta Sans] font-semibold text-[30px]">
                  Withdraw Items
                </h1>
                <p className="font-[Plus Jakarta Sans] font-normal text-[14px] leading-[22px] text-[#606060]">
                  Withdraw items seamlessly
                </p>
              </div>
              <div className="withdraw-items-search flex flex-row items-center w-full gap-[20px] relative z-20">
                <SearchInput 
                  placeholder="I'm Searching for" 
                  className="w-[66vw] h-[40px] !rounded-[24px] border border-[#E8E8E8] placeholder-[#C3C3C3]-400 placeholder:font-[Plus Jakarta Sans] placeholder:text-sm"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <CustomDropdown 
                  data={allBranchOptions}
                  value={fromBranch || {
                    label: (
                      <div className="flex items-center gap-2">
                        <HiMapPin className="text-gray-600" />
                        <span>All locations</span>
                      </div>
                    ),
                    value: ""
                  }}
                  onChangeOption={(option) => setFromBranch(option)}
                  className="w-[10vw] h-[40px] border border-[#E8E8E8] !rounded-[24px] flex items-center px-4 text-[#848484] font-[Plus Jakarta Sans] text-sm"
                />
              </div>
              <div className="withdraw-items-sort flex flex-row w-[35vw] h-[30px] gap-[10px]">
                    <CustomButton
                        title="All"
                        onClick={() => handleFilterClick('All')}
                        className={`min-w-[48px] w-[48px] max-w-[80px] rounded-[21px] border border-[#F0F0F0] 
                                  font-['Plus_Jakarta_Sans'] font-medium text-[12px] 
                                  leading-[15.12px] tracking-[-0.01em] 
                                  ${activeFilter === 'All' ? 'bg-[#9c9c9c] text-white' : ''}
                                  hover:bg-[#9c9c9c] hover:text-white transition-colors`}
                      />

                      {inventoryGroups.map((group) => (
                        <CustomButton
                          key={group.id}
                          title={group.name}
                          onClick={() => handleFilterClick(group.name)}
                          className={`min-w-[70px] rounded-[21px] border border-[#F0F0F0] 
                                    font-['Plus_Jakarta_Sans'] font-medium text-[12px] 
                                    leading-[15.12px] tracking-[-0.01em] 
                                    ${activeFilter === group.name ? 'bg-[#9c9c9c] text-white' : ''}
                                    hover:bg-[#9c9c9c] hover:text-white transition-colors`}
                        />
                      ))}
               </div>
               <div className="withdraw-table w-[78vw] flex-1 min-h-[65vh] border border-[#EAEAEA] overflow-y-auto">
                 <Table 
                   activeFilter={activeFilter} 
                   searchQuery={searchQuery}
                   selectedItems={selectedItems}
                   setSelectedItems={setSelectedItems}
                 />
               </div>
               <div className="withdraw-button flex flex-row justify-end gap-[10px] w-[78vw] mt-4 mb-6 pr-4">
                <CustomButton
                  title="Cancel"
                  onClick={() => navigate(-1)}
                  className="min-w-[100px] h-[36px] rounded-[4px] border border-[#E8E8E8] 
                            font-['Plus_Jakarta_Sans'] font-medium text-[14px] text-[#848484] 
                            bg-white hover:bg-gray-50 transition-colors"
                />
                <CustomButton
                  title="Withdraw Items"
                  onClick={handleWithdrawClick}
                  className="min-w-[120px] h-[36px] rounded-[4px] 
                            font-['Plus_Jakarta_Sans'] font-medium text-[14px] text-white 
                            bg-[#00A862] hover:bg-[#008F53] transition-colors"
                />
               </div>
            </div>
          </div>
        </div>

        <WithdrawModal
          isOpen={showWithdrawModal}
          onClose={() => setShowWithdrawModal(false)}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          location={fromBranch}
        />
      </AppLayout>
    </section>
  );
  
}  