import React, { useMemo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import InventoryListItem from '../../inventoryListItem/inventoryListItem'
import { getInventoryManufacturersThunk, getInventorySuppliersThunk, getAllItemsThunk, getAnItemThunk } from '../../../../../Store/Inventory'

const InventoryTable = ({ activeFilter, searchQuery, selectedItems, setSelectedItems }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const rawInventoryItems = useSelector(state => state?.inventory?.items)
  const rawInventoryGroups = useSelector(state => state?.inventory?.groups)
  const manufacturers = useSelector(state => state.inventory.manufacturers)
  const suppliers = useSelector(state => state.inventory.suppliers)
  const items = useSelector(state => state.inventory.items)
  const singleItem = useSelector(state => state.inventory.item)

  const inventoryItems = useMemo(() => rawInventoryItems || [], [rawInventoryItems])
  const inventoryGroups = useMemo(() => rawInventoryGroups || [], [rawInventoryGroups])

  useEffect(() => {
    dispatch(getInventoryManufacturersThunk())
    dispatch(getInventorySuppliersThunk())
    dispatch(getAllItemsThunk())
  }, [dispatch])

  const getGroupName = (groupId) => {
    const group = inventoryGroups?.find(g => g.id === groupId)
    return group?.name || '-'
  }

  const getManufacturerName = (currentItem) => {
    const manufacturerFromThunk = manufacturers?.find(m => m.id === currentItem?.inventoryManufacturerId);
    return manufacturerFromThunk?.name || '-';
  }

  const getSupplierName = (supplierId) => {
    const supplier = suppliers?.find(s => s.id === supplierId)
    return supplier?.name || '-'
  }

  const filteredItems = useMemo(() => {
    return inventoryItems.filter(item => {
      const matchesSearch = !searchQuery || 
        item.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.referenceNumber?.toLowerCase().includes(searchQuery.toLowerCase())
      
      const matchesGroup = !activeFilter || activeFilter === 'All groups' || 
        getGroupName(item.groupId) === activeFilter

      return matchesSearch && matchesGroup
    })
  }, [inventoryItems, searchQuery, activeFilter])

  const handleSelectRow = (item) => {
    if (selectedItems.some(i => i.id === item.id)) {
      setSelectedItems(prev => prev.filter(i => i.id !== item.id))
    } else {
      setSelectedItems(prev => [...prev, item])
    }
  }

  return (
    <div className="relative w-[77vw]">
      <table className="w-full text-left">
        <thead>
          <tr className="bg-[#F1FBF2]">
            <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
              Reference Number
            </th>
            <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
              Group
            </th>
            <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
              Name
            </th>
            <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
              Item price
            </th>
            <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
              Supplier
            </th>
            <th className="px-3 py-3 text-left text-xs font-medium text-[#7A7A7A] uppercase tracking-wider">
              Manufacturer
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredItems.map((item) => (
            <tr 
              key={item.id} 
              className="border-b border-[#EAEAEA] hover:bg-gray-50 cursor-pointer"
              onClick={() => navigate(`/inventory-items-list/${item.referenceNumber}/${item.id}`)}
            >
              <td className="px-3 py-4 whitespace-nowrap text-sm text-[#404040]">
                {item.referenceNumber || '-'}
              </td>
              <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                {getGroupName(item.groupId)}
              </td>
              <td className="px-3 py-4 whitespace-nowrap text-sm text-[#404040]">
                {item.name || '-'}
              </td>
              <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                ${(item.packageCost || 0).toFixed(2)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                  {getSupplierName(item.manAndSupp?.[0]?.supplierId)}
                </td>
              <td className="px-3 py-4 whitespace-nowrap text-sm text-[#7A7A7A]">
                {getManufacturerName(item)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default InventoryTable
