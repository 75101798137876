import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomDropdown from '../../Widgets/CustomDropdown';
import { toast } from 'react-toastify';
import { IoClose } from 'react-icons/io5';
import { Branch } from '../../../Network/ServiceClass/Branch';
import { getInventoryMeasurementUnitThunk, confirmOrderThunk } from '../../../Store/Inventory';
import { confirmOrder } from '../../../Network/ServiceClass/InventoryService';
import FormModal from '../../Widgets/Animation/FormModal';
import { getInventoryManufacturersThunk } from '../../../Store/Inventory';

const RequestItemModal = ({ open, handleClose, selectedItems, setSelectedItems }) => {
  const dispatch = useDispatch();
  {/*const { user } = useSelector((state) => state.auth);*/}
  const { loading, measurementUnits, manufacturers } = useSelector((state) => state.inventory);
  const [location, setLocation] = useState({ label: "Select location", value: "" });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [measurementUnit, setMeasurementUnit] = useState({ label: "Select unit", value: "" });
  const [branches, setBranches] = useState([]);
  const [orderQuantities, setOrderQuantities] = useState({});
  const [teamMembershipId, setTeamMembershipId] = useState(null);
  const [quantities, setQuantities] = useState({});  // Track quantities per item
  const [selectedUnits, setSelectedUnits] = useState({});  // Renamed from measurementUnits
  const companyState = useSelector((state) => state?.company);
  const [isLoading, setIsLoading] = useState(true);
  

  const branchService = new Branch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch branches
        const branchResponse = await branchService.getBranches();
        if (branchResponse?.data) {
          const branchOptions = branchResponse.data.map(branch => ({
            label: branch.name,
            value: branch.id
          }));
          setBranches(branchOptions);
        }

        // Fetch measurement units
        dispatch(getInventoryMeasurementUnitThunk());
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to load data');
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const getTeamMembership = async () => {
      try {
        const response = await branchService.fetchTeamMember({});
        if (response.status === 200) {
          setTeamMembershipId(response.data[0]?.id);
        }
      } catch (error) {
        console.error('Error fetching team membership:', error);
      }
    };

    getTeamMembership();
  }, []);

  useEffect(() => {
    dispatch(getInventoryManufacturersThunk());
  }, [dispatch]);

  useEffect(() => {
    // Check if company state is properly loaded
    if (companyState && companyState.activeCompany) {
      setIsLoading(false);
    }
  }, [companyState]);

  const handleRemoveItem = (itemId) => {
    setSelectedItems(prev => prev.filter(item => item.id !== itemId));
  };

  const handleRequestItems = () => {
    if (!location?.value) {
      toast.error('Please select a location');
      return;
    }
    setShowConfirmModal(true);
  };

  const handleQuantityChange = (itemId, change) => {
    setQuantities(prev => {
      const currentQuantity = prev[itemId] || 0;
      const newQuantity = Math.max(0, currentQuantity + change); // Prevent negative numbers
      return {
        ...prev,
        [itemId]: newQuantity
      };
    });
  };

  const handleUnitChange = (itemId, unit) => {
    setSelectedUnits(prev => ({
      ...prev,
      [itemId]: unit
    }));
  };

  const handleSubmit = async () => {
    if (isLoading || !companyState?.activeCompany?.memberships?.[0]?.id) {
      toast.error('Please wait for company data to load');
      return;
    }

    if (!location?.value) {
      toast.error('Please select a location');
      return;
    }

    // Validate that at least one item has a quantity and unit selected
    const hasValidItems = selectedItems.some(item => 
      (quantities[item.id] || 0) > 0 && selectedUnits[item.id]?.value
    );

    if (!hasValidItems) {
      toast.error('Please specify quantity and measurement unit for at least one item');
      return;
    }

    try {
      const submitData = {
        branchId: location.value,
        teamMembershipId: companyState.activeCompany.memberships[0].id,
        orderData: selectedItems.map(item => ({
          packagesToOrder: quantities[item.id] || 0,
          referenceNum: item.referenceNumber,
          measurementUnitId: selectedUnits[item.id]?.value || null,
        })).filter(item => item.packagesToOrder > 0 && item.measurementUnitId)
      };

      // Validate there's at least one valid item after filtering
      if (submitData.orderData.length === 0) {
        toast.error('Please add quantities and select units for at least one item');
        return;
      }

      console.log('Submitting order data:', submitData);
      
      // Submit using confirmOrder
      const response = await confirmOrder(submitData);
      console.log('Order response:', response);
      
      if (response.status === 201 || response.status === 200) {  // Check for both 200 and 201
        toast.success('Order request submitted successfully');
        handleClose();
      } else {
        // Show specific error message if available
        const errorMessage = response?.data?.message || 'Failed to submit order';
        console.error('Order error:', errorMessage);
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error('Error submitting order:', error);
      const errorMessage = error?.response?.data?.message || 
                          error?.message || 
                          'An unexpected error occurred while submitting the order';
      toast.error(errorMessage);
    }
  };

  const getManufacturerName = (currentItem) => {
    const manufacturerFromThunk = manufacturers?.find(m => m.id === currentItem?.inventoryManufacturerId);
    return manufacturerFromThunk?.name || '-';
  };

  // Show loading or error state while company data isn't available
  if (isLoading || !companyState?.activeCompany?.memberships?.[0]?.id) {
    return null; // Or a loading spinner
  }

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      className="fixed inset-0 flex items-center justify-center z-50"
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }}
    >
      {!showConfirmModal ? (
        <div 
          className="bg-white rounded-lg w-[500px] relative"
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <div className="flex justify-between items-center p-4 border-b">
            <h2 className="text-lg font-semibold">Order Request</h2>
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
              <IoClose size={24} />
            </button>
          </div>

          <div className="p-4 space-y-4">
            {/* Location Dropdown */}
            <div className="space-y-2 relative z-30">
              <label className="block text-sm font-medium text-gray-700">Location</label>
              <CustomDropdown
                data={branches}
                value={location}
                onChangeOption={setLocation}
                placeholder="Select location"
                className="w-full"
                containerClassName="w-full"
                menuClassName="max-h-[200px] overflow-y-auto bg-white shadow-lg border border-gray-200 rounded-md mt-1"
                controlClassName="w-full h-10 px-3 py-2 text-sm bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#00A862] focus:border-[#00A862]"
                optionClassName="px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
              />
            </div>

            {/* Selected Items */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Selected Items</label>
              <div className="border rounded-lg p-2 max-h-[150px] overflow-y-auto">
                {selectedItems.map((item) => (
                  <div key={item.id} className="flex items-center justify-between py-1 px-2 hover:bg-gray-50">
                    <div className="flex items-center gap-2">
                      <span className="text-sm">{item.referenceNumber}</span>
                      <span className="text-sm text-gray-500">{item.name}</span>
                    </div>
                    <button
                      onClick={() => handleRemoveItem(item.id)}
                      className="text-gray-400 hover:text-gray-600 p-1 rounded-full hover:bg-gray-100 transition-colors"
                    >
                      <IoClose size={16} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="p-4 border-t">
            <button
              onClick={handleRequestItems}
              className="w-full bg-[#00A862] text-white py-2 rounded-md hover:bg-[#008F53] transition-colors"
            >
              Request Items
            </button>
          </div>
        </div>
      ) : (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg w-[95vw]">
            <div className="p-4 flex justify-between items-center border-b">
              <h2 className="text-lg font-medium">Confirm Order Request</h2>
              <button onClick={() => setShowConfirmModal(false)} className="text-gray-500 hover:text-gray-700">
                <IoClose size={24} />
              </button>
            </div>

            <div className="p-4">
              <div className="mb-4">
                <div className="font-medium">Location - {location?.label || 'Not Selected'}</div>
                <div className="mt-4">Measurement Unit</div>
              </div>
              <div className="w-full">
                <table className="w-full">
                  <thead>
                    <tr className="text-sm text-gray-600 border-b">
                      <th className="text-left pb-2 w-[15%]">Reference No</th>
                      <th className="text-left pb-2 w-[20%]">Name</th>
                      <th className="text-left pb-2 w-[15%]">Manufacturer</th>
                      <th className="text-center pb-2 w-[10%]">No. of Suppliers</th>
                      <th className="text-center pb-2 w-[15%]">Packages to Order</th>
                      <th className="text-center pb-2 w-[10%]">Items</th>
                      <th className="text-right pb-2 w-[15%]">Unit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedItems.map((item, index) => (
                      <tr key={item.id} className="text-sm border-b" style={{ position: 'relative', zIndex: 1000 - index }}>
                        <td className="py-2 pl-2">{item.referenceNumber}</td>
                        <td className="py-2">{item.name}</td>
                        <td className="py-2">{getManufacturerName(item)}</td>
                        <td className="text-center py-2">{item.suppliersCount || 0}</td>
                        <td className="py-2">
                          <div className="flex items-center justify-center gap-2">
                            <button 
                              onClick={() => handleQuantityChange(item.id, -1)}
                              className="w-6 h-6 flex items-center justify-center border rounded-md hover:bg-gray-100"
                            >
                              -
                            </button>
                            <span className="w-8 text-center">{quantities[item.id] || 0}</span>
                            <button 
                              onClick={() => handleQuantityChange(item.id, 1)}
                              className="w-6 h-6 flex items-center justify-center border rounded-md hover:bg-gray-100"
                            >
                              +
                            </button>
                          </div>
                        </td>
                        <td className="text-center py-2">{item.availableItems || 0}</td>
                        <td className="text-right py-2 pr-2">
                          <CustomDropdown
                            data={measurementUnits?.map(unit => ({
                              label: unit.name,
                              value: unit.id
                            })) || []}
                            value={selectedUnits[item.id]}
                            onChangeOption={(unit) => handleUnitChange(item.id, unit)}
                            placeholder="Select unit"
                            className="w-[120px]"
                            containerClassName="w-[120px] ml-auto relative"
                            menuClassName="max-h-[200px] overflow-y-auto bg-white shadow-lg border border-gray-200 rounded-md mt-1 absolute"
                            controlClassName="w-full h-8 px-2 py-1 text-sm bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#00A862] focus:border-[#00A862]"
                            optionClassName="px-2 py-1 text-sm hover:bg-gray-100 cursor-pointer bg-white"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mt-4 flex items-center gap-2">
                {/* Lot Number field removed */}
              </div>
            </div>

            <div className="p-4 border-t flex justify-end gap-3">
              <button
                onClick={() => setShowConfirmModal(false)}
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                disabled={loading}
                className="px-4 py-2 bg-[#00A862] text-white rounded-md hover:bg-[#008F53] transition-colors disabled:bg-gray-400"
              >
                {loading ? "Processing..." : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      )}
    </FormModal>
  );
};

export default RequestItemModal;